import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import RuleItem from './RuleItem';
import { useBreakpoints } from '../../../Components/Hooks/BreakpointHooks';

const TransactionalWallet = ({ t, transactionals }) => {
  SwiperCore.use([FreeMode, Navigation, Thumbs]);
  const [thumbsSwiper] = useState(null);

  const events = [{ name: 'welcome', rules: [] }];
  const [walletTransasctionals, setWalletTransactionals] = useState(events);
  const { isLg } = useBreakpoints();

  useEffect(() => {
    setWalletTransactionals(
      events.map((event) => {
        const transactionalsEvent = transactionals.filter(
          (tr) => tr.transactional === event.name
        );

        return { ...event, rules: transactionalsEvent };
      })
    );
  }, [transactionals]);

  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col lg={12}>
          <div>
            <div className="horizontal-timeline my-3">
              <Swiper
                className="timelineSlider"
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={isLg ? 4 : 'auto'}
              >
                <div className="swiper-wrapper">
                  {walletTransasctionals.map((item, key) => (
                    <SwiperSlide key={key}>
                      <RuleItem
                        rules={item.rules}
                        event={item.name}
                        transactionalType="wallet"
                      />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(TransactionalWallet);
