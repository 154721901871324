import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import Select from "react-select";

//redux
import TableContainer from "../../../Components/Common/TableContainer";
import { withTranslation } from "react-i18next";
import { formatDocument } from "../../../helpers/format";
import { Can } from "../../../helpers/casl";
import Search from "../../../Components/Common/Search";
import RangeDatePicker from "../../../Components/Common/RangeDatePicker";
import moment from "moment";
import ExportReport from "../../../Components/Common/ExportReport";
import useQsParams from "../../../Components/Hooks/QueryString";
import PresetsFilter from "../../../Components/Common/PresetsFilter";
import { cleanDocument } from "../../../helpers/functions";
import EmptyList from "../../../Components/Common/EmptyList";

const TableOrderManagement = ({
  props,
  columns,
  orderStatus,
  isLoading,
  orders,
  meta,
  page,
  setPage,
  filtersParams,
  hiddenColumns = [],
}) => {
  const { qs, searchParams, setSearchParams, setSearchParamsAsObject, setQs } =
    useQsParams();
  const [createdAt, setCreatedAt] = useState([]);

  const _parseCreatedAt = () => {
    const hasCreatedAt = qs.get("created_at");
    let dateRange = [];

    if (hasCreatedAt) {
      const from = hasCreatedAt?.split(",")?.[0];
      const to = hasCreatedAt?.split(",")?.[1];

      dateRange = [
        moment.unix(from).format("D/M/YY"),
        moment.unix(to || from).format("D/M/YY"),
      ];
    }

    setCreatedAt(dateRange);
  };

  useEffect(() => {
    _parseCreatedAt();
  }, [qs.get("created_at")]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Vendas' pageTitle='Gestão' />
          <Row>
            <Col lg={12}>
              <Card id='leadsList'>
                <CardHeader className='border-0'>
                  <Row className='row-cols-1 row-cols-sm-2 row-cols-md-12 g-2 g-lg-3'>
                    <Col xl={2}>
                      <Search
                        placeholder={props.t("ticket")}
                        value={qs?.get("external_id")?.replace(/%/g, "")}
                        onSubmit={({ id }) => {
                          const value = _.trim(id);
                          setSearchParamsAsObject({
                            external_id: id ? `%${value}%` : "",
                            page: 1,
                          });
                        }}
                      />
                    </Col>

                    <Col xl={2}>
                      <Search
                        inputMode="numeric"
                        placeholder={props.t("document")}
                        value={formatDocument(qs?.get("document"))}
                        onSubmit={({ id }) => {
                          setSearchParamsAsObject({
                            document: id ? cleanDocument(id) : "",
                            page: 1,
                          });
                        }}
                      />
                    </Col>

                    <Col xl={2}>
                      <RangeDatePicker
                        onClear={() => setSearchParams("created_at", "")}
                        placeholder={props.t("order-date")}
                        value={createdAt}
                        onChange={(v) => {
                          if (v[0] && v[1]) {
                            const d1 = moment(v[0]).unix();
                            const d2 = moment(v[1]).unix();
                            const value = !d2 ? d1 : `${d1},${d2}`;
                            setSearchParams("created_at", value);
                          }
                        }}
                      />
                    </Col>

                    <Col xl={2}>
                      <Select
                        name='status'
                        id='order-status'
                        isClearable
                        isSearchable
                        placeholder={props.t("select-status")}
                        onChange={(v) => {
                          setSearchParamsAsObject({
                            status: v?.value,
                            page: 1,
                          });
                        }}
                        value={orderStatus.find(
                          (x) => x.value === searchParams?.get("status")
                        )}
                        options={orderStatus}
                      />
                    </Col>

                    {isLoading ? (
                      <Col xl='auto'>
                        <Spinner color='secondary' className='m-0' />
                      </Col>
                    ) : null}

                    <Col sm={12} md='auto' className='ms-auto'>
                      <div className='hstack gap-2'>
                        <button
                          type='button'
                          className='btn btn-primary'
                          onClick={() => setQs("showFilter", true)}
                          disabled={isLoading}
                        >
                          <i className='ri-filter-3-line align-bottom me-1'></i>{" "}
                          {props.t("advanced-filters")}
                        </button>
                      </div>
                    </Col>
                    <Col sm={12} md='auto'>
                      <ExportReport
                        pages={meta?.pages}
                        disabled={isLoading || !orders?.length}
                        filename='Relatório OCK: Vendas'
                        service='orders'
                        columns={columns}
                        filters={filtersParams}
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <div className='mt-3'>
                  <PresetsFilter
                    items={[
                      {
                        label: "Venda com cashback a expirar",
                        value: {
                          cashback_expires_at: [
                            moment().startOf("day").unix(),
                            moment().add("10", "years").endOf("day").unix(),
                          ].join(","),
                        },
                        key: "cashback_to_expire",
                      },
                      {
                        label: "Venda com cashback expirado",
                        value: {
                          cashback_expires_at: [
                            moment()
                              .subtract("10", "years")
                              .startOf("day")
                              .unix(),
                            moment().subtract("1", "day").endOf("day").unix(),
                          ].join(","),
                        },
                        key: "cashback_expired",
                      },
                      {
                        label: "Venda com saldo consumido",
                        value: {
                          used_cashback: [10, 1000000].join(","),
                        },
                        key: "used_cashback",
                      },
                    ]}
                  />
                </div>

                <CardBody className='pt-0'>
                  <Can I='read' a='sales_management'>
                    <div>
                      {!orders?.length && qs.toString() ? (
                        <div className='mt-3'>
                          <EmptyList
                            heading={props.t("search-empty-heading")}
                            body={props.t("table-content-empty-results")}
                          />
                        </div>
                      ) : (
                        <TableContainer
                          isLoading={isLoading}
                          columns={columns}
                          data={orders || []}
                          activePage={page}
                          pageSize={meta?.take || 10}
                          totalPages={meta?.pages || 0}
                          count={meta?.total || 0}
                          setPage={setPage}
                          className='custom-header-css'
                          divClass='table-responsive table-card mb-0'
                          tableClass='align-middle table-nowrap'
                          theadClass='table-light'
                          hiddenColumns={hiddenColumns}
                        />
                      )}
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer position='top-center' closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(TableOrderManagement);
