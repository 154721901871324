import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import InformativeCard from '../../../../Components/Common/InformativeCard';
import { convertCents } from '../../../../helpers/format';
import Card from '../Card';

const BillingLifetimeInfoChart = ({ data, t }) => {
  const indicators = data?.billing;

  const summary = [
    [
      {
        label: t('reports.billing.lifetime.info.total-impacted'),
        value: indicators.summary.billing_total_impacted,
        type: 'currency',
      },
      {
        label: t('reports.billing.lifetime.info.total-impacted-percentage'),
        value: indicators.summary.billing_total_impacted_percentage,
        type: 'percentage',
      },
    ],

    [
      {
        label: t('reports.billing.lifetime.info.total-mixed'),
        value: indicators.summary.billing_total_mixed,
        type: 'currency',
      },
      {
        label: t('reports.billing.lifetime.info.total-mixed-percentage'),
        value: indicators.summary.billing_total_mixed_percentage,
        type: 'percentage',
      },
    ],

    [
      {
        label: t('reports.billing.lifetime.info.total-incremental'),
        value: indicators.summary.billing_total_incremental,
        type: 'currency',
      },

      {
        label: t('reports.billing.lifetime.info.total-incremental-percentage'),
        value: indicators.summary.billing_total_incremental_percentage,
        type: 'percentage',
      },
    ],

    [
      {
        label: t('reports.billing.lifetime.info.total-no-cashback'),
        value: indicators.summary.billing_total_without_cashback,
        type: 'currency',
      },
      {
        label: t('reports.billing.lifetime.info.total-no-cashback-percentage'),
        value: indicators.summary.billing_total_without_cashback_percentage,
        type: 'percentage',
      },
    ],
  ];

  return (
    <Card
      minimal
      body={summary.map((row, rIndex) => (
        <Row key={`row-${rIndex}`}>
          {row.map((item, iIndex) => (
            <Col key={`col-${iIndex}`} xs={6}>
              <InformativeCard
                label={item.label}
                prefix={item.type === 'currency' ? 'R$' : ''}
                suffix={item.type === 'percentage' ? '%' : ''}
                decimals={item?.type === 'percentage' ? 1 : 2}
                counter={
                  item?.type === 'percentage'
                    ? item.value * 100
                    : convertCents(item.value)
                }
                duration={1.5}
                separator="."
                decimal=","
              />
            </Col>
          ))}
        </Row>
      ))}
    />
  );
};

export default withTranslation()(BillingLifetimeInfoChart);
