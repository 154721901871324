import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import _ from 'lodash';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import OrderDetailTable from './components/OrderDetails';

import GeneralInfo from './components/GeneralInfo';
import CashbackInfo from './components/CashbackInfo';
import { getOrder } from '../../../slices/orders/thunk';

const OrderManagementDetails = (props) => {
  const dispatch = useDispatch();
  const { orderId } = useParams();

  useEffect(() => {
    dispatch(getOrder(orderId));
  }, [dispatch, orderId]);

  const external_id = useSelector(
    createSelector(
      (store) => store.Order,
      (slice) => slice?.order?.external_id ?? ''
    )
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${props.t('sale')} ${external_id}`}
            pageTitle={props.t('orders')}
            showBackButton
          />
        </Container>

        <Row className="w-100 mx-auto gx-4 gx-md-5">
          <Col xl={3} className="order-2 order-xl-1 mt-5 mt-xl-0">
            {/* <div className="skeleton card" style={{ height: '100px' }} /> */}
            <GeneralInfo />
          </Col>

          <Col xl={9} className="order-1 order-xl-2">
            <CashbackInfo />
            <OrderDetailTable />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withTranslation()(OrderManagementDetails);
