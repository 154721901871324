import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { cancelOrder } from "../../../slices/orders/thunk";
import { cancelIncentive } from "../../../slices/incentives/thunk";


const CancelOrder = ({ isOpen, toggle, order, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const isIncentive = () => order?.type === "incentive";


  const cancelOrderOrIncentive = async () => {
    setIsLoading(true);
    let action;

    if(isIncentive()) {
      action = await cancelIncentiveApi();
    } else {
      action = await cancelOrderApi();
    }

    setIsLoading(false);
    toggle();

    if (!action.payload.error) {
      onSuccess();
    }
  };

  const cancelIncentiveApi = async () => {
    return dispatch(
      cancelIncentive({
        id: order.id,
        successMessage: t("incentives-management.cancel-incentive.successMessage"),
        errorMessage: t("incentives-management.cancel-incentive.errorMessage"),
        incentiveUsedErrorMessage: t("incentives-management.cancel-incentive.incentive-used-error-message"),
        incentiveExpiredErrorMessage: t("incentives-management.cancel-incentive.incentive-expired-error-message")
      })
    );
  };

  const cancelOrderApi = async () => {
    return dispatch(
      cancelOrder({
        external_id: order?.ticket || order?.external_id,
        successMessage: t("orders-management.cancel-order.successMessage"),
        errorMessage: t("orders-management.cancel-order.errorMessage"),
      })
    );
  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
           { isIncentive() ?  t("incentives-management.cancel-incentive.cancel") : t("orders-management.cancel-order.cancel")}
        </ModalHeader>

        <ModalBody>
          <h4 className="mb-4 text-center">
           { isIncentive() ?  t("incentives-management.cancel-incentive.modal-description") : t("orders-management.cancel-order.modal-description")}
          </h4>
          <br></br>
          <h4 className="mb-4 text-center">
           { isIncentive() ?  t("incentives-management.cancel-incentive.question") : t("orders-management.cancel-order.question")}
          </h4>
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={toggle}>
              {t("close")}
            </button>
            <button
              onClick={cancelOrderOrIncentive}
              type="submit"
              disabled={!order}
              className="btn btn-success"
              id="add-btn"
            >
              <div className="d-flex">
                {isLoading && <Spinner size="sm" className="me-3" />}
                {t("confirm")}
              </div>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CancelOrder);
