import { Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { currencyFieldToFloat } from '../../../../../helpers/functions';
import ActionButton from '../../../../../Components/Common/ActionButton';
import CurrencyInput from '../../../../../Components/Common/CurrencyInput';
import InputSwitch from '../../../../../Components/Common/InputSwitch';

const Step2 = ({ formik, disabled, t }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col md={9}>
              <h4>Confirmar número via SMS</h4>
              <span className="text-muted">
                Habilita o envio de SMS para o número informado no cadastro com
                a finalidade de verificar sua autenticidade. <u>Importante:</u>{' '}
                O incentivo será gerado somente após a confirmação do token via
                SMS
              </span>
            </Col>

            <Col className="d-flex justify-content-end">
              <InputSwitch
                name="validatePhone"
                checked={formik?.values?.validatePhone}
                disabled={disabled}
                onChange={(e) => formik.setFieldValue('validatePhone', e)}
              />
            </Col>
          </Row>
        </CardBody>

        <CardBody>
          <Row>
            <Col md={9}>
              <h4>{t('lead-capture.settings.step3.required-fields-title')}</h4>
              <span className="text-muted">
                {t('lead-capture.settings.step3.required-fields-description')}
              </span>
            </Col>

            <Col className="d-flex justify-content-end">
              <InputSwitch
                name="allFieldsRequired"
                checked={formik?.values?.allFieldsRequired}
                onChange={(e) => formik.setFieldValue('allFieldsRequired', e)}
                disabled={disabled}
              />
            </Col>
          </Row>
        </CardBody>

        <CardFooter className="text-end">
          <ActionButton
            label="Salvar"
            icon="bx-right-arrow-alt"
            onClick={async () => await formik.submitForm()}
            disabled={disabled}
          />
        </CardFooter>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col md={9}>
              <h4>Incentivo</h4>
              <span className="text-muted">
                Permite gerar um valor de cashback na conta do cliente ao
                completar o formulário
              </span>
            </Col>

            <Col className="d-flex justify-content-end">
              <InputSwitch
                name="enabledIncentive"
                checked={formik?.values?.enabledIncentive}
                onChange={(e) => formik.setFieldValue("enabledIncentive", e)}
                disabled={disabled}
              />
            </Col>
          </Row>

          <hr />

          <Row className="mb-3 d-flex align-items-center">
            <Col xl={4} className="text-muted">
              <Label
                htmlFor="cashbackAmount"
                className="form-label fs-5 fw-semibold m-0"
              >
                Valor desejado
              </Label>
              <p className="m-0">Em reais</p>
            </Col>

            <Col xl={4}>
              <div className="input-group">
                <span className="input-group-text bg-white">
                  <i className="bx bx-money label-icon align-middle fs-16 text-muted" />
                </span>
                <CurrencyInput
                  name="cashbackAmount"
                  id="cashbackAmount"
                  onChange={(e) => {
                    const value = Math.ceil(currencyFieldToFloat(e) * 100);
                    formik.setFieldValue("cashbackAmount", value);
                  }}
                  value={formik?.values?.cashbackAmount}
                  invalid={formik?.errors?.cashbackAmount}
                  disabled={
                    disabled ||
                    (formik?.values?.enableLeadCapture &&
                      !formik?.values?.enabledIncentive)
                  }
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3 d-flex align-items-center">
            <Col xl={4} className="text-muted">
              <Label
                htmlFor="expiresInDays"
                className="form-label fs-5 fw-semibold m-0"
              >
                Expiração
              </Label>
              <p className="m-0">Em dias</p>
            </Col>

            <Col xl={4}>
              <div className="input-group">
                <span className="input-group-text bg-white">
                  <i className="bx bx-calendar label-icon align-middle fs-16 text-muted" />
                </span>
                <Input
                  type="number"
                  name="expiresInDays"
                  id="expiresInDays"
                  placeholder="1"
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    if (!e.target.value || e.target.value <= 0) {
                      formik.setFieldValue("expiresInDays", 1);
                    }
                  }}
                  value={formik?.values?.expiresInDays}
                  invalid={formik?.errors?.expiresInDays}
                  disabled={
                    disabled ||
                    (formik?.values?.enableLeadCapture &&
                      !formik?.values?.enabledIncentive)
                  }
                />
              </div>
            </Col>
          </Row>
        </CardBody>

        <CardFooter className="text-end">
          <ActionButton
            label="Salvar"
            icon="bx-right-arrow-alt"
            onClick={async () => await formik.submitForm()}
            disabled={disabled}
          />
        </CardFooter>
      </Card>
    </div>
  );
};

export default withTranslation()(Step2);
