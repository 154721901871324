import { Card, CardBody, CardFooter, Col, Row, Spinner } from 'reactstrap';

import useQsParams from '../../../Components/Hooks/QueryString';
import ActionButton from '../../../Components/Common/ActionButton';
import ColorPicker from '../../../Components/Common/ColorPicker';

import { iFramePostMessage } from '../frame_utils';
import i18n from '../../../i18n';

export const VISUAL_PICKERS = [
  {
    label: i18n.t('my-wallet.step2.picker.background-title'),
    description: i18n.t('my-wallet.step2.picker.background-description'),
    state: 'backgroundColor',
    preview: {
      selector: '.auth_background-color',
      property: 'background',
    },
  },

  {
    label: i18n.t('my-wallet.step2.picker.backdrop-title'),
    description: i18n.t('my-wallet.step2.picker.backdrop-description'),
    state: 'formBackgroundColor',
    preview: {
      selector: '.auth_form-background-color',
      property: 'background',
    },
  },

  {
    label: i18n.t('my-wallet.step2.picker.labels-title'),
    description: i18n.t('my-wallet.step2.picker.labels-description'),
    state: 'formLabelsColor',
    preview: {
      selector: '.auth_form-labels-color',
      property: 'color',
    },
  },

  {
    label: i18n.t('my-wallet.step2.picker.cta-title'),
    description: i18n.t('my-wallet.step2.picker.cta-description'),
    state: 'ctaBackgroundColor',
    preview: {
      selector: '.auth_cta-background-color',
      property: 'background',
    },
  },

  {
    label: i18n.t('my-wallet.step2.picker.cta-text-title'),
    description: i18n.t('my-wallet.step2.picker.cta-text-description'),
    state: 'ctaTextColor',
    preview: {
      selector: '.auth_cta-text-color',
      property: 'color',
    },
  },
];

const Step2 = ({
  state,
  dispatch,
  formik,
  initialValues,
  disabled,
  ...props
}) => {
  const { setQs } = useQsParams();

  return (
    <Card className="m-0">
      <CardBody>
        <div className="d-flex gap-4 text-secondary">
          <h3 className="mb-4">{i18n.t('my-wallet.step2.elements')}</h3>
          {disabled && <Spinner />}
        </div>

        <Row className="row row-cols-1 g-3 mb-5">
          {Object.keys(formik?.values)?.length
            ? VISUAL_PICKERS?.map(({ state: key, ...props }, i) => (
                <Col key={`pickr-${i}`}>
                  <ColorPicker
                    {...props}
                    disabled={disabled}
                    value={formik?.values?.['auth']?.[key] || '#fff'}
                    invalid={formik?.errors[key]}
                    onChange={(c) => {
                      dispatch({ [key]: c });
                      formik.setFieldValue(`auth.${key}`, c);

                      const { selector, property } = props.preview;
                      const opts = { selector, property, value: c };
                      iFramePostMessage({ type: 'style', ...opts });
                    }}
                  />
                </Col>
              ))
            : null}
        </Row>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          icon="bx-right-arrow-alt"
          label="Salvar e continuar"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setQs('active', 3);
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default Step2;
