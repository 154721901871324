import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { formatCurrency as currency } from '../../../../helpers/format';
import { useState } from 'react';

const CheckingAccountChart = ({ data, t }): React.JSX.Element => {
  const [orderedASC, setOrder] = useState(true);
  const rawIndicators = [...data?.checkingAccount];

  const indicators = rawIndicators?.sort((a, b) => {
    const d1 = Date.parse(a?.created_at);
    const d2 = Date.parse(b?.created_at);
    return orderedASC ? d1 - d2 : d2 - d1;
  });

  const fieldsMap = [
    ['billing_total', 'currency'],
    ['billing_total_impacted', 'currency'],
    ['billing_total_impacted_percent', 'percent'],
    ['billing_total_mixed', 'currency'],
    ['billing_total_mixed_percent', 'percent'],
    ['billing_total_incremental', 'currency'],
    ['billing_total_incremental_percent', 'percent'],
    ['billing_total_without_cashback', 'currency'],
    ['billing_total_without_cashback_percent', 'percent'],
    ['cashback_total_earned', 'currency'],
    ['cashback_total_redeem', 'currency'],
    ['cashback_total_expired', 'currency'],
    ['checking_total_account', 'currency'],
    ['cashback_total_earned_billing_impacted', 'percent'],
    ['discount_total_billing_incremental', 'percent'],
    ['cashback_total_earned_billing', 'percent'],
    ['discount_total_billing', 'percent'],
  ];

  const summary = fieldsMap
    .map((field) => indicators?.reduce((acc, i) => acc + i?.[field[0]], 0))
    .map((field, k) =>
      currency(field, {
        style: fieldsMap[k][1],
        convert: Boolean(fieldsMap[k][1] === 'percent'),
      })
    );

  return (
    <Table responsive hover striped>
      <thead>
        <tr className="text-wrap align-middle lh-sm bg-secondary">
          <th>
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => setOrder(!orderedASC)}
            >
              {t('reports.checking-account.table.period')}
              <i
                className={`bx bx-sm ${
                  orderedASC ? 'bx-chevron-down' : 'bx-chevron-up'
                }`}
              />
            </div>
          </th>
          <th>{t('reports.checking-account.table.gmv')}</th>
          <th>{t('reports.checking-account.table.gmv-impacted')}</th>
          <th>{t('reports.checking-account.table.gmv-impacted-percent')}</th>
          <th>{t('reports.checking-account.table.gmv-mixed')}</th>
          <th>{t('reports.checking-account.table.gmv-mixed-percent')}</th>
          <th>{t('reports.checking-account.table.gmv-incremental')}</th>
          <th>{t('reports.checking-account.table.gmv-incremental-percent')}</th>
          <th>{t('reports.checking-account.table.gmv-no-cashback')}</th>
          <th>{t('reports.checking-account.table.gmv-no-cashback-percent')}</th>
          <th>{t('reports.checking-account.table.cashback-earned')}</th>
          <th>{t('reports.checking-account.table.cashback-redeem')}</th>
          <th>{t('reports.checking-account.table.cashback-expired')}</th>
          <th>{t('reports.checking-account.table.checking-account')}</th>
          <th>
            {t('reports.checking-account.table.cashback-earned-impacted')}
          </th>
          <th>
            {t('reports.checking-account.table.cashback-redeem-incremental')}
          </th>
          <th>{t('reports.checking-account.table.cashback-earned-gmv')}</th>
          <th>{t('reports.checking-account.table.discount')}</th>
        </tr>
      </thead>

      <tbody>
        {indicators?.map((i: any, k: number) => {
          const period = new Date(i?.created_at);
          const month = period.getMonth();
          const year = period.getFullYear();
          const opts = { style: 'percent', convert: true };

          return (
            <tr key={`indicator-${k}`}>
              <td>{t(`month.${month}`) + `/${year}`}</td>
              <td>{currency(i?.billing_total)}</td>
              <td>{currency(i?.billing_total_impacted)}</td>
              <td>{currency(i?.billing_total_impacted_percent, opts)}</td>
              <td>{currency(i?.billing_total_mixed)}</td>
              <td>{currency(i?.billing_total_mixed_percent, opts)}</td>
              <td>{currency(i?.billing_total_incremental)}</td>
              <td>{currency(i?.billing_total_incremental_percent, opts)}</td>
              <td>{currency(i?.billing_total_without_cashback)}</td>
              <td>
                {currency(i?.billing_total_without_cashback_percent, opts)}
              </td>
              <td>{currency(i?.cashback_total_earned)}</td>
              <td>{currency(i?.cashback_total_redeem)}</td>
              <td>{currency(i?.cashback_total_expired)}</td>
              <td>{currency(i?.checking_total_account)}</td>
              <td>
                {currency(i?.cashback_total_earned_billing_impacted, opts)}
              </td>
              <td>{currency(i?.discount_total_billing_incremental, opts)}</td>
              <td>{currency(i?.cashback_total_earned_billing, opts)}</td>
              <td>{currency(i?.discount_total_billing, opts)}</td>
            </tr>
          );
        })}

        <tr className="fw-bold">
          <td>{t('reports.checking-account.table.total')}</td>
          {summary.map((summaryItem, k) => (
            <td key={`summary-${k}`}>{summaryItem}</td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};

export default withTranslation()(CheckingAccountChart);
