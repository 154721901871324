import { useMemo } from 'react';

const calculateRange = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, i) => i + start);
};

export const useCustomPagination = (props) => {
  const { totalCount, pageSize, siblingCount = 1, currentPage } = props;

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return calculateRange(1, totalPageCount || 1);
    }

    const rightRange = currentPage + siblingCount;
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(rightRange, totalPageCount);

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    const hasEnoughSiblingLeft = leftSiblingIndex > 2;
    const hasEnoughSiblingRight = rightSiblingIndex < totalPageCount - 2;

    if (!hasEnoughSiblingLeft && hasEnoughSiblingRight) {
      const leftItemCount = 2 + 2 * siblingCount;
      const leftRange = calculateRange(1, leftItemCount);
      return [...leftRange, totalPageCount];
    }

    if (hasEnoughSiblingLeft && !hasEnoughSiblingRight) {
      const rightItemCount = 3 + 2 * siblingCount;
      const startRange = totalPageCount - rightItemCount + 1;
      const rightRange = calculateRange(startRange, totalPageCount);
      return [firstPageIndex, ...rightRange];
    }

    if (hasEnoughSiblingLeft && hasEnoughSiblingRight) {
      const itemsRange = calculateRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, ...itemsRange, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
