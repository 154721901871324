import { createSlice } from '@reduxjs/toolkit';
import { getConfigurations } from './thunk';

export const initialState = {
  isLoading: false,
  configuration: {},
  isError: false,
  error: {},
};

const leadCaptureSlice = createSlice({
  name: 'LeadCapture',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigurations.pending, (state, action) => {
      state.isLoading = true;
      state.configuration = {};
    });
    builder.addCase(getConfigurations.fulfilled, (state, action) => {
      const { labels, incentive, visualIdentity, ...rest } = action?.payload;
      state.isLoading = false;
      state.configuration = {
        ...rest,
        ...labels,
        ...incentive,
        ...visualIdentity,
      };
    });
    builder.addCase(getConfigurations.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });
  },
});

export default leadCaptureSlice.reducer;
