import { withTranslation } from "react-i18next";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { isEmpty } from "lodash";

import useQsParams from "../../Hooks/QueryString";

function RadioFilter({ item, values, t }) {
  const { qs, setSearchParams, setSearchParamsAsObject } = useQsParams();

  return (
    <div className='mb-4 filter-item'>
      <Label className='form-label text-muted text-uppercase fw-semibold mb-3 d-flex justify-content-between'>
        <span>{item.name}</span>

        {qs.get(item.field)?.length ? (
          <span
            className='fw-normal'
            onClick={() => setSearchParams(item.field, "")}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: 11,
            }}
          >
            {t("clear")}
          </span>
        ) : null}
      </Label>

      <Row className='g-2'>
        {(
          (!isEmpty(values) && values) ||
          (!isEmpty(item.values) && item.values) ||
          []
        ).map((value, index) => (
          <Col lg={12} key={`radio-${item.field}-${index}`}>
            <FormGroup check>
              <Input
                type='radio'
                className='form-check-input'
                name={item.field}
                defaultValue={value.value}
                id={`radio-${item.field}-${index}-field`}
                checked={qs.getAll(item.field).includes(value.value) ?? true}
                onChange={(e) => {
                  setSearchParamsAsObject({
                    [item.field]: e.currentTarget.value,
                    page: 1,
                  });
                }}
              />

              <Label
                className='form-check-label'
                htmlFor={`radio-${item.field}-${index}-field`}
              >
                {t(`radio-${item.field}-${value.value}`)}
              </Label>
            </FormGroup>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default withTranslation()(RadioFilter);
