import React, { useReducer, useState } from 'react';
import { Trans, withTranslation, getI18n } from 'react-i18next';
import classnames from 'classnames';
import {
  Badge,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { batchUpdateCustomerWalletStatus } from '../../slices/customers/thunk';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Importer, ImporterField, enUS, ptBR } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';

const initialValues = {
  status: undefined,
  reason: undefined,
  file: undefined,
  fields: undefined,
  rows: undefined,
};

const CsvStatusEdit = ({ isOpen, toggle, onSuccess, t, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const dispatch = useDispatch();
  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  const statuses = [
    {
      label: t('customer-wallet-status-blocked_redeem'),
      value: 'blocked_redeem',
    },
    {
      label: t('customer-wallet-status-blocked_accumulation'),
      value: 'blocked_accumulation',
    },
    { label: t('customer-wallet-status-blocked'), value: 'blocked' },
  ];

  const reasons = [
    {
      label: t('customer-wallet-status-reason-fraud'),
      value: t('customer-wallet-status-reason-fraud'),
    },
    {
      label: t('customer-wallet-status-reason-consumer_seller'),
      value: t('customer-wallet-status-consumer_seller'),
    },
    {
      label: t('customer-wallet-status-reason-other'),
      value: t('customer-wallet-status-reason-other'),
    },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: undefined,
      reason: undefined,
    },
    validationSchema: Yup.object({
      status: Yup.object({
        status: Yup.string(),
        label: Yup.string(),
      }).required(t('validation-required-value')),
    }),
    onSubmit: async () => {
      setIsLoading(true);
      const { columnFields, preview } = payload;
      const configurationParse = columnFields.reduce(
        (currentvalue, value, index) => {
          const column = preview.columns.find((val) => val.index === index);

          return {
            ...currentvalue,
            [value || '']: { column: column?.header },
          };
        },
        {}
      );

      const data = {
        file: payload.file,
        customers_status: payload.status,
        ...(payload?.reason ? { customers_status_reason: payload.reason } : {}),
        configuration_parse: JSON.stringify(configurationParse),
      };

      const action = await dispatch(
        batchUpdateCustomerWalletStatus({
          ...data,
          successMessage: t('csv-edit-wallets-success'),
          erroressage: t('csv-edit-wallets-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        validation.resetForm();
        onSuccess();
        toggle();
        setActiveTab('1');
        setPayload(initialValues);
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        size='lg'
        id='showModal'
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setActiveTab('1');
          validation.resetForm();
          setPayload(initialValues);
        }}
        centered
      >
        <ModalHeader className='bg-info-subtle p-3' toggle={toggle}>
          {t('csv-edit-wallet')}
        </ModalHeader>

        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Nav tabs className='nav nav-justified nav-tabs-wizard'>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '1' })}>
                  <Badge pill color='dark'>
                    1
                  </Badge>
                  <h6 className='mt-2'>{t('csv-edit-wallet-step1-heading')}</h6>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={classnames({ active: activeTab === '2' })}>
                  <Badge pill color='dark'>
                    2
                  </Badge>
                  <h6 className='mt-2'>{t('csv-edit-wallet-step2-heading')}</h6>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={classnames({ active: activeTab === '3' })}>
                  <Badge pill color='dark'>
                    3
                  </Badge>
                  <h6 className='mt-2'>{t('csv-edit-wallet-step3-heading')}</h6>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className='text-muted my-4'>
              <TabPane tabId='1' id='step1'>
                <h4>{t('csv-edit-wallet-step1-heading')}</h4>
                <Trans
                  t={t}
                  i18nKey={'csv-edit-wallet-step1-description'}
                  components={{
                    u: <u />,
                    b: <strong />,
                    bold: <strong className='bg-danger-subtle text-danger' />,
                  }}
                />

                <div className='mt-2'>
                  <Importer
                    locale={getI18n().language === 'pt' ? ptBR : enUS || ptBR}
                    dataHandler={async (rows) => {
                      await new Promise((resolve) => setTimeout(resolve, 2000));
                      setPayload({ rows });
                    }}
                    chunkSize={10000}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={false}
                    onComplete={({ columnFields, preview, file, fields }) =>
                      setPayload({ file, fields, columnFields, preview })
                    }
                    onClose={() => setActiveTab('2')}
                    delimiter={[',', ';', '\t', '\n']}
                    newline=""
                  >
                    <ImporterField name='document' label='CPF' />
                  </Importer>
                </div>
              </TabPane>

              <TabPane tabId='2' id='step2'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h4>{t('csv-edit-wallet-step2-heading')}</h4>
                  <span>{t('csv-edit-wallet-step2-description')}</span>
                  <Trans
                    t={t}
                    i18nKey={'csv-edit-wallet-step2-body'}
                    components={{
                      b: <strong style={{ display: 'contents' }} />,
                    }}
                  />

                  <div className='gap-2 mt-4 mb-2 w-50 justify-content-start'>
                    <Label className='form-label' htmlFor='wallet-status'>
                      {t('block-type')} <span className='text-danger'>*</span>
                    </Label>

                    <Select
                      name='status'
                      onChange={({ value }) => setPayload({ status: value })}
                      value={validation.values.status}
                      placeholder={t('select-placeholder')}
                      options={statuses}
                    />
                  </div>

                  <div className='gap-2 my-2 w-50 justify-content-start'>
                    <Label className='form-label' htmlFor='status-reason'>
                      {t('reason')}
                    </Label>

                    <Select
                      name='reason'
                      onChange={({ value }) => setPayload({ reason: value })}
                      value={validation.values.reason}
                      placeholder={t('select-placeholder')}
                      options={reasons}
                    />
                  </div>

                  <button
                    type='button'
                    className='btn btn-success align-right'
                    style={{ alignSelf: 'end' }}
                    disabled={!payload?.status}
                    onClick={() => setActiveTab('3')}
                  >
                    {t('confirm')}
                  </button>
                </div>
              </TabPane>

              <TabPane tabId='3' id='step3'>
                <h4>{t('csv-edit-wallet-step3-heading')}</h4>
                <p>{t('csv-edit-wallet-step3-description')}</p>

                <Table bordered striped>
                  <tbody>
                    <tr>
                      <th style={{ width: '30%' }} scope='row'>
                        {t('transactional-rule-wallet')}
                      </th>
                      <td>
                        {payload?.rows?.length} {t('items')}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '30%' }} scope='row'>
                        {t('blocked-status')}
                      </th>
                      <td>
                        {payload?.status && (
                          <Badge color='danger'>
                            {
                              statuses.find((s) => s.value === payload.status)
                                .label
                            }
                          </Badge>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '30%' }} scope='row'>
                        {t('blocked-status-reason')}
                      </th>
                      <td>
                        {payload?.reason
                          ? reasons.find((s) => s.value === payload.reason)
                              .label
                          : t('unavailable')}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div className='hstack gap-2 justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => setActiveTab('2')}
                  >
                    {t('back')}
                  </button>

                  <button type='submit' className='btn btn-success'>
                    {t('confirm')}
                  </button>
                </div>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CsvStatusEdit);
