import { useEffect } from 'react';
import { Card, CardBody, CardFooter, Col, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

import ActionButton from '../../../Components/Common/ActionButton';
import ColorPicker from '../../../Components/Common/ColorPicker';

import { getCustomers } from '../../../slices/customers/thunk';
import { iFramePostMessage } from '../frame_utils';
import i18n from '../../../i18n';

export const VISUAL_PICKERS = [
  {
    label: i18n.t('my-wallet.step3.picker.background-title'),
    description: i18n.t('my-wallet.step3.picker.background-description'),
    state: 'backgroundColor',
    preview: {
      selector: '.wallet_background-color',
      property: 'background',
    },
  },

  {
    label: i18n.t('my-wallet.step3.picker.available-text-title'),
    description: i18n.t('my-wallet.step3.picker.available-text-description'),
    state: 'cashbackAvailableTextColor',
    preview: {
      selector: '.wallet_cashback-available-text-color',
      property: 'color',
    },
  },

  {
    label: i18n.t('my-wallet.step3.picker.available-amount-title'),
    description: i18n.t('my-wallet.step3.picker.available-amount-description'),
    state: 'cashbackAvailableColor',
    preview: {
      selector: '.wallet_cashback-available-color',
      property: 'color',
    },
  },

  {
    label: i18n.t('my-wallet.step3.picker.pending-title'),
    description: i18n.t('my-wallet.step3.picker.pending-description'),
    state: 'cashbackPendingColor',
    preview: {
      selector: '.wallet_cashback-pending-color',
      property: 'color',
    },
  },

  {
    label: i18n.t('my-wallet.step3.picker.expiring-title'),
    description: i18n.t('my-wallet.step3.picker.expiring-description'),
    state: 'cashbackToExpireTextColor',
    preview: {
      selector: '.wallet_cashback-to-expire-text-color',
      property: 'color',
    },
  },

  {
    label: i18n.t('my-wallet.step3.picker.expiring-amount-title'),
    description: i18n.t('my-wallet.step3.picker.expiring-amount-description'),
    state: 'cashbackToExpireColor',
    preview: {
      selector: '.wallet_cashback-to-expire-color',
      property: 'color',
    },
  },
];

const Step3 = ({
  state,
  dispatch,
  formik,
  initialValues,
  disabled,
  ...props
}) => {
  const reduxDispatch = useDispatch();

  const customer = useSelector(
    createSelector(
      (store) => store.Customer,
      (slice) =>
        slice.customers.reduce((acc, customer) => {
          const cellphone = customer?.cellphone ?? '';
          const document = customer?.document ?? '';

          return document && cellphone ? { document, contact: cellphone } : acc;
        }, {})
    )
  );

  useEffect(() => {
    const authListener = (e) => {
      if (e?.data?.type === 'ready' && customer) {
        iFramePostMessage({ type: 'auth', ...customer });
      }
    };

    window.addEventListener('message', authListener, { once: true });
    return () => window.removeEventListener('message', authListener);
  }, [customer]);

  useEffect(() => {
    dispatch({ step: 'wallet' });
    reduxDispatch(getCustomers({ filters: { status: { in: ['active'] } } }));
  }, []);

  return (
    <Card className="m-0">
      <CardBody>
        <div className="d-flex gap-4 text-secondary">
          <h3 className="mb-4">{props.t('my-wallet.step2.elements')}</h3>
          {disabled && <Spinner />}
        </div>

        <Row className="row row-cols-1 g-3 mb-5">
          {Object.keys(formik?.values)?.length
            ? VISUAL_PICKERS?.map(({ state: key, ...props }, i) => (
                <Col key={`pickr-${i}`}>
                  <ColorPicker
                    {...props}
                    disabled={disabled}
                    value={formik?.values?.['wallet']?.[key] || '#fff'}
                    invalid={formik?.errors[key]}
                    onChange={(c) => {
                      dispatch({ [key]: c });
                      formik.setFieldValue(`wallet.${key}`, c);

                      const { selector, property } = props.preview;
                      const opts = { selector, property, value: c };
                      iFramePostMessage({ type: 'style', ...opts });
                    }}
                  />
                </Col>
              ))
            : null}
        </Row>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          label="Salvar"
          icon="bx-right-arrow-alt"
          onClick={async () => await formik.submitForm()}
        />
      </CardFooter>
    </Card>
  );
};

export default withTranslation()(Step3);
