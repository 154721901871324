import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Col, Input, Label, UncontrolledCollapse } from "reactstrap";

import useQsParams from "../Hooks/QueryString";
import classNames from "classnames";
import Search from "./Search";

const FilterByUserStores = ({ t }) => {
  const userAuth = sessionStorage.getItem("authUser");
  const { qs, searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const prevSelectedStores =
    searchParams?.get("filterBy")?.split(",")?.map(Number) ?? [];

  const [allStores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([...prevSelectedStores]);
  const hasStores = !isEmpty(allStores) && allStores;

  const [list, setList] = useState([]);
  const [result] = useState("");

  const _manageParamAsString = (_key, value) => {
    const raw = qs.get(_key);
    const ARRAY = raw ? raw.split(",") : [];
    const existing = _.uniq([...ARRAY].map(Number));

    if (existing.indexOf(value) != -1) {
      existing.splice(existing.indexOf(value), 1);
    } else {
      existing.push(value);
    }

    setSearchParamsAsObject({
      [_key]: existing.join(","),
      page: 1,
    });

    return existing.join(",");
  };

  const filterResults = (v) => {
    if (!v?.length) return setList(allStores);

    setList(
      allStores.filter((item) =>
        item?.name?.toLowerCase()?.includes(v?.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (userAuth) {
      const user = JSON.parse(userAuth);
      setStores([...user?.stores]);
      setList([...user?.stores]);
    }
  }, []);

  useEffect(() => {
    const hasStoreSelection = qs.get("filterBy");

    if (hasStoreSelection) {
      sessionStorage.setItem("filterByStores", hasStoreSelection);
    }
  }, []);

  if (!hasStores) return null;

  const isOpened = qs.get("opened")?.split(",")?.includes(String(899));
  const TRIGGER = `my-stores-filter-trigger`;

  return (
    <div className='filter-drawer-group-wrapper'>
      <span id={TRIGGER} className='px-3'>
        <div
          style={{ cursor: "pointer" }}
          className='d-flex justify-content-between align-items-center px-3'
          onClick={() => _manageParamAsString("opened", 899)}
        >
          <span className='fw-semibold fs-5'>{t("my-stores")}</span>

          <i
            className={classNames({
              "transition bx bxs-down-arrow": true,
              rotate180deg: isOpened,
            })}
          />
        </div>
      </span>

      <UncontrolledCollapse
        className='px-3'
        defaultOpen={isOpened}
        toggler={`#${TRIGGER}`}
      >
        <div className='mt-2 group-collapsible'>
          <div className='mb-4'>
            <Label className='form-label text-muted text-uppercase fw-semibold mb-3 d-flex justify-content-between'>
              <span>{t("my-stores")}</span>

              {qs.get("filterBy")?.length && list?.length ? (
                <span
                  className='fw-normal'
                  onClick={() => {
                    setSearchParams("filterBy", "");
                    sessionStorage.removeItem("filterByStores");
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: 11,
                  }}
                >
                  {t("clear")}
                </span>
              ) : null}
            </Label>

            {allStores?.length >= 10 ? (
              <div className='mb-2'>
                <Search
                  placeholder={`Filtrar ${allStores?.length} lojas`}
                  onSubmit={({ id }) => filterResults(id)}
                  onChange={filterResults}
                  value={result}
                />
              </div>
            ) : null}

            <div
              className='px-1'
              style={{
                maxHeight: 150,
                borderRadius: 4,
                border: "1px solid #ced4da",
                overflowY: "scroll",
                overflowX: "auto",
              }}
            >
              {(list || []).map((value, index) => {
                const raw = qs.get("filterBy")?.split(",");
                const checked = raw?.includes(String(value.id)) ?? false;

                const onChange = (e) => {
                  const stores = _manageParamAsString("filterBy", value.id);
                  sessionStorage.setItem("filterByStores", stores);
                };

                return (
                  <Col lg={12} key={index}>
                    <div className='form-check my-1' key={index}>
                      <Input
                        className='form-check-input'
                        id={`checkbox-mystores-${index}`}
                        checked={checked}
                        onChange={onChange}
                        type='checkbox'
                      />

                      <Label
                        htmlFor={`checkbox-mystores-${index}`}
                        className='form-check-label'
                      >
                        {value.name}
                      </Label>
                    </div>
                  </Col>
                );
              })}
            </div>
          </div>
        </div>
      </UncontrolledCollapse>
    </div>
  );
};

export default withTranslation()(FilterByUserStores);
