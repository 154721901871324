import { useState } from 'react';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const BlockedItemDropdown = ({ onClickEdit, onClickDisable}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="text-left">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="start">
        <DropdownToggle className="text-muted w-auto border-0 bg-transparent p-0">
          <i className="bx bx-list-ul bx-sm align-bottom" />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={onClickEdit}>
            <div className="d-flex align-top">
              <i className="bx bx-edit text-muted bx-xs me-2" />
              <span>Editar Status</span>
            </div>
          </DropdownItem>

          <DropdownItem onClick={onClickDisable}>
            <div className="d-flex align-top">
              <i className="bx bx-lock-open-alt text-muted bx-xs me-2" />
              <span>Desbloquear Item</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default BlockedItemDropdown;
