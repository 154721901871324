import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getGeneralSettings } from "../../slices/general-settings/thunk";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MasterToken from "./components/MasterToken";

const GeneralSettings = (props) => {
  const title = props.t("general-settings.title");
  const description = props.t("general-settings.description");
  const pageTitle = props.t("account");

  document.title = `${title} | Opencashback`;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGeneralSettings());
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb {...{ title, description, pageTitle }} />

        <Row>
          <Col lg={6}>
            <MasterToken />
          </Col>
        </Row>
      </Container>

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </div>
  );
};

export default withTranslation()(GeneralSettings);
