import { withTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';

import IndicatorsProps from '../../types/IndicatorsProps';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import AvgTicket from './AvgTicket';
import Card from '../Card';
import { useState } from 'react';

const AverageTicketIndicators = (props: IndicatorsProps): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ marginBottom: isOpen ? 50 : 0 }}>
      <BreadCrumb
        title={props?.t('reports.average-ticket.heading')}
        onClick={toggle}
        description={props?.t(
          isOpen ? 'reports.click-to-hide' : 'reports.click-to-expand'
        )}
      />

      <Collapse isOpen={isOpen}>
        <Row>
          <Col>
            <Card body={<AvgTicket {...props} />} />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default withTranslation()(AverageTicketIndicators);
