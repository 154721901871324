import { getI18n } from "react-i18next";
import pt from "apexcharts/dist/locales/pt-br.json";
import en from "apexcharts/dist/locales/en.json";

export const defaultLocale = {
    pt: 'pt-br',
    en: 'en',
  }[getI18n().language ?? 'pt']

const chartLocales = {
  locales: [pt, en],
  defaultLocale,
};

export default chartLocales;