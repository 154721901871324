import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import chartLocales from '../../utils/locales';

const SalesVolumeByOrderTypeChart = ({ data, t }): React.JSX.Element => {
  const indicators = data?.billing?.data;

  const currentMonthSeries = indicators.filter((v) => {
    const currentMonth = new Date().getMonth();
    const desiredIndex = currentMonth === 0 ? 11 : currentMonth;
    return new Date(v.created_at).getMonth() === desiredIndex;
  });

  return (
    <ReactApexChart
      type="line"
      height={350}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        title: {
          align: 'center',
          text: t('reports.sales-volume.month.daily.title'),
        },

        subtitle: {
          align: 'center',
          text: t('reports.sales-volume.month.daily.subtitle'),
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [2, 2, 2, 4] },

        labels: currentMonthSeries?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },
      }}
      series={[
        {
          name: t('reports.sales-volume.month.daily.chart.qty-impacted'),
          data: currentMonthSeries?.map((i) => i?.sales_quantity_impacted),
        },

        {
          name: t('reports.sales-volume.month.daily.chart.qty-mixed'),
          data: currentMonthSeries?.map((i) => i?.sales_quantity_mixed),
        },

        {
          name: t('reports.sales-volume.month.daily.chart.qty-incremental'),
          data: currentMonthSeries?.map((i) => i?.sales_quantity_incremental),
        },

        {
          name: t('reports.sales-volume.month.daily.chart.qty-no-cashback'),
          data: currentMonthSeries?.map(
            (i) => i?.sales_quantity_without_cashback
          ),
        },
      ]}
    />
  );
};

export default withTranslation()(SalesVolumeByOrderTypeChart);
