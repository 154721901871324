import { useEffect, useState } from 'react';
import Wizard from '../../../../../../Components/Common/Wizard';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';

const Step2 = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const step = {
      1: 'form',
      2: 'success',
      3: 'error',
    }[activeTab];

    props.dispatch({ step });
  }, [activeTab]);

  return (
    <Wizard
      showSteps
      enableNavigation
      activeTab={activeTab}
      onSelectTab={setActiveTab}
      tabs={[
        {
          label: 'Tela inicial',
          component: <Tab1 {...props} setActiveTab={setActiveTab} />,
        },

        {
          label: 'Sucesso ao criar carteira',
          component: <Tab2 {...props} setActiveTab={setActiveTab} />,
        },

        { label: 'Erro ao criar carteira', component: <Tab3 {...props} /> },
      ]}
    />
  );
};

export default Step2;
