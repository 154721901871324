export default function getStatusBadge(status, t) {
  switch (status) {
    case "001":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.001"),
      };

    case "002":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.002"),
      };

    case "003":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.003"),
      };

    case "004":
      return {
        classes: "bg-success-subtle text-success",
        label: t("integrations.vtex.status.004"),
      };

    case "005":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.005"),
      };

    case "006":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.006"),
      };

    case "007":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.007"),
      };

    case "008":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.008"),
      };

    case "009":
      return {
        classes: "bg-danger-subtle text-danger",
        label: t("integrations.vtex.status.009"),
      };

    default:
      return {
        classes: "bg-light-subtle text-muted",
        label: "•••",
      };
  }
}
