import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

import { useDispatch } from 'react-redux';
import { cancelIncentive } from '../../../../slices/incentives/thunk';

const CancelIncentive = ({ isOpen, toggle, incentive, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const cancelIncentiveApi = async () => {
    setIsLoading(true);

    const action = await dispatch(
      cancelIncentive({
        id: incentive.incentive_id,
        successMessage: t(
          'incentives-management.cancel-incentive.successMessage'
        ),
        errorMessage: t('incentives-management.cancel-incentive.errorMessage'),
        incentiveUsedErrorMessage: t(
          'incentives-management.cancel-incentive.incentive-used-error-message'
        ),
        incentiveExpiredErrorMessage: t(
          'incentives-management.cancel-incentive.incentive-expired-error-message'
        ),
      })
    );

    setIsLoading(false);
    toggle();

    if (!action.payload.error) {
      onSuccess();
    }
  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('incentives-management.cancel-incentive.cancel')}
        </ModalHeader>

        <ModalBody>
          <h4 className="mb-4 text-center">
            {t('incentives-management.cancel-incentive.modal-description')}
          </h4>
          <br></br>
          <h4 className="mb-4 text-center">
            {t('incentives-management.cancel-incentive.question')}
          </h4>
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={toggle}>
              {t('close')}
            </button>
            <button
              onClick={cancelIncentiveApi}
              type="submit"
              disabled={!incentive}
              className="btn btn-success"
              id="add-btn"
            >
              <div className="d-flex">
                {isLoading && <Spinner size="sm" className="me-3" />}
                {t('confirm')}
              </div>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CancelIncentive);
