import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Card as CardWrapper, CardBody } from "reactstrap";

import Card from "./Card";

import chartLocales from "../utils/locales";
import { convertCents, formatCurrency } from "../../../helpers/format";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import useQsParams from "../../../Components/Hooks/QueryString";
import {
  getCashbackCardsData,
  getCashbackChartData,
} from "../../../slices/manager-panel/thunk";
import { getFilters } from "../../../Components/Common/Filter";
import { isEmpty } from "lodash";

const Cashback = () => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [filtersParams, setFiltersParams] = useState({});

  const { cashback } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ cashback }) => ({ cashback })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  const filters = [
    {
      field: "created_at",
    },

    {
      field: "channel",
    },

    {
      field: "store_id",
    },
  ];

  const getData = () => {
    const params = {};
    const appliedFilters = getFilters(filters, [
      { field: "created_at", type: "between_date" },
      { field: "channel", type: "in" },
      { field: "store_id", type: "in" },
    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getCashbackCardsData(params));
    dispatch(getCashbackChartData(params));
  };

  return (
    <div>
      <h2 className="mb-3">Cashback</h2>

      <Row>
        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.generated?.length ? (
            <Card title="Cashback gerado" data={cashback?.cards?.generated} />
          ) : (
            <div className="skeleton card" style={{ height: "101px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.redeemed?.length ? (
            <Card title="Cashback Resgatado" data={cashback?.cards?.redeemed} />
          ) : (
            <div className="skeleton card" style={{ height: "101px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.expired?.length ? (
            <Card title="Cashback Expirado" data={cashback?.cards?.expired} />
          ) : (
            <div className="skeleton card" style={{ height: "101px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {cashback?.cards?.available?.length ? (
            <Card title="Cashback Disponível" data={cashback?.cards?.available} />
          ) : (
            <div className="skeleton card" style={{ height: "101px" }} />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <CardWrapper>
            <CardBody>
              {cashback?.chart?.data?.length ? (
                <ReactApexChart
                  type="bar"
                  height={350}
                  options={{
                    chart: {
                      stacked: true,
                      toolbar: { show: false },
                      ...chartLocales,
                    },
                    legend: {
                      onItemHover: { highlightDataSeries: true },
                      onItemClick: { toggleDataSeries: true },
                    },

                    markers: { size: 5 },
                    stroke: { width: [1, 1, 1, 2] },
                    colors: ["#1b73e8", "#aaff03", "#ff6400", "#ec616c"],
                    labels: cashback?.chart?.data?.map((i) => i?.date),

                    xaxis: {
                      labels: {
                        formatter: (v) => v,
                      },
                    },

                    yaxis: {
                      labels: {
                        formatter: (v) => formatCurrency(v, { convert: true }),
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Cashback disponível",
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_available_cashback)
                      ),
                    },

                    {
                      name: "Cashback resgatado",
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_redeemed_cashback)
                      ),
                    },

                    {
                      name: "Cashback expirado",
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.total_expired_cashback)
                      ),
                    },

                    {
                      name: "Incremental",
                      data: cashback?.chart?.data?.map((i) =>
                        convertCents(i?.average_ticket_incremental)
                      ),
                    },
                  ]}
                />
              ) : (
                <div className="skeleton card" style={{ height: 350 }} />
              )}
            </CardBody>
          </CardWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Cashback);
