import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { getIn } from 'formik';

import useQsParams from '../../../Components/Hooks/QueryString';
import { iFramePostMessage } from '../frame_utils';

import { VISUAL_PICKERS as STEP2_FIELDS } from './Step2';
import { VISUAL_PICKERS as STEP3_FIELDS } from './Step3';

function Preview({ formik, hideOverlay, t }) {
  const URL = `${process.env.REACT_APP_API_MY_WALLET_URL}`;
  const [loading, setLoading] = useState(true);
  const { qs } = useQsParams();

  useEffect(() => {
    if (formik.values) setLoading(false);
  }, [loading, formik.values]);

  if (loading) return null;

  const applyColorsToKey = (fields, key, timeout) => {
    setTimeout(() => {
      fields.map(({ state, preview }) => {
        const value = getIn(formik.values, `${key}.${state}`);
        iFramePostMessage({ type: 'style', ...preview, value });
      });
      hideOverlay(true);
    }, timeout);
  };

  // step 1
  if (!qs.get('active') || (qs.get('active') && qs.get('active') == 1)) {
    hideOverlay(true);
    return (
      <div
        className="rounded-3 w-100 h-100 d-flex flex-col justify-content-center align-items-center"
        style={{ background: '#fafafa' }}
      >
        <div className="text-center">
          {formik.values.logoUrl && (
            <>
              <p className="m-0 fs-5">{t('my-wallet.step1.logo')}</p>
              <img
                src={formik.values.logoUrl}
                style={{ maxHeight: 200 }}
                className="mb-5"
              />
            </>
          )}

          <div>
            <p className="m-0 fs-5">
              {t('my-wallet.step1.picker.spinner-title')}
            </p>
            <p>{t('my-wallet.step1.picker.spinner-description')}</p>

            <div style={{ color: formik.values.spinnerColor }}>
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // step 2
  if (qs.get('active') && qs.get('active') == 2) {
    return (
      <iframe
        id="preview"
        className="w-100 h-100 rounded-3"
        src={`${URL}/${formik.values.path}/login`}
        onLoad={() => {
          applyColorsToKey(STEP2_FIELDS, 'auth', 800);

          setTimeout(() => {
            iFramePostMessage({ type: 'image', value: formik.values.logoUrl });
          }, 700);
        }}
      />
    );
  }

  // step 3
  if (qs.get('active') && qs.get('active') == 3) {
    return (
      <iframe
        id="preview"
        className="w-100 h-100 rounded-3"
        src={`${URL}/${formik.values.path}`}
        onLoad={() => applyColorsToKey(STEP3_FIELDS, 'wallet', 5500)}
      />
    );
  }

  // default
  return (
    <div className="text-center">
      <h4>{t('my-wallet.fail.description')}</h4>
      <p className="m-0">{t('my-wallet.fail.description')}</p>
    </div>
  );
}

export default withTranslation()(Preview);
