import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { first, isEmpty } from "lodash";
import { createSelector } from "reselect";
import Select from "react-select";

import { isInvalid } from "../../helpers/functions";
import { getRoles, updateUser, getStoresRaw } from "../../slices/thunks";

import Dropdown from "../../Components/Common/Dropdown";
import ActionButton from "../../Components/Common/ActionButton";
import useQsParams from "../../Components/Hooks/QueryString";

const isAdminSelected = (roleLabel) => {
  if (!roleLabel) return false;
  return String(roleLabel)?.toLowerCase().includes("administrador");
};

const EditUserForm = ({ onSuccess, t }) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParamsAsObject } = useQsParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const statusOptions = [
    { label: t("user-status-active"), value: "active" },
    { label: t("user-status-inactive"), value: "inactive" },
    { label: t("user-status-blocked"), value: "blocked" },
  ];

  const data = useSelector(
    createSelector(
      (store) => ({ stores: store?.Stores, roles: store?.User }),
      (slice) => ({
        stores: {
          isLoading: slice?.stores?.isLoading,
          data: [...slice?.stores?.stores]?.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        },
        roles: {
          isLoading: slice?.roles?.isLoadingRoles,
          data: [...slice?.roles?.roles]?.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        },
      })
    )
  );

  const urlData = JSON.parse(decodeURI(searchParams.get("edit")));
  const status = statusOptions.find((item) => item.value === urlData?.status);
  const role = first(urlData?.roles);

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getStoresRaw());
  }, [searchParams.toString()]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: urlData?.id,
      name: urlData?.name ?? "",
      email: urlData?.email ?? "",
      status: status || {},
      role:
        urlData?.roles && !isEmpty(urlData.roles)
          ? { label: role?.name, value: role?.id }
          : {},
      stores:
        urlData?.stores?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [],
    },
    validationSchema: Yup.object({
      id: Yup.number().required(),
      name: Yup.string().required(t("name-placeholder")),
      email: Yup.string()
        .required(t("validation-required-email"))
        .email(t("validation-valid-email")),
      status: Yup.object().required(t("validation-required-status")),
      role: Yup.object().required(t("validation-required-role")),
      stores: Yup.array().when("role", {
        is: (k) => !k.label?.toLowerCase().includes("administrador"),
        then: (schema) =>
          schema
            .min(1, t("validation-required-store-at-least-one"))
            .required(t("required")),
      }),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const isAdmin = isAdminSelected(values?.role?.label);

      const action = await dispatch(
        updateUser({
          id: values?.id,
          user: {
            name: values?.name,
            email: values?.email,
            status: values?.status?.value,
            roles: [values?.role?.value],
            stores_ids: !isAdmin
              ? values?.stores?.map((item) => item.value)
              : [],
          },
          successMessage: t("edit-user-success"),
          errorMessage: t("edit-user-error"),
        })
      );

      setIsSubmitting(false);

      if (!action.payload.error) {
        formik.resetForm();
        onSuccess(action.payload);
        setSearchParamsAsObject({ edit: "" });
      }
    },
  });

  return (
    <>
      <div className="row">
        <div className="col flex-grow-1">
          <Card className="m-0">
            <CardBody>
              <div className="d-flex gap-2">
                <div
                  className="d-flex flex-row gap-3 align-items-center"
                  style={{ height: 40 }}
                >
                  <h4 className="m-0">{t("edit-user")}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-lg-auto col-12 mt-lg-0 mt-3">
          <Card className="m-0 h-100">
            <CardBody>
              <ActionButton
                label={t("save")}
                icon="bx-check"
                disabled={
                  data?.stores?.isLoading ||
                  data?.roles?.isLoading ||
                  isSubmitting
                }
                onClick={formik.submitForm}
              />
            </CardBody>
          </Card>
        </div>
      </div>

      <Form className="tablelist-form mt-5" onSubmit={formik.handleSubmit}>
        <Row className="g-3">
          <Col lg={12}>
            <Label htmlFor="name-field" className="form-label">
              {t("name")}
            </Label>
            <div className="input-group">
              <Input
                name="name"
                id="name-field"
                className="form-control"
                placeholder={t("name-placeholder")}
                type="text"
                validate={{ required: { value: false } }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ""}
                invalid={isInvalid(formik, "name") ? true : false}
              />
              {isInvalid(formik, "name") && (
                <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
              )}
            </div>
          </Col>

          <Col lg={12}>
            <Label htmlFor="email-field" className="form-label">
              {t("email")}
            </Label>
            <div className="input-group">
              <Input
                name="email"
                id="email-field"
                className="form-control"
                placeholder={t("email-placeholder")}
                type="text"
                validate={{ required: { value: false } }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email || ""}
                invalid={isInvalid(formik, "email") ? true : false}
              />
              {isInvalid(formik, "email") && (
                <FormFeedback type="invalid">
                  {formik.errors.email}
                </FormFeedback>
              )}
            </div>
          </Col>

          <Col lg={12}>
            <Label htmlFor="status-field" className="form-label">
              {t("status")}
            </Label>
            <div className="input-group">
              <Select
                name="status"
                id="status-field"
                className="form-group w-100"
                placeholder={t("dropdown.placeholder")}
                value={formik.values.status}
                onChange={(e) => formik.setFieldValue("status", e)}
                options={statusOptions}
              />
              {isInvalid(formik, "status") && (
                <FormFeedback className="d-block" type="invalid">
                  {formik.errors.status}
                </FormFeedback>
              )}
            </div>
          </Col>

          <Col lg={12}>
            <div>
              <Label htmlFor="role-field" className="form-label">
                {t("permission")}
              </Label>
              <div className="input-group">
                <Select
                  name="group"
                  id="group-field"
                  className="form-group w-100"
                  placeholder={t("dropdown.placeholder")}
                  value={formik.values.role}
                  onChange={(e) => formik.setFieldValue("role", e)}
                  options={data?.roles?.data}
                />
                {isInvalid(formik, "role") && (
                  <FormFeedback className="d-block" type="invalid">
                    {formik.errors.role}
                  </FormFeedback>
                )}
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <Label htmlFor="store-field" className="form-label">
              {t("store")}
            </Label>

            {isAdminSelected(formik.values.role?.label) ? (
              <Select
                isDisabled
                options={[{ label: "Todas", value: "0" }]}
                value={{ label: "Todas", value: "0" }}
              />
            ) : (
              <>
                <Dropdown
                  isMulti
                  disabled={data?.stores?.isLoading}
                  values={formik?.values?.stores}
                  options={data?.stores?.data}
                  onChange={(values) => formik.setFieldValue("stores", values)}
                />
                {isInvalid(formik, "stores") && (
                  <FormFeedback className="d-block" type="invalid">
                    {formik.errors.stores}
                  </FormFeedback>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withTranslation()(EditUserForm);
