import { useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import QRCode from "react-qr-code";

import useQsParams from "../../../../Components/Hooks/QueryString";
import ActionButton from "../../../../Components/Common/ActionButton";

const ModalQr = ({ value }) => {
  const { qs, setQs } = useQsParams();
  const ref = useRef(null);

  const downloadImage = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");

      downloadLink.download = "OCK-QrCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Modal
      id="leadCapture-qr"
      isOpen={String(qs.get("modal")) === "true"}
      toggle={() => setQs("modal", "")}
      centered
    >
      <ModalHeader
        className="bg-info-subtle p-3"
        toggle={() => setQs("modal", "")}
      >
        Aponte sua câmera ou baixe o arquivo
      </ModalHeader>

      <ModalBody>
        <div ref={ref} className="mx-auto text-center">
          <QRCode className="d-none" id="QRCode" value={value} size={1024} />
          <QRCode value={value} />
        </div>
      </ModalBody>

      <ModalFooter className="border-top pt-3 justify-content-center">
        <ActionButton
          style="primary"
          label="Baixar QR Code"
          icon="bx-cloud-download"
          onClick={downloadImage}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ModalQr;
