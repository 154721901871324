import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMyWalletConfiguration as getMyWalletConfigurationApi,
  setMyWalletConfiguration as setMyWalletConfigurationApi,
} from '../../helpers/bff_helper';

export const getMyWalletConfiguration = createAsyncThunk(
  'my-wallet/getConfigurations',
  () => {
    try {
      return getMyWalletConfigurationApi();
    } catch (error) {
      return error;
    }
  }
);

export const setMyWalletConfigurations = createAsyncThunk(
  'my-wallet/setConfigurations',
  async (params) => {
    try {
      await setMyWalletConfigurationApi(params);
      return { status: 200, statusText: 'Success' };
    } catch (error) {
      return error;
    }
  }
);
