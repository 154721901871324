import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { useFormik } from "formik";
import * as Yup from "yup";

import { isInvalid } from "../../helpers/functions";
import { getIntegrations, putIntegration } from "../../slices/thunks";
import useQsParams from "../../Components/Hooks/QueryString";

import RenderGroup from "./components/render-group";
import ActionButton from "../../Components/Common/ActionButton";
import Switch from "../../Components/Common/Filter/Switch";
import Text from "../../Components/Common/Filter/Text";
import { toast } from "react-toastify";
import getStatusBadge from "./statuses";

const IntegrationSettings = ({ t }) => {
  const { setSearchParamsAsObject } = useQsParams();
  const dispatch = useDispatch();

  const [prevTitle, setPrevTitle] = useState("");
  const [editTitle, setEditTitle] = useState(false);

  const { isLoading, data, status } = useSelector(
    createSelector(
      (store) => store.Integrations,
      (slice) => slice
    )
  );

  useEffect(() => {
    dispatch(getIntegrations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    initialValues: {
      order_status_done: "invoiced",
      ...data,
      // giftcard_as_discount: false,
      // showcase_badge_position: "right-bottom",
      // updated_at: "2024-06-11T15:07:54.520Z",
      // created_at: null,
    },

    validationSchema: Yup.object({
      integration: Yup.string().required(t("required")),
      url: Yup.string().required(t("required")),
      app_key: Yup.string().required(t("required")),
      access_token: Yup.string().required(t("required")),
      order_status_done: Yup.string().required(t("required")),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const { payload } = await dispatch(
        putIntegration({
          values,
          success: t("integrations.success"),
          error: t("integrations.error"),
        })
      );

      formik.setSubmitting(false);

      const hasError = payload?.error;
      const hasErrorId = payload?.message?.id;

      if (!hasError)
        return setSearchParamsAsObject({
          settings: "",
          opened: "",
        });

      if (hasError && hasErrorId) {
        toast.error(t(`integrations.vtex.errors.${hasErrorId}`));
      }

      return;
    },
  });

  const generateIntegrationBadge = (key) => {
    const statusId = status?.[key]?.status_id;
    const hideStatuses = ["004", "007", "009"].includes(statusId);
    if (!statusId || hideStatuses) return false;

    const { label, classes } = getStatusBadge(statusId, t);
    return <span className={`badge rounded-pill ${classes}`}>{label}</span>;
  };

  return (
    <>
      <div className='row'>
        <div className='col flex-grow-1'>
          <Card className='m-0'>
            <CardBody>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-column'>
                  {editTitle ? (
                    <div
                      className='w-100 d-flex flex-column flex-lg-column gap-1 align-lg-items-center'
                      style={{ minHeight: 38 }}
                    >
                      <h4 className='m-0 d-flex d-xl-none'>Integração:</h4>

                      <div className='d-flex'>
                        <Input
                          type='text'
                          id='integration'
                          className='me-2 m-0 py-2'
                          value={formik.values.integration}
                          onChange={(e) => {
                            formik.setFieldValue("integration", e.target.value);
                          }}
                        />

                        <div className='d-flex flex-row gap-2 mt-1'>
                          <button
                            type='button'
                            className='p-0 lw-1 bg-white border-0'
                            onClick={() => {
                              setEditTitle(!editTitle);
                              formik.setFieldValue(
                                "integration",
                                prevTitle?.length ? prevTitle : data.integration
                              );
                            }}
                          >
                            <i className='bx bx-x fs-2 text-warning' />
                          </button>

                          <button
                            type='button'
                            disabled={!formik.values.integration}
                            style={{
                              opacity: !formik.values.integration ? 0.5 : 1,
                            }}
                            className='p-0 lw-1 bg-white border-0'
                            onClick={() => {
                              if (!formik.values.integration) {
                                formik.setFieldValue("integration", prevTitle);
                              }
                              setPrevTitle(formik.values.integration);
                              setEditTitle(!editTitle);
                            }}
                          >
                            <i className='bx bx-check fs-2 text-success' />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : isLoading && !formik.values.integration ? (
                    <div className='skeleton bg-light' style={{ height: 35 }} />
                  ) : (
                    <div
                      className='d-flex flex-row gap-2 align-items-center'
                      style={{ height: 38 }}
                    >
                      <h4 className='m-0'>
                        Integração:&nbsp;&ldquo;
                        {formik.values.integration}
                        &rdquo;
                      </h4>

                      <button
                        type='button'
                        className='p-0 mt-1 lw-1 bg-white border-0'
                        onClick={() => setEditTitle(!editTitle)}
                      >
                        <i className='bx bxs-edit-alt fs-3 text-muted' />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className='col-lg-auto col-12 mt-lg-0 mt-3'>
          <Card className='m-0 h-auto'>
            <CardBody>
              <ActionButton
                icon='bx-check'
                label={t("save")}
                onClick={formik.submitForm}
                disabled={
                  isLoading ||
                  formik.isSubmitting ||
                  formik.isError ||
                  !formik?.isValid
                }
              />
            </CardBody>
          </Card>
        </div>
      </div>

      <Form
        className='tablelist-form mt-5'
        autoComplete={new Date().getUTCMilliseconds()}
        onSubmit={formik.handleSubmit}
      >
        <div style={{ borderBottom: "1px solid #EAECED" }}>
          <Switch
            item={{ field: "enabled" }}
            label='Habilitar integração'
            description='Permitir integração de vendas e criação de carteiras'
            className='justify-content-between'
            onChange={(v) => formik.setFieldValue("enabled", v)}
            value={formik.values.enabled}
            disabled={isLoading}
            size='sm'
          />
        </div>

        <div className='d-flex flex-column justify-content-end h-100 overflow-auto px-0'>
          <div className='offcanvas-body p-0 filter-drawer-wrapper'>
            <RenderGroup id='keys' title='Chaves' opened>
              <div className='mt-2 group-collapsible'>
                <Text
                  id='url'
                  item={{ name: "URL MyVTEX", field: "url" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.url}
                  invalid={isInvalid(formik, "url")}
                  placeholder='Informe uma URL MyVTEX'
                  disabled={isLoading}
                />

                <Text
                  id='app_key'
                  item={{ name: "Key VTEX", field: "app_key" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.app_key}
                  invalid={isInvalid(formik, "app_key")}
                  placeholder='Informe uma chave válida'
                  disabled={isLoading}
                />

                <Text
                  id='access_token'
                  item={{ name: "Token VTEX", field: "access_token" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.access_token}
                  invalid={isInvalid(formik, "access_token")}
                  placeholder='Informe um token válido'
                  disabled={isLoading}
                  showToggle
                  type='password'
                  dry
                />

                <Text
                  item={{
                    name: "Token plugin Opencashback",
                    field: "ock_api_key",
                  }}
                  id='ock_key'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ock_api_key}
                  invalid={isInvalid(formik, "ock_api_key")}
                  type='password'
                  showToggle
                  disabled
                />
              </div>
            </RenderGroup>

            <RenderGroup id='advanced' title='Configurações Avançadas'>
              <div className='mt-2 filter-item'>
                <Label className='form-label text-muted text-uppercase fw-semibold mb-3'>
                  Elementos visuais
                </Label>

                <Switch
                  item={{ field: "wallet_badge" }}
                  label='Badge Carteira'
                  description='Habilitar elemento'
                  className='justify-content-between'
                  onChange={(v) => formik.setFieldValue("wallet_badge", v)}
                  value={formik.values.wallet_badge}
                  disabled={isLoading}
                  size='sm'
                  dry
                />

                <Switch
                  item={{ field: "showcase_badge" }}
                  label='Badge Vitrine'
                  description='Habilitar elemento'
                  className='justify-content-between'
                  onChange={(v) => formik.setFieldValue("showcase_badge", v)}
                  value={formik.values.showcase_badge}
                  disabled={isLoading}
                  size='sm'
                  dry
                />

                <Switch
                  item={{ field: "product_detail_badge" }}
                  label='Banner Página de Produto'
                  description='Habilitar elemento'
                  className='justify-content-between'
                  onChange={(v) =>
                    formik.setFieldValue("product_detail_badge", v)
                  }
                  value={formik.values.product_detail_badge}
                  disabled={isLoading}
                  size='sm'
                  dry
                />
              </div>

              <div className='mt-2 filter-item'>
                <Label className='form-label text-muted text-uppercase fw-semibold mb-3'>
                  Integrações
                </Label>

                <Switch
                  item={{ field: "order_hook" }}
                  label='Habilitar Order Hook'
                  description='Permite receber vendas automaticamente da VTEX'
                  className='justify-content-between'
                  onChange={(v) => formik.setFieldValue("order_hook", v)}
                  invalid={() => generateIntegrationBadge("order_hook")}
                  value={formik.values.order_hook}
                  size='sm'
                  dry
                />

                <Switch
                  item={{ field: "giftcard_provider" }}
                  label='Habilitar Giftcard Opencashback'
                  description='Permite o resgate no checkout'
                  className='justify-content-between'
                  onChange={(v) => formik.setFieldValue("giftcard_provider", v)}
                  invalid={() => generateIntegrationBadge("giftcard_provider")}
                  value={formik.values.giftcard_provider}
                  disabled={isLoading}
                  size='sm'
                  dry
                />

                <Switch
                  item={{ field: "create_wallets" }}
                  label='Habilitar Criação de Carteiras'
                  description='Cria novas carteiras via integração'
                  className='justify-content-between'
                  onChange={(v) => formik.setFieldValue("create_wallets", v)}
                  value={formik.values.create_wallets}
                  disabled={isLoading}
                  size='sm'
                  dry
                />

                <Switch
                  item={{ field: "auto_import_catalog" }}
                  label='Importar Catálogo Automático'
                  description='Permite importar catálogo de maneira automática'
                  className='justify-content-between'
                  onChange={(v) =>
                    formik.setFieldValue("auto_import_catalog", v)
                  }
                  value={formik.values.auto_import_catalog}
                  disabled={isLoading}
                  size='sm'
                  dry
                />

                <Text
                  item={{
                    name: "Status pedido finalizado",
                    field: "order_status_done",
                  }}
                  description='Status do Workflow do Pedido VTEX que deve ser considerado para iniciar o tempo de liberação do cashback'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.order_status_done}
                  invalid={isInvalid(formik, "order_status_done")}
                  placeholder='Informe o status'
                  disabled={isLoading}
                  dry
                />
              </div>
            </RenderGroup>
          </div>
        </div>
      </Form>
    </>
  );
};

export default withTranslation()(IntegrationSettings);
