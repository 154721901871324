import React from 'react';
import { useCustomPagination } from '../Hooks/Pagination';
import classnames from 'classnames';
import { Button, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const CustomPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 2,
    currentPage,
    pageSize,
    disabled = false,
    t,
  } = props;

  const paginationRange = useCustomPagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const isLastPage = paginationRange[paginationRange.length - 1];
  const isLessThan2 = currentPage === 0 || paginationRange.length < 2;
  if (isLessThan2) return null;

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);

  return (
    <Row className="mt-4 pt-2">
      <Col xs={12} md={4} className="text-center text-md-start mb-2 mb-md-0">
        {t('showing')}
        <span className="fw-semibold ms-1">{pageSize}</span> {t('of')}{' '}
        <span className="fw-semibold">{totalCount}</span> {t('results')}
      </Col>

      <Col className="d-flex justify-content-center justify-content-md-end">
        <ul className="pagination pagination-separated pagination-md m-0">
          <li
            className={classnames('pagination-item', {
              disabled: currentPage === 1,
            })}
          >
            <Button
              className="page-link"
              disabled={currentPage === 1}
              onClick={onPrevious}
            >
              &lsaquo;
            </Button>
          </li>

          {paginationRange.map((pageNumber, index) => {
            const active = pageNumber === currentPage;

            return (
              <li className={'page-item mx-1'} key={`page-${pageNumber}`}>
                <Button
                  disabled={disabled}
                  className={classnames('page-link', { active })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </Button>
              </li>
            );
          })}

          <li
            key={`right-dots`}
            className={classnames('page-item', {
              disabled: currentPage === isLastPage,
            })}
          >
            <Button
              className="page-link"
              disabled={currentPage === isLastPage}
              onClick={onNext}
            >
              &rsaquo;
            </Button>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default withTranslation()(CustomPagination);
