import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { convertCents, formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const BillingByOrderTypeChart = ({ data, t }) => {
  const indicators = data?.billing?.data;

  return (
    <ReactApexChart
      type="line"
      height={350}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        title: {
          align: 'center',
          text: t('reports.billing.month.daily.title'),
        },

        subtitle: {
          align: 'center',
          text: t('reports.billing.month.daily.subtitle'),
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [2, 2, 2, 4] },

        labels: indicators?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: { formatter: (v) => formatCurrency(v, { convert: true }) },
        },
      }}
      series={[
        {
          name: t('reports.billing.month.daily.chart.total-impacted'),
          data: indicators?.map((i) => convertCents(i?.billing_total_impacted)),
        },

        {
          name: t('reports.billing.month.daily.chart.total-mixed'),
          data: indicators?.map((i) => convertCents(i?.billing_total_mixed)),
        },

        {
          name: t('reports.billing.month.daily.chart.total-incremental'),
          data: indicators?.map((i) =>
            convertCents(i?.billing_total_incremental)
          ),
        },

        {
          name: t('reports.billing.month.daily.chart.total-no-cashback'),
          data: indicators?.map((i) =>
            convertCents(i?.billing_total_without_cashback)
          ),
        },
      ]}
    />
  );
};

export default withTranslation()(BillingByOrderTypeChart);
