import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { isInvalid } from '../../../helpers/functions';
import { createIncentiveGroup } from '../../../slices/thunks';

const CreateIncentiveGroup = ({ isOpen, toggle, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      reason: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('validation-required-name')),
      reason: Yup.string().required(t('validation-required-reason')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const newGroup = {
        name: values.name,
        reason: values.reason,
      };

      const action = await dispatch(
        createIncentiveGroup({
          group: newGroup,
          successMessage: t('new-incentive-group-success'),
          errorMessage: t('new-incentive-group-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        validation.resetForm();
        onSuccess(action.payload);
        toggle();
      }
    },
  });

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('new-incentive-group')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row className="g-3">
              <Col lg={12}>
                <div>
                  <Label htmlFor="name-field" className="form-label">
                    {t('name')}
                  </Label>
                  <div className="input-group">
                    <Input
                      name="name"
                      id="name-field"
                      className="form-control"
                      placeholder={t('name-placeholder')}
                      type="text"
                      validate={{ required: { value: false } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ''}
                      invalid={isInvalid(validation, 'name') ? true : false}
                    />
                    {isInvalid(validation, 'name') && (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor="reason-field" className="form-label">
                    {t('reason')}
                  </Label>
                  <div className="input-group">
                    <Input
                      name="reason"
                      id="reason-field"
                      className="form-control"
                      placeholder={t('reason-placeholder')}
                      type="text"
                      validate={{ required: { value: false } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.reason || ''}
                      invalid={isInvalid(validation, 'reason') ? true : false}
                    />
                    {isInvalid(validation, 'reason') && (
                      <FormFeedback type="invalid">
                        {validation.errors.reason}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={toggle}>
                {t('close')}
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                <div className="d-flex">
                  {isLoading && <Spinner size="sm" className="me-3" />}
                  {t('save')}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CreateIncentiveGroup);
