import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import chartLocales from '../../utils/locales';

const SalesVolumeMonthTotalAccumulatedChart = ({
  data,
  t,
}): React.JSX.Element => {
  const indicators = data?.billing?.data;

  const lastMonthSeries = indicators.filter((v) => {
    const currentMonth = new Date().getMonth();
    const desiredIndex = currentMonth - 1 <= 0 ? 11 : currentMonth - 1;
    return new Date(v.created_at).getMonth() === desiredIndex;
  });

  const currentMonthSeries = indicators.filter((v) => {
    const currentMonth = new Date().getMonth();
    const desiredIndex = currentMonth === 0 ? 11 : currentMonth;
    return new Date(v.created_at).getMonth() === desiredIndex;
  });

  return (
    <ReactApexChart
      type="line"
      height={350}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        title: {
          align: 'center',
          text: t('reports.sales-volume.month.monthly.title'),
        },

        subtitle: {
          align: 'center',
          text: t('reports.sales-volume.month.monthly.subtitle'),
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [2, 2, 2, 4] },

        labels: [...lastMonthSeries, ...currentMonthSeries]?.map(
          (i) => i?.created_at
        ),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },
      }}
      series={[
        {
          name: t('reports.sales-volume.month.monthly.chart.last-month'),
          data: [
            ...lastMonthSeries?.map((i) => i?.sales_quantity_total ?? 0),
            ...currentMonthSeries?.map(() => null),
          ],
        },

        {
          name: t('reports.sales-volume.month.monthly.chart.current-month'),
          data: [
            ...lastMonthSeries?.map(() => null),
            ...currentMonthSeries?.map((i) => i?.sales_quantity_total ?? 0),
          ],
        },
      ]}
    />
  );
};

export default withTranslation()(SalesVolumeMonthTotalAccumulatedChart);
