import { Component } from 'react';
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/nano.min.css';
import classNames from 'classnames';

class ColorPicker extends Component {
  componentDidMount() {
    const picker = Pickr.create({
      el: '.ock-color-picker',
      theme: 'nano',
      closeOnScroll: true,
      autoReposition: true,
      comparison: false,

      default: this.props?.value || undefined,

      i18n: {
        'btn:cancel': 'Valor inicial',
        'btn:save': 'Confirmar',
      },

      components: {
        preview: true,
        hue: true,

        interaction: {
          input: true,
          cancel: true,
        },
      },
    });

    picker.on('hide', (res) => {
      const raw = res._color.toHEXA().toString();
      this.props.onChange(raw);
    });
  }

  render() {
    return (
      <div key="pickr" className="position-relative">
        {this.props.disabled && (
          <div
            className="skeleton position-absolute w-100 h-100 rounded-3 z-3"
            style={{ opacity: 0.8 }}
          />
        )}

        <div
          className={classNames(
            'd-flex justify-content-between gap-4 p-3 border shadow-sm rounded-3',
            this.props.invalid && 'border-danger'
          )}
        >
          {this.props.label && (
            <div>
              {this.props.label && (
                <h5 className="p-0 m-0">{this.props.label}</h5>
              )}

              {this.props.description && (
                <span
                  className="text-muted"
                  dangerouslySetInnerHTML={{ __html: this.props.description }}
                />
              )}
            </div>
          )}

          <div className="ock-color-picker" />
        </div>
      </div>
    );
  }
}

export default ColorPicker;
