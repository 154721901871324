import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getGeneralSettings as getGeneralSettingsApi,
  updateGeneralSettings as updateGeneralSettingsApi,
} from "../../helpers/bff_helper";

const namespace = "general-settings";
const toastOpts = { autoClose: 5000 };

export const getGeneralSettings = createAsyncThunk(
  `${namespace}/getSettings`,
  async () => {
    try {
      return await getGeneralSettingsApi();
    } catch (error) {
      return error;
    }
  }
);

export const updateGeneralSettings = createAsyncThunk(
  `${namespace}/updateSettings`,
  async ({ settings, successMessage, errorMessage }) => {
    try {
      const response = await updateGeneralSettingsApi(settings);
      toast.success(successMessage, toastOpts);
      return response;
    } catch (error) {
      toast.error(errorMessage, toastOpts);
      return { error: true, message: error };
    }
  }
);
