import { createSlice } from '@reduxjs/toolkit';
import { getCustomers, getFilters } from './thunk';

export const initialState = {
  isLoading: false,
  customers: [],
  meta: {},
  error: {},
  filters: [],
  isError: false,
};

const customerSlice = createSlice({
  name: 'Customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.filters = action.payload?.filters;
    });
  },
});

export default customerSlice.reducer;
