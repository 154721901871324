import { createSlice } from '@reduxjs/toolkit';
import { requestReportExport } from './thunk';

export const initialState = {
  isLoading: false,
  isError: false,
  error: {},
  meta: {},
};

const reportSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestReportExport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestReportExport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(requestReportExport.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export default reportSlice.reducer;
