import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { createBlockedProducts } from '../../../slices/strategies/thunk';

const BlockedStatusDisable = ({ isOpen, toggle, skuList, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: undefined
    },
    onSubmit: async (values) => {
      setIsLoading(true);

      const action = await dispatch(
        createBlockedProducts({
          product_ids: skuList,
          block_type: 'disabled',
          successMessage: t('catalog-edit-blocked-success'),
          errorMessage: t('catalog-edit-blocked-error'),
        })
      );      

      setIsLoading(false);

      if (!action.payload.error) {
        validation.resetForm();
        onSuccess();
        toggle();
      }
    },
  });

  return (
    <React.Fragment>
      <Modal id='showModal' isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className='bg-info-subtle p-3' toggle={toggle}>
          {t('bulk-edit-one-blocked-product')}
        </ModalHeader>

        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <h4 className='mb-4'>{t('bulk-disable-one-blocked-product-description')}</h4>
          </ModalBody>

          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <button type='button' className='btn btn-light' onClick={toggle}>
                {t('close')}
              </button>
              <button type='submit' disabled={!skuList?.length} className='btn btn-success' id='add-btn'>
                <div className='d-flex'>
                  {isLoading && <Spinner size='sm' className='me-3' />}
                  {t('confirm')}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(BlockedStatusDisable);
