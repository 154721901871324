import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

const Wizard = ({
  tabs,
  activeTab,
  onSelectTab = () => {},
  enableNavigation = false,
  showSteps = false,
}) => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  useEffect(() => {
    onSelectTab(active);
  }, [active]);

  const renderNavLinks = tabs?.map((tab, index) => {
    const tabIndex = ++index;

    return (
      <NavItem key={`tab-item-${tabIndex}`}>
        <NavLink
          className={classnames({ active: active === tabIndex })}
          {...(enableNavigation
            ? {
                onClick: () => setActive(tabIndex),
                style: { cursor: 'pointer' },
              }
            : {})}
        >
          {showSteps && (
            <Badge pill color="dark">
              {tabIndex}
            </Badge>
          )}
          <h6 className="mt-2">{tab.label}</h6>
        </NavLink>
      </NavItem>
    );
  });

  const renderTabs = tabs?.map((tab, index) => {
    const tabIndex = ++index;

    return (
      <TabPane key={`tab-pane-${tabIndex}`} tabId={tabIndex}>
        {tab.component}
      </TabPane>
    );
  });

  if (!tabs?.length) {
    return 'Nada aqui';
  }

  return (
    <>
      <Nav tabs className="nav nav-justified nav-tabs-wizard">
        {renderNavLinks}
      </Nav>

      <TabContent activeTab={active} className="text-muted my-4">
        {renderTabs}
      </TabContent>
    </>
  );
};

export default Wizard;
