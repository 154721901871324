import { Card as CardWrapper, CardHeader, CardBody } from "reactstrap";
import { formatCurrency } from "../../../helpers/format";
import { isNil } from "lodash";

const Card = ({ title, link, data }) => (
  <CardWrapper>
    <CardHeader>
      <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
        <h6 className="m-0 text-uppercase">{title}</h6>

        {link && (
          <a href={link} className="text-uppercase">
            <small>Detalhes</small>
            <i className="bx bx-link-external align-middle ms-2"></i>
          </a>
        )}
      </div>
    </CardHeader>

    <CardBody>
      {data.map((item, key) => {
        const value1 = item.label !== 'Número total' ? formatCurrency(item.value1, { maximumFractionDigits: 2 }) : item.value1;
        if (isNil(item?.value2)) {
          return (
            <div
              key={`${key}-${String(item.label)}`}
              className="d-flex justify-content-between align-items-center"
            >
              <span className="lh-sm w-100">{item.label}</span>
              <span className="text-end w-50">{value1}</span>
            </div>
          )
        }
        return (
          <div
            key={`${key}-${String(item.label)}`}
            className="d-flex justify-content-between align-items-center"
          >
            <span className="lh-sm w-100">{item.label}</span>
            <span className="text-end w-50">{value1}</span>
            <span className="text-end w-50">{`${Math.round(item.value2 * 100)}%`}</span>
          </div>
        )
      })}
    </CardBody>
  </CardWrapper>
);

export default Card;
