import classNames from "classnames";

const ActionButton = ({
  label,
  icon,
  onClick,
  disabled = false,
  type = "button",
  style = "success",
  small = false,
}) => {
  return (
    <button
      type={type}
      className={classNames({
        "btn right": true,
        "btn-label": icon,
        "btn-sm": small,
        [`btn-${style}`]: true,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label ?? "Clique aqui"}

      {icon && (
        <div className="label-icon ms-2">
          <i className={classNames("bx align-middle fs-16", icon)} />
        </div>
      )}
    </button>
  );
};

export default ActionButton;
