import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { convertCents, formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const MixedChart = ({ data, t }) => {
  const indicators = data?.billing?.data;

  return (
    <ReactApexChart
      height={400}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        title: {
          align: 'center',
          text: t('reports.earning-redeem.lifetime.mixed.title'),
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [1, 1, 1, 2] },
        colors: ['#1b73e8', '#aaff03', '#ff6400', '#ec616c'],
        labels: indicators?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: { formatter: (v) => formatCurrency(v, { convert: true }) },
        },
      }}
      series={[
        {
          type: 'line',
          name: t('reports.earning-redeem.lifetime.mixed.chart.total-earned'),
          data: indicators?.map((i) => convertCents(i?.cashback_total_earned)),
        },

        {
          type: 'line',
          name: t('reports.earning-redeem.lifetime.mixed.chart.total-used'),
          data: indicators?.map((i) =>
            convertCents(i?.cashback_total_used ?? 0)
          ),
        },

        {
          type: 'column',
          name: t('reports.earning-redeem.lifetime.mixed.chart.billing-total'),
          data: indicators?.map((i) => convertCents(i?.billing_total)),
        },
      ]}
    />
  );
};

export default withTranslation()(MixedChart);
