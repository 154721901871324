import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getHome as getHomeApi,
} from "../../helpers/bff_helper";

export const getHome = createAsyncThunk("results/getHome", async (params) => {
  try {
    const response = getHomeApi(params);

    return response;
  } catch (error) {
    return error;
  }
});