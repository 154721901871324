import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import qs from "qs";

const useQsParams = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const currentUri = searchParams.toString();
  const existingParams = qs.parse(currentUri);

  const cleanup = (v) => (v) =>
    _.isNil(v) || (_.isArray(v) && _.every(v, _.isNil)) || v === "";

  const setSearchParams = (field, value) => {
    const params = _.omitBy({ ...existingParams, [field]: value }, cleanup());
    _setSearchParams(params);
  };

  const setSearchParamsAsObject = (payload, overwrite = false) => {
    const values = !overwrite
      ? { ...existingParams, ...payload }
      : { ...payload };
    const params = _.omitBy(values, cleanup());

    _setSearchParams(params);
  };

  const resetSearchParams = (e = undefined) => {
    if (e) e.preventDefault();
    _setSearchParams({});
  };

  return {
    searchParams,
    setSearchParams,
    resetSearchParams,
    setSearchParamsAsObject,

    // aliases
    qs: searchParams,
    setQs: setSearchParams,
    resetQs: resetSearchParams,
  };
};

export default useQsParams;
