
import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NewStrategySelect = (props) => {
  document.title = "Nova estratégia | Opencashback";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Nova estratégia" pageTitle="Gestão" />
          <Row className='align-items-center h-100'>
            <Col lg={4} xxl={4}>
              <Card className="card-body">
                <div className="avatar-sm mb-3">
                  <div className="avatar-title bg-success-subtle text-success fs-17 rounded">
                    <i className="bx bx-category"></i>
                  </div>
                </div>
                <h4 className="card-title">{props.t('category')}</h4>
                <p className="card-text text-muted">{props.t('category-strategy-description')}</p>
                <Link to="/estrategias/novo/categoria" className="btn btn-success">{props.t('select')}</Link>
              </Card>
            </Col>
            <Col lg={4} xxl={4}>
              <Card className="card-body">
                <div className="avatar-sm mb-3">
                  <div className="avatar-title bg-success-subtle text-success fs-17 rounded">
                    <i className="bx bx-collection"></i>
                  </div>
                </div>
                <h4 className="card-title">{props.t('collection')}</h4>
                <p className="card-text text-muted">{props.t('collection-strategy-description')}</p>
                <Link to="/estrategias/novo/colecao" className="btn btn-success">{props.t('select')}</Link>
              </Card>
            </Col>
            <Col lg={4} xxl={4}>
              <Card className="card-body">
                <div className="avatar-sm mb-3">
                  <div className="avatar-title bg-success-subtle text-success fs-17 rounded">
                    <i className="bx bxl-product-hunt"></i>
                  </div>
                </div>
                <h4 className="card-title">{props.t('product')}</h4>
                <p className="card-text text-muted">{props.t('product-strategy-description')}</p>
                <Link to="/estrategias/novo/produto" className="btn btn-success">{props.t('select')}</Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(NewStrategySelect);