import classNames from "classnames";
import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

const SIZE = {
  sm: { width: 60, height: 25 },
  md: { width: 110, height: 40 },
  lg: { width: 200, height: 70 },
  xl: { width: 300, height: 100 },
};

const InputSwitch = ({ className = "", ...props }) => {
  const { label = undefined, description = undefined, size = "md" } = props;
  const { checked = false, onChange = () => {} } = props;
  const style = { ...SIZE[size], borderRadius: 100 };

  const [state, setState] = useState(checked);

  const isInvalid = props?.invalid && props?.invalid();

  const toggle = () => {
    const nextState = !state;
    onChange && onChange(nextState);
    setState(nextState);
  };

  return (
    <FormGroup
      switch
      className={classNames({
        "d-flex form-check-secondary gap-2 p-0": true,
        "fw-semibold text-primary": true,
        "switch-group-error": isInvalid,
        [className]: !!className,
      })}
      style={{
        alignItems: props?.vertical ? "start" : "center",
        flexDirection: props?.vertical ? "column" : "row",
      }}
    >
      {label && (
        <Label
          className='fs-6 d-flex flex-column switch-label'
          check
          onClick={toggle}
        >
          <span className={classNames({ "fw-semibold": !!description })}>
            {label}
          </span>

          {description ? (
            <span className='fs-9 text-muted me-4'>{description}</span>
          ) : null}

          {isInvalid ? <div className='mt-1'>{props?.invalid()}</div> : null}
        </Label>
      )}

      <div>
        <Input
          name={props.name}
          disabled={props.disabled}
          checked={checked}
          type='switch'
          onClick={toggle}
          style={style}
          className='m-0 switch-input'
        />
      </div>
    </FormGroup>
  );
};

export default InputSwitch;
