import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Section from "./Section";
import { withTranslation } from "react-i18next";
import Revenue from "./Revenue";
import Customer from "./Customer";
import Communication from "./Communication";
import WizardSetup from "./WizardSetup";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "../../slices/thunks";
import { createSelector } from "reselect";


const OnePageReport = (props) => {
  document.title = "Dashboard | Opencashback";

  const dispatch = useDispatch()

  const [billing, setBilling] = useState({});
  const [cashback, setCashback] = useState({});
  const [customers, setCustomers] = useState({});
  const [communications, setCommunications] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const data = await dispatch(getHome());
    setIsLoading(false);

    if (!data.payload) {
      return;
    }

    setBilling(data.payload.orders);
    setCashback(data.payload.cashback);
    setCustomers(data.payload.customers);
    setCommunications(data.payload.communications);
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section isLoading={isLoading} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Customer customers={customers} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Revenue billing={billing} cashback={cashback} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Communication communications={communications} />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <WizardSetup />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(OnePageReport);
