import { withTranslation } from "react-i18next";
import { Label, Row } from "reactstrap";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import { formatCurrency, formatInputCurrency } from "../../../helpers/format";
import useQsParams from "../../Hooks/QueryString";
import CurrencyInput from "../CurrencyInput";

function RangeFilter({ item, orderFilters, t }) {
  const { qs, setSearchParams, setSearchParamsAsObject } = useQsParams();

  const prefix = item?.field ?? "";
  const nameFrom = `${prefix}from`;
  const nameTo = `${prefix}to`;

  const range = orderFilters?.length
    ? orderFilters?.find((f) => f?.field === item.field)?.values?.map(Number)
    : [0, 1000000];

  const raw = qs.get(item.field)?.split(",");
  const to = raw?.[1] ?? Number(range?.[1]) ?? 0;
  const from = raw?.[0] ?? 0;

  return (
    <div className='mb-4 filter-item'>
      <Label className='form-label text-muted text-uppercase fw-semibold mb-3 d-flex justify-content-between'>
        <span>{item.name}</span>

        {qs.get(item.field)?.length ? (
          <span
            className='fw-normal'
            onClick={() => setSearchParams(item.field, "")}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: 11,
            }}
          >
            {t("clear")}
          </span>
        ) : null}
      </Label>

      {range?.length ? (
        <div className='mb-4 px-1'>
          <Nouislider
            connect
            step={1}
            id={`range-${item.field}-slider`}
            range={{ min: 0, max: Number(range?.[1]) }}
            start={[from, to]}
            tooltips={[
              { to: (v) => formatCurrency(Math.round(v)) },
              { to: (v) => formatCurrency(Math.round(v)) },
            ]}
            onChange={([nFrom, nTo]) => {
              const rawUrl = new URLSearchParams(window.location.search);
              const existing = Object.fromEntries(rawUrl);

              const v1 = Math.round(Number(nFrom));
              const v2 = Math.round(Number(nTo));

              setSearchParamsAsObject({
                ...existing,
                [item.field]: `${v1},${v2}`,
                page: 1,
              });
            }}
          />
        </div>
      ) : null}

      <Row className='g-2 align-items-center'>
        <div className='input-group col-lg'>
          <span className='input-group-text'>{t("currency")}</span>
          <CurrencyInput
            name={nameFrom}
            placeholder='0,00'
            value={formatInputCurrency(from)}
            onChange={(value) => {
              value = String(value).replace(/\D/g, "");
              setSearchParamsAsObject({
                [item.field]: `${value},${to}`,
                page: 1,
              });
            }}
          />
        </div>

        <div className='col-lg-auto'>{t("to")}</div>

        <div className='input-group col-lg'>
          <span className='input-group-text'>{t("currency")}</span>
          <CurrencyInput
            name={nameTo}
            placeholder='0,00'
            value={formatInputCurrency(to)}
            onChange={(value) => {
              value = String(value).replace(/\D/g, "");
              setSearchParamsAsObject({
                [item.field]: `${from},${value}`,
                page: 1,
              });
            }}
          />
        </div>
      </Row>
    </div>
  );
}

export default withTranslation()(RangeFilter);
