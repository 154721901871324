import htmlParser from "prettier/plugins/html";
import prettier from "prettier/standalone";

export const formatHtml = async (raw) => {
  if (!raw) return "";
  return await prettier.format(raw, {
    parser: "html",
    plugins: [htmlParser],
    tabWidth: 2,
    printWidth: 600,
    singleQuote: true,
    htmlWhitespaceSensitivity: "ignore",
    singleAttributePerLine: true,
    bracketSpacing: false,
  });
};

export const downloadHtml = (html, name = "email.html") => {
  const blob = new Blob([html], { type: "text/html" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
