import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

import { AbilityContext } from "../../../../helpers/casl";
import { useBreakpoints } from "../../../../Components/Hooks/BreakpointHooks";

const OtherSettingsForm = ({ form, ...props }) => {
  const ability = useContext(AbilityContext);
  const { isLg } = useBreakpoints();

  return (
    <div>
      <h6 style={{ fontWeight: "bold" }}>Outras configurações</h6>

      <div className="mb-3">
        <Row>
          <Col xs={12}>
            <div
              className={`form-check form-switch d-flex mt-2 gap-1 align-items-center ${
                isLg ? "form-switch-lg" : "form-switch-sm"
              }`}
              dir="ltr"
            >
              <Input
                disabled={ability.cannot("update", "account_strategies")}
                className="form-check-input mt-0"
                type="checkbox"
                value={form.values.block_discounts}
                defaultChecked={form.values.block_discounts}
                checked={form.values.block_discounts}
                name="block_discounts"
                id="accumulation-block-discounts"
                onChange={form.handleChange}
              />
              <Label
                className="form-label w-100 form-check-label lh-sm"
                for="accumulation-block-discounts"
              >
                {props.t("accumulation-block-discounts")}
              </Label>
            </div>
          </Col>

          <Col xs={12}>
            <div
              className={`form-check form-switch d-flex mt-2 gap-1 align-items-center ${
                isLg ? "form-switch-lg" : "form-switch-sm"
              }`}
              dir="ltr"
            >
              <Input
                disabled={ability.cannot("update", "account_strategies")}
                className="form-check-input mt-0"
                type="checkbox"
                value={form.values.block_vtex_any_coupon}
                defaultChecked={form.values.block_vtex_any_coupon}
                checked={form.values.block_vtex_any_coupon}
                name="block_vtex_any_coupon"
                id="accumulation-block-vtex-any-coupon"
                onChange={form.handleChange}
              />
              <Label
                className="form-label w-100 form-check-label lh-sm"
                for="accumulation-block-vtex-any-coupon"
              >
                {props.t("accumulation-block-vtex-any-coupon")}
              </Label>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(OtherSettingsForm);
