import { createSlice } from '@reduxjs/toolkit';
import {
  deleteStrategyProducts,
  getStrategyProducts,
  getAccountStrategy,
  getStrategies,
} from './thunk';

export const initialState = {
  isLoading: false,
  account: {
    accumulation: {},
    rescue: {},
  },
  strategies: [],
  products: [],
  meta: {},
  error: {},
  isError: false,
};

const strategiesSlice = createSlice({
  name: 'Strategies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountStrategy.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccountStrategy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.account = action.payload;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getAccountStrategy.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStrategies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStrategies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.strategies = action.payload?.data;
      state.products = []; // reset/empty slice
      state.meta = action.payload?.meta;
    });
    builder.addCase(getStrategies.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStrategyProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStrategyProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.meta = action.payload?.meta;
      state.products = action?.payload?.data?.map((item) => {
        item.id = item.external_id;
        return item;
      });
    });
    builder.addCase(getStrategyProducts.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export default strategiesSlice.reducer;
