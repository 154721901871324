import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { convertCents, formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const AvgTicketChart = ({ data, t }): React.JSX.Element => {
  const indicators = data?.billing?.data;

  return (
    <ReactApexChart
      type="bar"
      height={550}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },
        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        title: {
          align: 'center',
          text: t('reports.average-ticket.chart.title'),
        },

        subtitle: {
          align: 'center',
          text: t('reports.average-ticket.chart.subtitle'),
        },

        markers: { size: 5 },
        stroke: { width: [1, 1, 1, 2] },
        colors: ['#1b73e8', '#aaff03', '#ff6400', '#ec616c'],
        labels: indicators?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: { formatter: (v) => formatCurrency(v, { convert: true }) },
        },
      }}
      series={[
        {
          name: t('reports.average-ticket.chart.avg-ticket'),
          data: indicators?.map((i) => convertCents(i?.average_ticket)),
        },

        {
          name: t('reports.average-ticket.chart.total-impacted'),
          data: indicators?.map((i) => convertCents(i?.average_ticket_impacted)),
        },

        {
          name: t('reports.average-ticket.chart.total-mixed'),
          data: indicators?.map((i) => convertCents(i?.average_ticket_mixed)),
        },

        {
          name: t('reports.average-ticket.chart.total-incremental'),
          data: indicators?.map((i) =>
            convertCents(i?.average_ticket_incremental)
          ),
        },
      ]}
    />
  );
};

export default withTranslation()(AvgTicketChart);
