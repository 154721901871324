import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Container, Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { getSmartIncentives } from '../../../slices/incentives/thunk';
import useQsParams from '../../../Components/Hooks/QueryString';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ExportReport from '../../../Components/Common/ExportReport';
import Collapsive from '../../../Components/Common/Collapsive';
import SmartIncentiveModal from './components/Modal';
import SmartIncentiveTable, {
  smartIncentivesColumns,
} from './components/SmartIncentiveTable';

const SmartIncentives = (props) => {
  const dispatch = useDispatch();

  const { qs, setSearchParamsAsObject } = useQsParams();

  document.title = `${props.t('smart-incentives.menu')} | Opencashback`;

  const data = useSelector(
    createSelector(
      (store) => store.Incentive,
      (slice) => {
        const { wallets_reactivation, special_dates, lead_capture } = slice?.smart;
        let opened = [];

        const hasWalletsReactivationActive = wallets_reactivation?.length
          ? wallets_reactivation.some((i) => i.active)
          : false;

        const hasSpecialDatesActive = special_dates?.length
          ? special_dates.some((i) => i.active)
          : false;

          const hasLeadCaptureActive = lead_capture?.length
          ? lead_capture.some((i) => i.active)
          : false;

        if (!qs.getAll('opened')?.length && !qs.get('l')) {
          if (hasWalletsReactivationActive) opened.push('wallets_reactivation');
          if (hasSpecialDatesActive) opened.push('special_dates');
          if (hasLeadCaptureActive) opened.push('lead_capture');

          setSearchParamsAsObject({ opened, l: true });
        }

        return {
          isLoading: slice.isLoading,
          incentives: slice.smart,
          meta: slice.meta,
          opened: [],
          hasActive: {
            wallets_reactivation: hasWalletsReactivationActive,
            special_dates: hasSpecialDatesActive,
            lead_capture: hasLeadCaptureActive,
          },
        };
      }
    )
  );

  useEffect(() => {
    dispatch(getSmartIncentives());
  }, []);

  const exportColumns = [...smartIncentivesColumns];
  exportColumns.push({
    Header: props.t('smart-incentives.table.status'),
    accessor: 'active',
    reportProps: {
      formatter: {
        type: 'boolean',
        properties: {
          option_true: props.t('smart-incentives.active'),
          option_false: props.t('smart-incentives.inactive'),
        },
      },
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t('smart-incentives.menu')}
          description={props.t('smart-incentives.description')}
          pageTitle={props.t('incentives')}
        />

        <Card className="mb-4" style={{ borderRadius: 10 }}>
          <CardBody>
            <div className="d-flex flex-column flex-md-row gap-3 justify-content-between align-items-md-center">
              <div>
                <h4 className="mb-1">
                  {props.t('smart-incentives.header.title')}
                </h4>
                <span className="text-muted">
                  {props.t('smart-incentives.header.description')}
                </span>
              </div>

              <div>
                <ExportReport
                  pages={1}
                  disabled={data.isLoading}
                  filename={props.t('smart-incentives.report')}
                  service="smart-incentives"
                  columns={[
                    ...exportColumns,
                    {
                      accessor: 'type',
                      Header: 'Grupo',
                      reportProps: {
                        formatter: {
                          type: 'enum',
                          properties: {
                            options: [
                              {
                                key: 'special_dates',
                                value: props.t(
                                  'smart-incentives.collapsible.special-dates'
                                ),
                              },
                              {
                                key: 'wallets_reactivation',
                                value: props.t(
                                  'smart-incentives.collapsible.wallets-reactivation'
                                ),
                              },
                              {
                                key: 'lead_capture',
                                value: props.t(
                                  'smart-incentives.collapsible.lead-capture'
                                ),
                              },
                            ],
                          },
                        },
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Collapsive
          title={props.t('smart-incentives.collapsible.wallets-reactivation')}
          description={props.t(
            'smart-incentives.collapsible.wallets-reactivation-description'
          )}
          id="wallets_reactivation"
          loading={data.isLoading}
        >
          <SmartIncentiveTable data={data} type="wallets_reactivation" />
        </Collapsive>

        <Collapsive
          title={props.t('smart-incentives.collapsible.special-dates')}
          description={props.t(
            'smart-incentives.collapsible.special-dates-description'
          )}
          id="special_dates"
          loading={data.isLoading}
        >
          <SmartIncentiveTable data={data} type="special_dates" />
        </Collapsive>

        <Collapsive
          title={props.t('smart-incentives.collapsible.lead-capture')}
          description={props.t(
            'smart-incentives.collapsible.lead-capture-description'
          )}
          id="lead_capture"
          loading={data.isLoading}
        >
          <SmartIncentiveTable data={data} type="lead_capture" />
        </Collapsive>
      </Container>

      <SmartIncentiveModal />
      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </div>
  );
};

export default withTranslation()(SmartIncentives);
