import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { currencyFieldToNumber, isInvalid } from '../../helpers/functions';
import CurrencyInput from '../../Components/Common/CurrencyInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ReactInputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import { useProfile } from '../../Components/Hooks/UserHooks';
import { createOrder, sendToken, updateCustomer } from '../../slices/thunks';
import TwoFactor from './TwoFactor';
import { toNumber, isEmpty } from 'lodash';
import { formatDate } from '../../helpers/format';

const SecondTab = ({
  t,
  onSubmit,
  customer,
  wallet,
  reset,
  order,
  setRescueBalance,
  rescueBalance,
  simulateOrderIsLoading,
}) => {
  const { userProfile } = useProfile();
  const dispatch = useDispatch();

  const [useBalance, setUseBalance] = useState(true);
  const [shouldSendToken, setShouldSendToken] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);
  const [disabledFinishOrder, setDisabledFinishOrder] = useState(false);

  useEffect(() => {
    if (reset) {
      validation.resetForm();
      setIsLoading(false);
      setTwoFactorModal(false);
      setUseBalance(true);
      setShouldSendToken(true);
      setDisabledFinishOrder(false);
    }

    if (
      order?.cashback?.maximum_rescue &&
      order?.cashback?.maximum_rescue > 0
    ) {
      setUseBalance(true);
    } else {
      setUseBalance(false);
    }
  }, [reset, order?.cashback?.maximum_rescue]);

  const onCloseTwoFactor = () => {
    setIsLoading(false);
  };

  const submit = async (values) => {
    setIsLoading(true);
    const rescue_balance = currencyFieldToNumber(values.rescue_balance);
    const customerDocument = order?.document;

    if (rescue_balance > values.rescue_limit) {
      validation.setFieldError(
        'rescue_balance',
        t('validation-max-rescue-balance')
      );
      return;
    }

    const external_id = `${moment().unix()}-${customerDocument}`;

    const payload = {
      document: customerDocument,
      external_id,
      seller: userProfile.name,
      store: userProfile?.stores?.[0]?.name ?? '',
      store_id: userProfile?.store_id,
      status: 'done',
      total: order?.total,
      total_paid: useBalance ? order?.total - rescue_balance : order?.total,
      used_cashback: useBalance ? rescue_balance : 0,
      master_token_used: values.master_token_used,
    };

    const result = await dispatch(
      createOrder({
        order: payload,
        errorMessage: t('new-order-error'),
      })
    );

    if (result?.payload?.error) {
      setIsLoading(false);
      setDisabledFinishOrder(false);
      return;
    }

    setIsLoading(false);
    validation.resetForm();
    return onSubmit(result.payload);
  };

  const toggleTwoFactorModal = () => {
    setTwoFactorModal(!twoFactorModal);
  };

  const twoFactorSuccess = async (payload) => {
    const { is_master_token } = payload;
    setTwoFactorModal(false);
    setDisabledFinishOrder(true);
    await submit({ ...validation.values, master_token_used: is_master_token });
  };

  const handleRescueBalanceChange = (e) => {
    const value = Math.min(
      currencyFieldToNumber(e),
      toNumber(validation.values.rescue_limit)
    );
    validation.setFieldValue('rescue_balance', value);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cellphone: (wallet && wallet.cellphone) || '',
      email: (wallet && wallet.email) || '',
      rescue: true,
      rescue_balance:
        order?.cashback?.maximum_rescue && rescueBalance > 0
          ? Math.min(rescueBalance, order?.cashback?.maximum_rescue)
          : order?.cashback?.maximum_rescue || '0',
      rescue_limit: order?.cashback?.maximum_rescue || '0',
    },
    validationSchema: Yup.object({
      cellphone: Yup.string().required(t('validation-required-cellphone')),
      email: Yup.string().email(t('validation-valid-email')),
      rescue: Yup.boolean().required(t('validation-required-use-rescue')),
      rescue_balance: Yup.string().when('rescue', {
        is: true,
        then: () =>
          Yup.string().required(t('validation-required-rescue-balance')),
      }),
    }),
    onSubmit: async (values) => {
      setDisabledFinishOrder(true)
      setIsLoading(true);

      const rescue_balance = currencyFieldToNumber(values.rescue_balance);

      if (rescue_balance > values.rescue_limit) {
        validation.setFieldError(
          'rescue_balance',
          t('validation-max-rescue-balance')
        );
        setIsLoading(false);
        return;
      }

      const customerDocument = order?.document;

      if (
        values.cellphone !== wallet.cellphone ||
        values.email !== wallet.email
      ) {
        const customer_payload = {
          email: !isEmpty(values.email) ? values.email : undefined,
          cellphone: values.cellphone,
        };

        await dispatch(
          updateCustomer({
            document: customerDocument,
            customer: customer_payload,
          })
        );
      }

      if (
        !useBalance ||
        (useBalance && currencyFieldToNumber(values.rescue_balance) === 0)
      ) {
        return await submit(values);
      }

      toggleTwoFactorModal();
      if (shouldSendToken) {
        const responseToken = await dispatch(
          sendToken({
            document: customerDocument,
            params: {
              channel: 'sms',
              identifier: 'order-ock-light',
            },
          })
        );

        if (!responseToken?.payload?.error) {
          setShouldSendToken(false);
        }
      }

      setDisabledFinishOrder(false)
    },
  });

  return (
    <React.Fragment>
      <Form
        className='tablelist-form'
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row className='justify-content-center py-5'>
          <Col xs={12} lg={5}>
            <Row>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-document-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('document')}
                  </Label>
                  <Input
                    mask={
                      customer &&
                      customer.document &&
                      customer.document.length < 15
                        ? '999.999.999-999'
                        : '99.999.999/9999-99'
                    }
                    maskChar=''
                    name='customer-document'
                    id='customer-document-field'
                    className='form-control'
                    placeholder={t('document-placeholder')}
                    type='text'
                    disabled
                    value={(customer && customer.document) || ''}
                    tag={ReactInputMask}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='name-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('name')}
                  </Label>
                  <Input
                    name='document'
                    id='name-field'
                    className='form-control'
                    placeholder={t('name-placeholder')}
                    type='text'
                    disabled
                    value={(customer && customer.name) || ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-cellphone-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('cellphone')}
                  </Label>
                  <Input
                    mask='(99) 9 9999-9999'
                    name='cellphone'
                    id='customer-cellphone-field'
                    className='form-control'
                    placeholder={t('cellphone-placeholder')}
                    type='text'
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cellphone || ''}
                    invalid={isInvalid(validation, 'cellphone')}
                    tag={ReactInputMask}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-birthdate-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('birthdate')}
                  </Label>
                  <Input
                    mask='99/99/9999'
                    name='birthdate'
                    id='customer-birthdate-field'
                    className='form-control'
                    placeholder={t('birthdate-placeholder')}
                    type='text'
                    disabled
                    value={ (customer && customer.birthdate && formatDate(customer.birthdate)) || '' }
                    tag={ReactInputMask}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-email-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('email')}
                  </Label>
                  <Input
                    name='email'
                    id='customer-email-field'
                    className='form-control'
                    placeholder={t('email-placeholder')}
                    type='text'
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ''}
                    invalid={isInvalid(validation, 'email') ? true : false}
                  />
                  {isInvalid(validation, 'email') && (
                    <FormFeedback type='invalid'>
                      {validation.errors.email}
                    </FormFeedback>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
          <Col xs={12} lg={5}>
            <Row>
              <Col lg={12}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-balance-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('balance-in-wallet')}
                  </Label>
                  <div className='input-group'>
                    <span className='input-group-text'>{t('currency')}</span>
                    <CurrencyInput
                      name='balance'
                      id='customer-balance-field'
                      placeholder='0,00'
                      type='text'
                      value={wallet && wallet.balance}
                      disabled={true}
                      className='form-control'
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='customer-document-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('rescue-balance')} ?
                  </Label>
                  <div
                    className='form-check form-switch form-switch-lg'
                    dir='ltr'
                  >
                    <Input
                      type='checkbox'
                      name='rescue'
                      className='form-check-input'
                      id='customSwitchsizelg'
                      checked={useBalance}
                      disabled={
                        simulateOrderIsLoading &&
                        validation.values.rescue_limit <= 0
                      }
                      onChange={(e) => {
                        const useBalanceStatus = !useBalance;

                        if (useBalanceStatus === true) {
                          const newRescueBalance = currencyFieldToNumber(
                            validation.values.rescue_balance
                          );

                          setRescueBalance(newRescueBalance);
                        } else {
                          setRescueBalance(0);
                        }

                        setUseBalance(useBalanceStatus);
                      }}
                    />
                    <Label
                      className='form-check-label'
                      for='customSwitchsizelg'
                    ></Label>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className='mb-3'>
                  <Label
                    htmlFor='rescue-balance-field'
                    className='form-label text-muted text-uppercase fw-semibold'
                  >
                    {t('amount-rescue-balance')}
                  </Label>
                  <div className='input-group'>
                    <span className='input-group-text'>{t('currency')}</span>
                    <CurrencyInput
                      name='rescue_balance'
                      id='rescue-balance-field'
                      placeholder='0,00'
                      onChange={handleRescueBalanceChange}
                      onBlur={(e) => {
                        const value = e.currentTarget.value;
                        const newRescueBalance = currencyFieldToNumber(value);

                        setRescueBalance(newRescueBalance);

                        validation.handleBlur(e);
                      }}
                      value={
                        useBalance ? validation.values.rescue_balance : '0,00'
                      }
                      disabled={simulateOrderIsLoading && !useBalance}
                      invalid={isInvalid(validation, 'rescue_balance')}
                    />
                    {isInvalid(validation, 'rescue_balance') && (
                      <FormFeedback type='invalid'>
                        {' '}
                        {validation.errors.rescue_balance}{' '}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-grid gap-2 mt-3'>
                  <Button
                    type='submit'
                    // onClick={(e) => {
                    //   e.preventDefault()
                    //   // toggleTwoFactorModal()
                    // }}
                    className='btn btn-tertiary text-white'
                    disabled={disabledFinishOrder}
                  >
                    {t('order-step')}
                    {isLoading && <Spinner size='sm' className='ms-2' />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <TwoFactor
        isOpen={twoFactorModal}
        toggle={toggleTwoFactorModal}
        document={order?.document}
        onClose={onCloseTwoFactor}
        onSuccess={twoFactorSuccess}
        reset={reset}
      />
    </React.Fragment>
  );
};

export default withTranslation()(SecondTab);
