import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { createSelector } from "reselect";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getIntegrations, getIntegrationStatus } from "../../slices/thunks";
import { formatDate } from "../../helpers/format";
import { Can } from "../../helpers/casl";

import useQsParams from "../../Components/Hooks/QueryString";
import TableContainer from "../../Components/Common/TableContainer";
import Breadcrumb from "../../Components/Common/BreadCrumb";
import Drawer from "../../Components/Common/Drawer";
import IntegrationSettings from "./settings";
import getStatusBadge from "./statuses";

const Integrations = ({ t }) => {
  document.title = `${t("integrations.menu")} | Opencashback`;

  const dispatch = useDispatch();
  const { searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const { isLoading, data, status } = useSelector(
    createSelector(
      (store) => store.Integrations,
      ({ data, ...slice }) => ({
        ...slice,
        // temp: mock payload as array
        data: Object.keys(data)?.length ? [{ id: 1, ...data }] : [],
      })
    )
  );

  useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getIntegrationStatus());
  }, [searchParams.toString()]);

  const hasIntegrationError = Object.keys(status)
    ?.map((key) => {
      return !["004", "007", "009"].includes(status?.[key]?.status_id);
    })
    .some(Boolean);

  const columns = [
    {
      Header: t("integrations.vtex.table.name"),
      accessor: "integration",
      Cell: ({ value }) => value,
    },
    {
      Header: t("integrations.vtex.table.created_at"),
      accessor: "created_at",
      Cell: ({ value }) => (value ? formatDate(value) : "-"),
    },
    {
      Header: t("integrations.vtex.table.updated_at"),
      accessor: "updated_at",
      Cell: ({ value }) => (value ? formatDate(value) : "-"),
    },
    {
      Header: t("integrations.vtex.table.status"),
      accessor: "enabled",
      Cell: ({ value }) => {
        if (hasIntegrationError) {
          return (
            <span className='badge rounded-pill bg-warning-subtle text-warning'>
              {t("integrations.vtex.table.status-error")}
            </span>
          );
        }

        const statusId = value ? "004" : "0";
        const statusLabel = value ? "enabled" : "disabled";
        const { classes } = getStatusBadge(statusId, t);

        return (
          <span className={`badge rounded-pill ${classes}`}>
            {t(`integrations.vtex.table.status-${statusLabel}`)}
          </span>
        );
      },
    },

    {
      Header: t("actions"),
      Cell: () => {
        return (
          <ul className='list-inline hstack gap-2 mb-0'>
            <Can I='update' a='integrations'>
              <li className='list-inline-item'>
                <button
                  type='button'
                  onClick={() =>
                    setSearchParamsAsObject({
                      ...(hasIntegrationError ? { opened: "advanced" } : {}),
                      settings: "true",
                    })
                  }
                  className='bg-transparent p-0 border-0'
                >
                  <i className='ri-pencil-fill align-bottom text-muted' />
                </button>
              </li>
            </Can>
          </ul>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title={t("integrations.menu")}
            pageTitle={t("configurations")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className='pt-0'>
                  <Can I='read' a='integrations'>
                    <div className='mt-n2 mb-n3'>
                      <TableContainer
                        isLoading={isLoading}
                        columns={columns}
                        data={data || []}
                        activePage={searchParams.get("page")}
                        setPage={(p) => setSearchParams("page", p)}
                        pageSize={10}
                        totalPages={0}
                        count={0}
                        // pageSize={meta?.take || 10}
                        // totalPages={meta?.pages || 0}
                        // count={meta?.total || 0}
                        className='custom-header-css'
                        divClass='table-responsive table-card mb-0'
                        tableClass='align-middle table-nowrap'
                        theadClass='table-light'
                      />
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer position='top-center' closeButton={false} limit={1} />

      <Drawer slug='settings' className='w-500'>
        <IntegrationSettings />
      </Drawer>
    </React.Fragment>
  );
};

export default withTranslation()(Integrations);
