import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import chartLocales from '../../utils/locales';

const SalesVolumeLifetimeChart = ({ data, t }): React.JSX.Element => {
  const indicators = data?.billing?.data;

  return (
    <ReactApexChart
      type="bar"
      height={530}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },
        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [1, 1, 1, 2] },
        colors: ['#1b73e8', '#aaff03', '#ff6400', '#ec616c'],
        labels: indicators?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
            rotateAlways: true,
            rotate: -45,
          },
        },
      }}
      series={[
        {
          name: t('reports.sales-volume.lifetime.chart.no-cashback'),
          data: indicators?.map((i) => i?.sales_quantity_without_cashback),
        },

        {
          name: t('reports.sales-volume.lifetime.chart.qty-impacted'),
          data: indicators?.map((i) => i?.sales_quantity_impacted),
        },

        {
          name: t('reports.sales-volume.lifetime.chart.qty-incremental'),
          data: indicators?.map((i) => i?.sales_quantity_incremental),
        },
      ]}
    />
  );
};

export default withTranslation()(SalesVolumeLifetimeChart);
