import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import StatusDropdown from './StatusDropdown';
import { translations } from '../../statuses';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { formatCurrency, formatDate } from '../../../../helpers/format';
import { Can } from '../../../../helpers/casl';

const CashbackInfo = (props) => {
  const { order, isLoading } = useSelector(
    createSelector(
      (store) => store.Order,
      (slice) => ({
        isLoading: slice.isLoading,
        order: slice.order,
      })
    )
  );

  return (
    <>
      <Row>
        <Col sm={12} lg={4}>
          {isLoading ? (
            <div className="skeleton card" style={{ height: '117px' }} />
          ) : (
            <Card className="text-center">
              <CardHeader className="bg-black text-white text-uppercase">
                {props.t('order-status-detail')}
              </CardHeader>
              <CardBody>
                <div className="d-flex justify-content-center gap-2">
                  <h4 className="fw-bold h-auto text-success">
                    {translations[order?.cashback_status]?.label}
                  </h4>

                  <Can I='update' a='sales_management'>
                    <StatusDropdown icon="bx-edit-alt" order={order} />
                  </Can>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>

        <Col sm={12} lg={4}>
          {isLoading ? (
            <div className="skeleton card" style={{ height: '117px' }} />
          ) : (
            <Card className="text-center">
              <CardHeader className="bg-dark text-white text-uppercase">
                {props.t('cashback-generated')}
              </CardHeader>
              <CardBody>
                <h4 className="fw-bold">
                  {formatCurrency(order?.earned_cashback)}
                </h4>
              </CardBody>
            </Card>
          )}
        </Col>

        <Col sm={12} lg={4}>
          {isLoading ? (
            <div className="skeleton card" style={{ height: '117px' }} />
          ) : (
            <Card className="text-center">
              <CardHeader className="bg-dark text-white text-uppercase">
                {props.t('order-used-cashback2-detail')}
              </CardHeader>
              <CardBody>
                <h4 className="fw-bold">
                  {formatCurrency(order?.used_cashback)}
                </h4>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={12} lg={6}>
          {isLoading ? (
            <div className="skeleton card" style={{ height: '57px' }} />
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex flex-column flex-sm-row justify-content-between">
                  <span className="fs-5 fw-semibold text-dark">
                    {props.t('ticket')}
                  </span>
                  <span className="fs-5 text-muted">{order?.external_id}</span>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>

        <Col sm={12} lg={6}>
          {isLoading ? (
            <div className="skeleton card" style={{ height: '57px' }} />
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex flex-column flex-sm-row justify-content-between">
                  <span className="fs-5 fw-semibold text-dark">
                    {props.t('date')}
                  </span>
                  <span className="fs-5 text-muted">
                    {formatDate(order?.created_at, 'DD/MM/Y')}
                  </span>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(CashbackInfo);
