//Include Both Helper File with needed methods
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from './reducer';
import { login } from '../../../helpers/bff_helper';
import { toast } from 'react-toastify';
import { createAbility } from '../../../helpers/casl';

export const loginUser = (user) => async (dispatch) => {
  const errOpts = {
    className: 'bg-danger text-white',
    position: 'top-center',
  };

  try {
    const data = await login(user.email, user.password);

    if (data) {
      sessionStorage.setItem('authUser', JSON.stringify(data));
      dispatch(loginSuccess(data));

      return data?.permissions?.reduce(
        (acc, { subject }) => (acc = [...acc, subject]),
        []
      );
    }

    toast.error('Ops! Houve um erro. Tente novamente em instantes', errOpts);
  } catch (error) {
    dispatch(apiError(error));
    toast.error(error, errOpts);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem('authUser');
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
