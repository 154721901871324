import { useEffect, useRef, useState } from "react";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";
import { EditorState } from "@codemirror/state";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import Select from "react-select";

import { handlebarsLanguage } from "@xiechao/codemirror-lang-handlebars";
import { formatHtml } from "../../helpers/html_helper";

const Editor2 = ({
  id,
  label,
  value,
  placeholder,
  invalid,
  error,
  onChange,
  config = {},
}) => {
  const [formatted, setFormatted] = useState(!config.formatOnPaste);
  const [counter, setCounter] = useState(0);
  const ref = useRef();

  // enable syntax parsing
  const extensions = [handlebarsLanguage];

  // enable line wrapping
  if (config?.lineWrap) extensions.push(EditorView.lineWrapping);

  // disable editing
  if (config?.disabled)
    extensions.push(
      EditorState.readOnly.of('nocursor'),
      EditorView.theme({
        '.cm-content': { background: '#f2f2f2', color: '#807f7f' },
      })
    );

  // enable character limit
  if (config?.maxCharacters && config?.maxCharacters > 0) {
    extensions.push(
      EditorState.changeFilter.of((tr) =>
        config?.maxCharacters && config?.type !== "sms"
          ? tr.newDoc.length <= config?.maxCharacters
          : tr,
      ),
    );
  }

  function refCallback(editor) {
    if (!ref.current && editor?.editor && editor?.state && editor?.view) {
      ref.current = editor;
    }
  }

  const variables = useSelector(
    createSelector(
      (state) => state.Communication,
      ({ variables }) => variables,
    ),
  );

  useEffect(() => {
    const hasRef = ref?.current?.view?.state;
    setCounter(hasRef?.doc?.length ?? 0);
  }, [ref, value]);

  return (
    <div>
      {label ? (
        <Label className="form-label fs-5 fw-semibold text-muted">
          {label}
        </Label>
      ) : null}

      {error ? (
        <Label className="form-label fs-6 d-block text-danger">{error}</Label>
      ) : null}

      <div
        style={{
          borderLeft: `1px solid ${invalid ? "tomato" : "#e9ebec"}`,
          borderRight: `1px solid ${invalid ? "tomato" : "#e9ebec"}`,
          borderTop: `1px solid ${invalid ? "tomato" : "#e9ebec"}`,
          background: config?.disabled ? "#f2f2f2" : "white",
          borderRadius: "5px 5px 0 0",
          width: "100%",
        }}
      >
        <div className="toolbar-container">
          <Select
            value=""
            isSearchable
            placeholder="Inserir variável"
            classNames={{
              control: () => "border-0 border-end rounded-0 rounded-start-2",
            }}
            isDisabled={config?.disabled}
            onChange={(v) => {
              const state = ref?.current?.view?.state;
              const cursor = state?.selection?.ranges[0]?.from;
              const changes = { from: cursor, insert: v?.value };
              ref.current.view.dispatch({ changes });
            }}
            options={[
              ...variables?.map(({ label, value }) => ({ label, value })),
            ]}
          />
        </div>
      </div>

      <CodeMirror
        id={id ?? "editor"}
        ref={refCallback}
        value={value}
        placeholder={placeholder ?? ''}
        height={`${config?.height ?? 30}px`}
        extensions={extensions}
        style={{
          borderRadius: "0 0 5px 5px",
          padding: config?.maxCharacters ? 8 : 0,
          background: config?.disabled ? "#f2f2f2" : "white",
          border: `1px solid ${invalid ? "tomato" : "#e9ebec"}`,
        }}
        basicSetup={{
          lineNumbers: true,
          foldGutter: false,
          dropCursor: false,
          allowMultipleSelections: false,
          indentOnInput: true,
          searchKeymap: false,
          ...config,
        }}
        onChange={async (raw) => {
          let html = raw;

          if (!formatted) {
            setFormatted(true);

            try {
              html = await formatHtml(raw);
            } catch (e) {
              html = raw;
            }
          }

          onChange(html);
        }}
      />

      {!config.disabled && config.maxCharacters && config?.maxCharacters > 0 ? (
        <div className="text-end">
          {counter}/{config?.maxCharacters}
        </div>
      ) : null}
    </div>
  );
};

export default Editor2;
