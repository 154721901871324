import { Card, CardBody, Collapse } from 'reactstrap';
import classNames from 'classnames';

import useQsParams from '../Hooks/QueryString';

const Collapsive = ({ title, description, id, loading, children }) => {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const isSelected = qs.getAll('opened').includes(id);

  return (
    <>
      {loading ? (
        <div
          style={{ width: '100%', minHeight: 75 }}
          className="card mb-3 rounded-3 skeleton"
        />
      ) : (
        <>
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSearchParamsAsObject({
                opened: isSelected
                  ? qs.getAll('opened').filter((v) => v !== id)
                  : String(id),
              });
            }}
            className="mb-3"
          >
            <CardBody>
              <div className="d-flex gap-3">
                <div>
                  <i
                    className={classNames({
                      'bx bx-chevron-down fs-1 transition': true,
                      rotate180deg: isSelected,
                    })}
                  />
                </div>

                <div className='d-flex flex-column justify-content-center'>
                  <h5 className="mb-1">{title}</h5>
                  {description ? <span className="text-muted">{description}</span> : null}
                </div>
              </div>
            </CardBody>
          </Card>

          <Collapse isOpen={isSelected}>
            <div className="mx-md-3 mb-5">{children}</div>
          </Collapse>
        </>
      )}
    </>
  );
};

export default Collapsive;
