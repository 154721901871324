import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import TransactionalOrder from './TransactionalOrder';
import TransactionalIncentive from './TransactionalIncentive';
import TransactionalWallet from './TransactionalWallet';
import classnames from 'classnames';
import { Can } from '../../../helpers/casl';
import { getCommunicationRules } from '../../../slices/communication/thunk';

const Transactional = (props) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState('1');

  const getData = () => {
    dispatch(getCommunicationRules());
  };

  const loadingState = createSelector(
    (state) => state.Communication.isLoading,
    (isLoading) => isLoading
  );

  const transactionalsState = createSelector(
    (state) => state.Communication.transactionals,
    (transactionals) => transactionals
  );

  const transactionals = useSelector(transactionalsState);
  const isLoading = useSelector(loadingState);

  useEffect(() => {
    getData();
  }, [dispatch]);

  document.title = 'Regua transacional | Opencashback';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Régua transacional" pageTitle="Ferramentas" />
          <Can I="read" a="communications">
            <Row>
              <Col>
                <Card>
                  <CardHeader className="px-2">
                    <Nav
                      tabs
                      className="nav nav-justified nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames(
                            {
                              active: step === '1',
                            },
                            'text-uppercase fw-semibold'
                          )}
                          onClick={() => setStep('1')}
                        >
                          {props.t('transactional-rule-wallet')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames(
                            {
                              active: step === '2',
                            },
                            'text-uppercase fw-semibold'
                          )}
                          onClick={() => setStep('2')}
                        >
                          {props.t('transactional-rule-orders')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames(
                            {
                              active: step === '3',
                            },
                            'text-uppercase fw-semibold'
                          )}
                          onClick={() => setStep('3')}
                        >
                          {props.t('transactional-rule-incentives')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <TabContent activeTab={step} className="text-muted">
              <TabPane tabId="1" id="nav-border-justified-home">
                <TransactionalWallet transactionals={transactionals.wallet} />
              </TabPane>
              <TabPane tabId="2" id="nav-border-justified-home">
                <TransactionalOrder transactionals={transactionals.order} />
              </TabPane>
              <TabPane tabId="3" id="nav-border-justified-home">
                <TransactionalIncentive
                  transactionals={transactionals.incentive}
                />
              </TabPane>
            </TabContent>
          </Can>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Transactional);
