import React, { useContext } from "react";
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import i18n from '../../../../i18n';
import { formatCurrency } from '../../../../helpers/format';

import TableContainer from '../../../../Components/Common/TableContainer';
import InputSwitch from '../../../../Components/Common/InputSwitch';
import useQsParams from '../../../../Components/Hooks/QueryString';

import { AbilityContext } from "../../../../helpers/casl";

export const smartIncentivesColumns = [
  {
    accessor: 'name',
    Header: i18n.t('smart-incentives.table.group'),
    Cell: (props) => <div style={{ minWidth: 180 }}>{props.value}</div>,
  },

  {
    accessor: 'amount',
    Header: i18n.t('smart-incentives.table.value'),
    Cell: ({ value }) => formatCurrency(value),
    reportProps: {
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    accessor: 'cashback_expire_days',
    Header: i18n.t('smart-incentives.table.expiration'),
    Cell: ({ value }) => `${value} ${i18n.t('days')}`,
  },

  {
    Header: i18n.t('credit-wallets'),
    accessor: 'credit-wallets',
    filterable: false,
    Cell: (info) => info.row.original.distinct_wallets_count,
    reportProps: {
      accessor: 'distinct_wallets_count',
    },
  },

  {
    Header: i18n.t('credit-total'),
    accessor: 'credit-total',
    filterable: false,
    Cell: (info) => formatCurrency(info.row.original.total_incentives_amount),
    reportProps: {
      accessor: 'total_incentives_amount',
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    Header: i18n.t('orders-generated'),
    accessor: 'orders-generated',
    filterable: false,
    Cell: (info) => info.row.original.total_orders_related,
    reportProps: {
      accessor: 'total_orders_related',
    },
  },

  {
    Header: i18n.t('used-credit'),
    accessor: 'used-credit',
    filterable: false,
    Cell: (info) => formatCurrency(info.row.original.total_credit_redeemed),
    reportProps: {
      accessor: 'total_credit_redeemed',
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    Header: i18n.t('will-expire-credit'),
    accessor: 'will-expire-credit',
    filterable: false,
    Cell: (info) => formatCurrency(info.row.original.total_credit_to_expire),
    reportProps: {
      accessor: 'total_credit_to_expire',
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    Header: i18n.t('expired-credit'),
    accessor: 'expired-credit',
    filterable: false,
    Cell: (info) => formatCurrency(info.row.original.total_credit_expired),
    reportProps: {
      accessor: 'total_credit_expired',
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    Header: i18n.t('incremental-billing-generated'),
    accessor: 'incremental-billing-generated',
    filterable: false,
    Cell: (info) => formatCurrency(info.row.original.total_orders_amount),
    reportProps: {
      accessor: 'total_orders_amount',
      formatter: {
        type: 'currency',
        properties: { currency: 'BRL', centsToCurrency: true },
      },
    },
  },

  {
    Header: i18n.t('discount-in-incremental-billing'),
    accessor: 'discount-in-incremental-billing',
    filterable: false,
    Cell: (info) => {
      const raw = info.row.original.discount_in_total_orders_amount;

      let value = Math.round(raw * 100);
      let color = '#212529';

      const low = value >= 0.1 && value <= 10;
      const regular = value >= 11 && value <= 40;
      const good = value >= 41 && value <= 70;
      const high = value >= 71;

      if (low) color = 'green';
      if (regular) color = 'blue';
      if (good) color = 'orange';
      if (high) color = 'tomato';

      return (
        <span className="fw-semibold" style={{ color }}>
          {value > 100 ? 100 : value}%
        </span>
      );
    },
    reportProps: {
      accessor: 'discount_in_total_orders_amount',
      formatter: {
        type: 'decimal_percentage',
        properties: { value: 0 },
      },
    },
  },

  {
    Header: i18n.t('engagement'),
    accessor: 'engagement',
    filterable: false,
    Cell: (info) => {
      const raw = info.row.original.engagement_percentage;

      let value = Math.round(raw * 100);
      let color = '#212529';

      const low = value >= 0.1 && value <= 10;
      const regular = value >= 11 && value <= 40;
      const good = value >= 41 && value <= 70;
      const high = value >= 71;

      if (low) color = 'tomato';
      if (regular) color = 'orange';
      if (good) color = 'blue';
      if (high) color = 'green';

      return (
        <span className="fw-semibold" style={{ color }}>
          {value > 100 ? 100 : value}%
        </span>
      );
    },
    reportProps: {
      accessor: 'engagement_percentage',
      formatter: {
        type: 'decimal_percentage',
        properties: { value: 0 },
      },
    },
  },
];

const SmartIncentiveTable = ({ data, type }) => {
  const { qs, setQs, setSearchParamsAsObject } = useQsParams();
  const ability = useContext(AbilityContext);
  const shouldntEdit = ability.cannot("update", "smart_incentive");

  const toggleModal = (props, extraProps = {}) => {
    const data = props?.row?.original;
    const payload = JSON.stringify({
      id: data.smart_incentive_id,
      group: data?.name,
      expire_days: data?.cashback_expire_days,
      type: data.type,
      due_date: data?.due_date,
      amount: data?.amount,
      active: data?.active,
    });

    setSearchParamsAsObject({ action: encodeURI(payload), ...extraProps });
  };

  const columns = [
    ...smartIncentivesColumns,
    {
      Header: i18n.t('smart-incentives.table.status'),
      Cell: (props) => {
        const data = props?.row?.original;
        const id = data?.incentive_group_id || data.smart_incentive_id;

        return (
          <InputSwitch
            size="sm"
            disabled={qs.get('view') == id  || shouldntEdit}
            onChange={() => toggleModal(props, { view: id })}
            checked={data?.active}
            name="active"
          />
        );
      },
    },

    {
      Header: i18n.t('actions'),
      Cell: (props) => {
        const hasGroup = props?.row?.original?.incentive_group_id;
        const viewLink = `/incentivos/${hasGroup}`;

        return (
          <ul className="list-inline hstack gap-2 mb-0 me-2">
            <li className="list-inline-item" id={`view-${props.row.id}`}>
              <Link to={hasGroup ? viewLink : window.location.href}>
                <i
                  className={classNames({
                    'ri-eye-fill align-bottom': true,
                    'text-light-emphasis': !hasGroup,
                    'text-muted': !hasGroup,
                  })}
                />
                <UncontrolledTooltip target={`view-${props.row.id}`}>
                  {i18n.t('smart-incentives.table.view')}
                </UncontrolledTooltip>
              </Link>
            </li>

            <li className="list-inline-item" id={`edit-${props.row.id}`}>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  hasGroup && !shouldntEdit ? toggleModal(props, { edit: true }) : null
                }
                className={classNames({
                  'ri-pencil-fill align-bottom': true,
                  'text-light-emphasis': !hasGroup,
                  'text-muted': !hasGroup || shouldntEdit,
                })}
              />
              <UncontrolledTooltip target={`edit-${props.row.id}`}>
                {i18n.t('smart-incentives.table.edit')}
              </UncontrolledTooltip>
            </li>
          </ul>
        );
      },
    },
  ];

  return (
    <div
      className="card"
      style={{
        background: 'white',
        border: '1px solid #e9ebef',
        overflow: 'auto',
      }}
    >
      <div style={{ marginTop: -16 }}>
        <TableContainer
          isLoading={data.isLoading}
          columns={columns}
          data={data?.incentives[type] || []}
          activePage={qs.get('page')}
          pageSize={data?.meta?.take || 10}
          totalPages={data?.meta?.pages || 0}
          count={data?.meta?.itemCount || 0}
          setPage={(p) => setQs('page', p)}
          tableClass="align-middle table-wrap"
          theadClass="table-light align-middle"
          divClass="table-responsive"
        />
      </div>
    </div>
  );
};

export default SmartIncentiveTable;
