import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { convertCents } from '../../../../helpers/format';
import InformativeCard from '../../../../Components/Common/InformativeCard';
import IndicatorsProps from '../../types/IndicatorsProps';
import Card from '../Card';

const SalesVolumeLifetimeInfo = (props: IndicatorsProps): React.JSX.Element => {
  const indicators = props?.data?.billing;

  const summary = [
    [
      {
        label: props?.t('reports.sales-volume.lifetime.info.qty-impacted'),
        value: indicators.summary.sales_quantity_impacted,
        type: 'currency',
      },
      {
        label: props?.t(
          'reports.sales-volume.lifetime.info.qty-impacted-percentage'
        ),
        value: indicators.summary.sales_quantity_impacted_percentage * 100,
        type: 'percentage',
      },
    ],

    [
      {
        label: props?.t('reports.sales-volume.lifetime.info.qty-incremental'),
        value: indicators.summary.sales_quantity_incremental,
        type: 'currency',
      },
      {
        label: props?.t(
          'reports.sales-volume.lifetime.info.qty-incremental-percentage'
        ),
        value: indicators.summary.sales_quantity_incremental_percentage * 100,
        type: 'percentage',
      },
    ],

    [
      {
        label: props?.t('reports.sales-volume.lifetime.info.qty-mixed'),
        value: indicators.summary.sales_quantity_mixed,
        type: 'currency',
      },
      {
        label: props?.t(
          'reports.sales-volume.lifetime.info.qty-mixed-percentage'
        ),
        value: indicators.summary.sales_quantity_mixed_percentage * 100,
        type: 'percentage',
      },
    ],

    [
      {
        label: props?.t('reports.sales-volume.lifetime.info.qty-no-cashback'),
        value: indicators.summary.sales_quantity_without_cashback,
        type: 'currency',
      },
      {
        label: props?.t(
          'reports.sales-volume.lifetime.info.qty-no-cashback-percentage'
        ),
        value:
          indicators.summary.sales_quantity_without_cashback_percentage * 100,
        type: 'percentage',
      },
    ],
  ];

  return (
    <Card
      minimal
      body={summary.map((row, rIndex) => (
        <Row key={`row-${rIndex}`}>
          {row.map((item, iIndex) => (
            <Col key={`col-${iIndex}`} xs={6}>
              <InformativeCard
                label={item.label}
                counter={item.value}
                icon={null}
                decimals={item?.type === 'percentage' ? 1 : 2}
                duration={1.5}
                prefix={item?.type === 'currency' ? 'R$' : ''}
                suffix={item?.type === 'percentage' ? '%' : ''}
                separator="."
                decimal=","
                additionalInfo={undefined}
                badge={undefined}
                badgeClass={undefined}
                link_to={undefined}
                link_name={undefined}
                link_deactive={undefined}
                bgcolor={undefined}
              />
            </Col>
          ))}
        </Row>
      ))}
    />
  );
};

export default withTranslation()(SalesVolumeLifetimeInfo);
