import { withTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';

import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import IndicatorsProps from '../../types/IndicatorsProps';

import Generated from './Generated';
import Redeem from './Redeem';
import Mixed from './Mixed';
import Card from '../Card';
import { useState } from 'react';

const EarningRedeemIndicators = (props: IndicatorsProps): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ marginBottom: isOpen ? 50 : 0 }}>
      <BreadCrumb
        title={props?.t('reports.earning-redeem.heading')}
        onClick={toggle}
        description={props?.t(
          isOpen ? 'reports.click-to-hide' : 'reports.click-to-expand'
        )}
      />

      <Collapse isOpen={isOpen}>
        <Card title={props?.t('reports.earning-redeem.lifetime.heading')} />

        <Row>
          <Col xxl={6} xl={12}>
            <Card body={<Generated {...props} />} />
          </Col>

          <Col xxl={6} xl={12}>
            <Card body={<Redeem {...props} />} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Card body={<Mixed {...props} />} />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default withTranslation()(EarningRedeemIndicators);
