import React from "react";
import { Input } from "reactstrap";
import { formatInputCurrency } from "../../helpers/format";

const CurrencyInput = ({
  name,
  id,
  placeholder,
  onChange,
  onBlur,
  value,
  invalid,
  disabled,
  className,
}) => {
  value = formatInputCurrency(value);

  return (
    <React.Fragment>
      <Input
        type='text'
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          let value = e.target.value;
          const newValue = formatInputCurrency(value);
          onChange(newValue, e);
        }}
        onBlur={onBlur}
        value={value}
        className={`${className} form-control`}
        invalid={invalid}
        disabled={disabled ? true : false}
      />
    </React.Fragment>
  );
};

export default CurrencyInput;
