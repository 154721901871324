import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportExport as reportExportApi } from '../../helpers/bff_helper';
import { toast } from 'react-toastify';

export const requestReportExport = createAsyncThunk(
  'report/export',
  async ({ success, error, ...params }) => {
    try {
      const response = await reportExportApi(params);
      toast.success(success);
      return JSON.stringify({ status: response.status });
    } catch (error) {
      toast.error(error);
      return error;
    }
  }
);
