import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationENG from "./locales/en.json";
import translationPT from "./locales/pt.json";

// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  pt: {
    translation: translationPT
  }
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "pt");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "pt", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export const getLanguage = (lang = 'pt') => {
  const dict = { pt: 'pt-BR', en: 'en-US' };
  return dict[i18n.language ?? lang];
};
