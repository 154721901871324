import React from "react";
import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import SimpleCard from "../../Components/Common/SimpleCard";

const WizardSetup = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <h5 className="text-decoration-underline mb-3 pb-1">{props.t('wizard-setup')}</h5>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <SimpleCard label="Status da instalação" value="90%" />
        </Col>
        <Col lg={3}>
          <SimpleCard label="Estratégia de cashback vigente" value="OCK" />
        </Col>
        <Col lg={3}>
          <SimpleCard label="Regras gerais de cashback vigente" value="OCK" />
        </Col>
        <Col lg={3}>
          <SimpleCard label="Régua de comunicação vigente" value="Padrão" />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default withTranslation()(WizardSetup);
