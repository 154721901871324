import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";

const CancelModal = ({
  isOpen,
  toggle,
  onCancel,
  t
}) => {
  return (
    <React.Fragment>
      <Modal keyboard={false} id="cancel-order-modal" tabIndex="-1" isOpen={isOpen} toggle={toggle} centered>
        <ModalBody className='text-center p-5'>
          <div className="text-end">
            {/* <button type="button" onClick={() => { toggle() }} className="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></button> */}
          </div>
          <div className="mt-2">
            <h4 className="mb-3 mt-4">{t('want-cancel-order')}</h4>
            <p className="text-muted fs-15 mb-4">{t("want-cancel-order-description")}</p>
            <div className="hstack gap-2 justify-content-center">
              <button onClick={onCancel} className="btn btn-danger">{t('cancel-order')}</button>
              <button onClick={toggle} className="btn btn-success">{t('continue-order')}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(CancelModal)
