import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import Select from "react-select";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

import { isInvalid } from "../../helpers/functions";
import { createUser, getRoles, getStoresRaw } from "../../slices/thunks";

import { useProfile } from "../../Components/Hooks/UserHooks";
import ActionButton from "../../Components/Common/ActionButton";
import Dropdown from "../../Components/Common/Dropdown";
import useQsParams from "../../Components/Hooks/QueryString";

const isAdminSelected = (roleLabel) => {
  if (!roleLabel) return false;
  return String(roleLabel)?.toLowerCase().includes("administrador");
};

const CreateUserForm = ({ onSuccess, t }) => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const { resetSearchParams } = useQsParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const data = useSelector(
    createSelector(
      (store) => ({ stores: store?.Stores, roles: store?.User }),
      (slice) => ({
        stores: {
          isLoading: slice?.stores?.isLoading,
          data: [...slice?.stores?.stores]?.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        },
        roles: {
          isLoading: slice?.roles?.isLoadingRoles,
          data: [...slice?.roles?.roles]?.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        },
      })
    )
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getStoresRaw());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      password: "",
      stores: [],
      role: "",
      // role: data?.roles,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("validation-required-name")),
      email: Yup.string()
        .required(t("validation-required-email"))
        .email(t("validation-valid-email")),
      password: Yup.string().required(t("validation-required-password")),
      role: Yup.object().required(t("validation-required-role")),
      stores: Yup.array().when("role", {
        is: (k) => !k?.label?.toLowerCase().includes("administrador"),
        then: (schema) =>
          schema
            .min(1, t("validation-required-store-at-least-one"))
            .required(t("required")),
      }),
    }),
    onSubmit: async (values) => {
      const isAdmin = isAdminSelected(values?.role?.label);

      setIsSubmitting(true);

      const newUser = {
        name: values.name,
        email: values.email,
        type: "account",
        account_id: profile.userProfile.account_id,
        password: values.password,
        roles: [values?.role?.value],
        stores_ids: values?.stores?.map((item) => item.value),
      };

      const action = await dispatch(
        createUser({
          user: newUser,
          successMessage: t("new-user-success"),
          conflictMessage: t("new-user-conflict"),
          errorMessage: t("new-user-error"),
        })
      );

      setIsSubmitting(false);

      if (!action.payload.error) {
        formik.resetForm();
        onSuccess(action.payload);
        resetSearchParams();
      }
    },
  });

  return (
    <>
      <div className="row">
        <div className="col flex-grow-1">
          <Card className="m-0">
            <CardBody>
              <div className="d-flex gap-2">
                <div
                  className="d-flex flex-row gap-3 align-items-center"
                  style={{ height: 40 }}
                >
                  <h4 className="m-0">{t("new-user")}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-lg-auto col-12 mt-lg-0 mt-3">
          <Card className="m-0 h-100">
            <CardBody>
              <ActionButton
                label={t("save")}
                icon="bx-check"
                disabled={
                  data?.stores?.isLoading ||
                  data?.roles?.isLoading ||
                  isSubmitting
                }
                onClick={formik.submitForm}
              />
            </CardBody>
          </Card>
        </div>
      </div>

      <Form className="tablelist-form mt-5" onSubmit={formik.handleSubmit}>
        <Row className="g-3">
          <Col lg={12}>
            <div>
              <Label htmlFor="name-field" className="form-label">
                {t("name")}
              </Label>
              <div className="input-group">
                <Input
                  name="name"
                  id="name-field"
                  className="form-control"
                  placeholder={t("name-placeholder")}
                  type="text"
                  validate={{ required: { value: false } }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  invalid={isInvalid(formik, "name") ? true : false}
                />
                {isInvalid(formik, "name") && (
                  <FormFeedback type="invalid">
                    {formik.errors.name}
                  </FormFeedback>
                )}
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <div>
              <Label htmlFor="email-field" className="form-label">
                {t("email")}
              </Label>
              <div className="input-group">
                <Input
                  name="email"
                  id="email-field"
                  className="form-control"
                  placeholder={t("email-placeholder")}
                  type="text"
                  validate={{ required: { value: false } }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email || ""}
                  invalid={isInvalid(formik, "email") ? true : false}
                />
                {isInvalid(formik, "email") && (
                  <FormFeedback type="invalid">
                    {formik.errors.email}
                  </FormFeedback>
                )}
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <div>
              <Label htmlFor="password-field" className="form-label">
                {t("password")}
              </Label>
              <div className="input-group">
                <Input
                  name="password"
                  id="password-field"
                  className="form-control"
                  placeholder={t("password-placeholder")}
                  type="password"
                  validate={{ required: { value: false } }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password || ""}
                  invalid={isInvalid(formik, "password") ? true : false}
                />
                {isInvalid(formik, "password") && (
                  <FormFeedback type="invalid">
                    {formik.errors.password}
                  </FormFeedback>
                )}
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <div>
              <Label htmlFor="role-field" className="form-label">
                {t("permission")}
              </Label>
              <div className="input-group">
                <Select
                  placeholder={t("select-placeholder")}
                  name="group"
                  id="group-field"
                  className="form-group w-100"
                  value={formik.values.role}
                  onChange={(e) => formik.setFieldValue("role", e)}
                  options={data?.roles?.data}
                />
                {isInvalid(formik, "role") && (
                  <FormFeedback className="d-block" type="invalid">
                    {formik.errors.role}
                  </FormFeedback>
                )}
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <Label htmlFor="store-field" className="form-label">
              {t("store")}
            </Label>
            {isAdminSelected(formik.values.role?.label) ? (
              <Select
                isDisabled
                options={[{ label: "Todas", value: "0" }]}
                value={{ label: "Todas", value: "0" }}
              />
            ) : (
              <>
                <Dropdown
                  isMulti
                  disabled={data?.stores?.isLoading}
                  values={formik?.values?.stores}
                  options={data?.stores?.data}
                  onChange={(values) => formik.setFieldValue("stores", values)}
                />
                {isInvalid(formik, "stores") && (
                  <FormFeedback className="d-block" type="invalid">
                    {formik.errors.stores}
                  </FormFeedback>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withTranslation()(CreateUserForm);
