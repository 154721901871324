import { Col } from 'reactstrap';
import classNames from 'classnames';
import useQsParams from '../Hooks/QueryString';

const BADGE = {
  width: 30,
  height: 30,
  fontSize: 15,
  lineHeight: 30,
  borderRadius: '50%',
};

const StepIndicator = ({
  label,
  description,
  number,
  active: defaultActive,
  heading = false,
  onClick = () => null,
}) => {
  const { qs, setQs } = useQsParams();
  const current = Number(qs.get('active') || defaultActive);
  const active = current === number;

  return (
    <Col>
      <button
        type="button"
        onClick={() => {
          onClick();
          setQs('active', number);
        }}
        className={classNames(
          'btn btn-label border-0 w-100 rounded-3',
          active ? 'btn-success text-white' : 'btn-light bg-white text-primary',
          heading ? 'bg-transparent p-0 mb-4' : 'py-5 px-3 shadow-lg'
        )}
      >
        <div
          className={classNames(
            'd-flex',
            heading
              ? 'flex-row align-items-start pt-1 text-start'
              : 'flex-column'
          )}
        >
          <div
            className={classNames(
              'text-white position-relative mx-auto rounded-pill',
              active ? 'btn-success label-icon' : 'bg-primary label-icon',
              heading ? 'mx-0 me-3 ms-1 bg-primary' : 'mx-auto'
            )}
            style={BADGE}
          >
            {number}
          </div>

          <div className="flex-fill">
            <p
              className={classNames(
                'm-0 fw-semibold lh-1',
                heading ? 'fs-2 mx-0 text-primary' : 'fs-4 mt-2 mx-auto'
              )}
            >
              {label}
            </p>

            <p
              className={classNames(
                'mx-auto lh-sm mt-1',
                active ? 'text-white' : 'text-muted',
                heading ? 'text-muted' : ''
              )}
            >
              {description}
            </p>
          </div>
        </div>
      </button>
    </Col>
  );
};

export default StepIndicator;
