import { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
  Input,
  FormFeedback,
} from 'reactstrap';

import { isInvalid } from '../../../../helpers/functions';
import {
  getSmartIncentives,
  putSmartIncentives,
} from '../../../../slices/incentives/thunk';

import useQsParams from '../../../../Components/Hooks/QueryString';
import CurrencyInput from '../../../../Components/Common/CurrencyInput';
import ActionButton from '../../../../Components/Common/ActionButton';
import InputSwitch from '../../../../Components/Common/InputSwitch';

function _nextExecution(due_date) {
  if (due_date) return moment(due_date).format('DD/MM');

  const now = moment().locale('pt-br');
  const hour = now.hour();
  const min = now.minutes();

  if (hour <= 10 && min === 0) return now.format('DD/MM');
  const tomorrow = now.clone().add(1, 'day');
  return tomorrow.format('DD/MM');
}

const SmartIncentiveModal = ({ t }) => {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const dismiss = () =>
    setSearchParamsAsObject({ action: '', view: '', edit: '' });

  const isEdit = qs.get('edit') == 'true';
  const initialValues = JSON.parse(decodeURI(qs.get('action')));
  const active = !isEdit ? !initialValues?.active : initialValues?.active;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues, active },

    validationSchema: Yup.object({
      expire_days: Yup.number()
        .min(1, t('validation-required-field'))
        .required(t('validation-required-field')),
      amount: Yup.string()
        .test({
          message: t('validation-required-field'),
          test: (v) => v.trim() != '0,00',
        })
        .required(t('validation-required-field')),
      active: Yup.boolean().required(t('validation-required-field')),
    }),

    onSubmit: async ({ active, id, expire_days, amount }) => {
      setLoading(true);

      const amountType = typeof amount === 'string';
      amount = amountType ? Number(amount.replace(',', '')) : amount;

      const action = await dispatch(
        putSmartIncentives({
          id,
          active,
          expire_days,
          amount,
          type: initialValues.type,
        })
      );

      setLoading(false);

      if (!action.payload.error) {
        toast.success(t('smart-incentives.success'));
        dispatch(getSmartIncentives());
        formik.resetForm();
        return dismiss();
      }

      return toast.error(t('smart-incentives.error'));
    },
  });

  return (
    <Modal
      id="smart-incentives"
      isOpen={qs.get('action')}
      toggle={dismiss}
      centered
    >
      <ModalHeader className="bg-info-subtle p-3" toggle={dismiss}>
        {formik.values.group}
      </ModalHeader>

      <Form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <div className="p-3 mb-3 bg-light border border-text rounded-3">
            <span>{t('smart-incentives.modal.description')}</span>
            <span> "{formik.values.group}".</span>

            <div className="mt-3">
              <Trans
                t={t}
                i18nKey={'smart-incentives.modal.execution'}
                components={{ b: <strong /> }}
              />
            </div>
          </div>

          <Row className="flex-column gap-3">
            <Col>
              <div>
                <Label htmlFor="amount-field" className="form-label">
                  {t('smart-incentives.modal.incentive')}
                </Label>

                <div className="input-group">
                  <span className="input-group-text">{t('currency')}</span>
                  <CurrencyInput
                    name="amount"
                    id="amount-field"
                    placeholder="0,00"
                    value={formik.values.amount}
                    invalid={isInvalid(formik, 'amount') ? true : false}
                    onChange={(e) => formik.setFieldValue('amount', e)}
                    onBlur={formik.handleBlur}
                  />

                  {isInvalid(formik, 'amount') ? (
                    <FormFeedback type="invalid">
                      {formik.errors.amount}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>

            <Col>
              <div>
                <Label htmlFor="expire-field" className="form-label">
                  {t('smart-incentives.modal.expires-in')}
                </Label>

                <div className="input-group">
                  <Input
                    type="number"
                    name="expire_days"
                    id="expire-field"
                    className="form-control"
                    validate={{ required: { value: false } }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.expire_days}
                    invalid={isInvalid(formik, 'expire_days') ? true : false}
                  />
                  <span className="input-group-text">{t('days')}</span>

                  {isInvalid(formik, 'expire_days') && (
                    <FormFeedback type="invalid">
                      {formik.errors.expire_days}
                    </FormFeedback>
                  )}
                </div>
              </div>
            </Col>

            <Col className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-3">
                <Label className="form-label mt-1">{t('status')}</Label>

                <InputSwitch
                  size="sm"
                  onChange={(v) => formik.setFieldValue('active', v)}
                  checked={formik.values.active}
                  name="active"
                />
              </div>

              {formik.values.active && initialValues?.type != "lead_capture" && (
                <div>
                  <span className="text-muted">
                    {`${t(
                      'smart-incentives.modal.next'
                    )} ${_nextExecution(initialValues?.due_date)} - 10AM`}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="border-top pt-3 justify-content-end">
          <ActionButton style="light" label={t('close')} onClick={dismiss} />

          <ActionButton
            label={t('save')}
            icon={loading ? 'bx-loader-alt rotate' : 'bx-check'}
            onClick={formik.handleSubmit}
            disabled={loading}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(SmartIncentiveModal);
