import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const createAbility = (user) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  const { permissions } = user;

  permissions.forEach((permission) => {
    can(permission.action, permission.subject);
  });

  return build();
};
