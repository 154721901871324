import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { useProfile } from "../../Components/Hooks/UserHooks";
import { isInvalid } from "../../helpers/functions";
import { updatePassword } from "../../slices/thunks";

const UpdateUserPasswordModal = ({ isOpen, toggle, t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialFieldValues = {
    current: false,
    newPassword: false,
    confirmation: false,
  };

  const [showPassword, dispatchShowPassword] = useReducer((state, action) => {
    const { current, newPassword, confirmation } = state;
    if (action.type === "current") return { ...state, current: !current };

    if (action.type === "newPassword")
      return { ...state, newPassword: !newPassword };

    if (action.type === "confirmation")
      return { ...state, confirmation: !confirmation };

    if (action.type === "initial") return initialFieldValues;

    throw Error("Unknown action.");
  }, initialFieldValues);

  const profile = useProfile();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(t("password-recovery.required")),
      newPassword: Yup.string()
        .required(t("password-recovery.required"))
        .test(
          "not-same-as-current",
          t("password-recovery.confirmation.same"),
          function (value) {
            const currentPassword = this.parent.currentPassword;
            return value !== currentPassword;
          }
        ),
      newPasswordConfirmation: Yup.string()
        .required(t("password-recovery.required"))
        .oneOf(
          [Yup.ref("newPassword"), null],
          t("password-recovery.confirmation.invalid")
        ),
    }),
    onSubmit: async (values) => {
      const payload = {
        old_password: values.currentPassword,
        new_password: values.newPassword,
      };

      const action = await dispatch(
        updatePassword({
          data: payload,
          invalidMessage: t("password-recovery.modal.invalid-password"),
          errorMessage: t("password-recovery.modal.error"),
        })
      );

      setIsLoading(false);

      if (action.payload.message) {
        formik.setErrors({
          currentPassword: action.payload.message,
        });
      }

      if (!action.payload.error) {
        navigate("/logout");
      }
    },
  });

  const toggleForm = () => {
    toggle();
    formik.resetForm();
    dispatchShowPassword({ type: "initial" });
  };

  return (
    <React.Fragment>
      <Modal
        centered
        id="userPasswordUpdateModal"
        isOpen={isOpen}
        toggle={toggleForm}
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggleForm}>
          {t("password-recovery.modal.heading")}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                <Label htmlFor="currentPassword" className="form-label">
                  {t("password-recovery.modal.current-password")}
                </Label>

                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    name="currentPassword"
                    id="currentPassword"
                    className="form-control"
                    placeholder={t("password-placeholder")}
                    type={showPassword.current ? "text" : "password"}
                    validate={{ required: { value: false } }}
                    value={formik.values.currentPassword || ""}
                    invalid={!!isInvalid(formik, "currentPassword")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  {isInvalid(formik, "currentPassword") && (
                    <FormFeedback type="invalid">
                      {formik.errors.currentPassword}
                    </FormFeedback>
                  )}

                  {formik?.values?.currentPassword ? (
                    <button
                      className="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                      onClick={() => dispatchShowPassword({ type: "current" })}
                      style={{
                        right: isInvalid(formik, "currentPassword") ? 20 : 0,
                      }}
                      type="button"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  ) : null}
                </div>
              </Col>

              <Col lg={12} className="mb-3">
                <Label htmlFor="newPassword" className="form-label">
                  {t("password-recovery.modal.new-password")}
                </Label>

                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    name="newPassword"
                    id="newPassword"
                    className="form-control"
                    placeholder={t("password-placeholder")}
                    type={showPassword.newPassword ? "text" : "password"}
                    validate={{ required: { value: false } }}
                    value={formik.values.newPassword || ""}
                    invalid={!!isInvalid(formik, "newPassword")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  {isInvalid(formik, "newPassword") && (
                    <FormFeedback type="invalid">
                      {formik.errors.newPassword}
                    </FormFeedback>
                  )}

                  {formik?.values?.newPassword ? (
                    <button
                      className="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                      onClick={() =>
                        dispatchShowPassword({ type: "newPassword" })
                      }
                      style={{
                        right: isInvalid(formik, "newPassword") ? 20 : 0,
                      }}
                      type="button"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  ) : null}
                </div>
              </Col>

              <Col lg={12} className="mb-3">
                <Label htmlFor="newPasswordConfirmation" className="form-label">
                  {t("password-recovery.modal.new-password-confirmation")}
                </Label>

                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    name="newPasswordConfirmation"
                    id="newPasswordConfirmation"
                    className="form-control"
                    placeholder={t("password-placeholder")}
                    type={showPassword.confirmation ? "text" : "password"}
                    validate={{ required: { value: false } }}
                    value={formik.values.newPasswordConfirmation || ""}
                    invalid={!!isInvalid(formik, "newPasswordConfirmation")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  {isInvalid(formik, "newPasswordConfirmation") && (
                    <FormFeedback type="invalid">
                      {formik.errors.newPasswordConfirmation}
                    </FormFeedback>
                  )}

                  {formik?.values?.newPasswordConfirmation ? (
                    <button
                      className="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                      onClick={() =>
                        dispatchShowPassword({ type: "confirmation" })
                      }
                      style={{
                        right: isInvalid(formik, "newPasswordConfirmation")
                          ? 20
                          : 0,
                      }}
                      type="button"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={toggleForm}
              >
                {t("close")}
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={!formik.isValid}
              >
                <div className="d-flex">
                  {isLoading && <Spinner size="sm" className="me-3" />}
                  {t("save")}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
        <ToastContainer position="top-center" closeButton={false} limit={1} />  
      </Modal>

    </React.Fragment>
  );
};

export default withTranslation()(UpdateUserPasswordModal);
