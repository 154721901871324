import { createSlice } from '@reduxjs/toolkit';
import {
  getCommunicationRules,
  getCommunicationRulesByChannel,
  getCommunicationTemplate,
  getCommunicationTemplates,
  getTemplateVariables,
} from './thunk';

export const initialState = {
  isLoading: false,
  transactionals: {
    order: [],
    incentive: [],
    wallet: [],
  },
  rules: [],
  templates: [],
  template: {},
  variables: [],
  meta: {},
  error: {},
  isError: false,
};

const communication = createSlice({
  name: 'Communication',
  initialState,
  reducers: {
    cleanup: (state) => {
      state.template = {};
      state.rules = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunicationRules.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCommunicationRules.fulfilled, (state, action) => {
      const walletTransactionals = ['welcome'];
      const orderTransactionals = [
        'purchase-made',
        'credit-received',
        'credit-to-expire',
        'credit-to-receive',
      ];
      const incentiveTransactionals = ['credit-received', 'credit-to-expire'];

      state.isLoading = false;
      state.transactionals.wallet = action.payload.filter((tr) =>
        walletTransactionals.includes(tr.transactional)
      );
      state.transactionals.order = action.payload.filter(
        (tr) =>
          orderTransactionals.includes(tr.transactional) && tr.type === 'order'
      );
      state.transactionals.incentive = action.payload.filter(
        (tr) =>
          incentiveTransactionals.includes(tr.transactional) &&
          tr.type === 'incentive'
      );
    });
    builder.addCase(getCommunicationRules.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getCommunicationRulesByChannel.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCommunicationRulesByChannel.fulfilled,
      (state, action) => {
        state.rules = action.payload;
      }
    );
    builder.addCase(
      getCommunicationRulesByChannel.rejected,
      (state, action) => {
        state.isError = true;
        state.error = action.payload?.error || null;
      }
    );

    builder.addCase(getCommunicationTemplates.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCommunicationTemplates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.templates = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(getCommunicationTemplates.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getCommunicationTemplate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCommunicationTemplate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.template = action.payload;
    });
    builder.addCase(getCommunicationTemplate.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getTemplateVariables.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTemplateVariables.fulfilled, (state, action) => {
      state.isLoading = false;
      state.variables = action.payload;
    });
    builder.addCase(getTemplateVariables.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });
  },
});

export const { cleanup } = communication.actions;
export default communication.reducer;
