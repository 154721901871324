import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getUsers } from "../../slices/thunks";
import { formatDate } from "../../helpers/format";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { first } from "lodash";
import CreateUserForm from "./Create";
import EditUserForm from "./Edit";
import { Can } from "../../helpers/casl";
import Drawer from "../../Components/Common/Drawer";
import useQsParams from "../../Components/Hooks/QueryString";
import QueryString from "qs";

const User = (props) => {
  document.title = "Usuários | Opencashback";

  const [createUserModal, setCreateUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [user, setUser] = useState({});

  const { searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const toggleCreateUser = () => {
    setCreateUserModal(!createUserModal);
  };

  const toggleEditUser = () => {
    setEditUserModal(!editUserModal);
  };

  const dispatch = useDispatch();
  const usersSelector = createSelector(
    (state) => state.User.users,
    (users) => users
  );

  const metaSeletor = createSelector(
    (state) => state.User.meta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.User.isLoading,
    (isLoading) => isLoading
  );

  const users = useSelector(usersSelector);
  const meta = useSelector(metaSeletor);
  const isLoading = useSelector(isLoadingSelector);

  const getData = () => {
    const params = QueryString.stringify(
      {
        page: searchParams.get("page") ?? 1,
        order: "desc",
      },
      { addQueryPrefix: true }
    );

    dispatch(getUsers(params));
  };

  useEffect(() => {
    getData();
  }, [searchParams.toString()]);

  const getStatusBadgeClasses = (status) => {
    switch (status) {
      case "active":
        return "bg-success-subtle text-success";
      case "inactive":
        return "bg-warning-subtle text-warning";
      default:
        return "bg-danger-subtle text-danger";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t("name"),
        accessor: "name",
        filterable: false,
        Cell: (info) => info.row.original.name,
      },
      {
        Header: props.t("email"),
        accessor: "email",
        filterable: false,
        Cell: (info) => info.row.original.email,
      },
      {
        Header: props.t("status"),
        accessor: "status",
        filterable: false,
        Cell: (info) => {
          const status = info.row.original.status;
          const text = props.t(`user-status-${status}`);

          return (
            <React.Fragment>
              <span
                className={`badge rounded-pill ${getStatusBadgeClasses(status)}`}
              >
                {text}
              </span>
            </React.Fragment>
          );
        },
      },
      {
        Header: props.t("created-at"),
        accessor: "created_at",
        filterable: false,
        Cell: (info) => formatDate(info.row.original.created_at),
      },
      {
        Header: props.t("permission"),
        accessor: "permission",
        filterable: false,
        Cell: (info) => {
          const role = first(info.row.original.roles)?.name;
          return role || "-";
        },
      },
      {
        Header: props.t("store"),
        accessor: "stores",
        Cell: ({ value, row }) => {
          const isAdmin = row?.original?.isAdmin;
          const data = isAdmin ? [{ name: "Todas" }] : value;
          if (!value?.length && !isAdmin) return "-";

          return (
            <ul className='list-group ms-3'>
              {data.map((rule, key) => (
                <li key={key}>{rule.name}</li>
              ))}
            </ul>
          );
        },
        reportProps: {
          isArray: true,
        },
      },
      {
        Header: props.t("actions"),
        Cell: (info) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="update" a="account_users">
                <li className="list-inline-item">
                  <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => {
                      const { id, email, name, isAdmin } = info.row.original;
                      const { roles, stores, status } = info.row.original;

                      const userData = { id, email, name, isAdmin };
                      const userInfo = { roles, stores, status };
                      const payload = { ...userData, ...userInfo };

                      setSearchParamsAsObject({
                        create: "",
                        edit: encodeURI(JSON.stringify(payload)),
                      });
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom text-muted" />
                  </button>
                </li>
              </Can>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Usuários" pageTitle="Configurações" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm={1}>{isLoading && <Spinner color="primary" />}</Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <Can I="create" a="account_users">
                          <button
                            type="button"
                            className="btn btn-secondary add-btn"
                            id="create-btn"
                            onClick={() => setSearchParams("create", true)}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {props.t("new-user")}
                          </button>
                        </Can>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <Can I="read" a="account_users">
                    <div>
                      <TableContainer
                        isLoading={isLoading}
                        columns={columns}
                        data={users || []}
                        activePage={searchParams.get("page")}
                        setPage={(p) => setSearchParams("page", p)}
                        pageSize={meta?.take || 10}
                        totalPages={meta?.pages || 0}
                        count={meta?.total || 0}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer position="top-center" closeButton={false} limit={1} />

      <Drawer slug="create" className="w-25">
        <CreateUserForm onSuccess={getData} />
      </Drawer>

      <Drawer slug="edit" className="w-25">
        <EditUserForm onSuccess={getData} />
      </Drawer>
    </React.Fragment>
  );
};

export default withTranslation()(User);
