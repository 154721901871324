import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';


const Section = ({
  t,
  isLoading,
}) => {
  const selectDashboardData = createSelector(
    (state) => state.Profile.user,
    (user) => user
  );

  // Inside your component
  const user = useSelector(selectDashboardData);

  const [userName, setUserName] = useState("User");

  useEffect(() => {

    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(obj.name);
    }
  }, [userName, user]);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">{t('welcome')}, {userName}! {isLoading && (<Spinner color='primary' size='sm' />)}</h4>
              <p className="text-muted mb-0">{t('account-resume')}</p>
            </div>
            <div className="mt-3 mt-lg-0">
              {/* <form action="#">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-sm-auto">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={props.date}
                                                onChange={props.onChange}
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                    defaultDate: []
                                                }}
                                            />
                                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                        </div>
                                    </div>
                                </Row>
                            </form> */}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(Section);