import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Can } from "../../helpers/casl";
import Search from "../../Components/Common/Search";
import EmptyList from "../../Components/Common/EmptyList";
import CatalogBlockedImporter from "./components/CatalogBlockedImporter";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ExportReport from "../../Components/Common/ExportReport";
import TableContainer from "../../Components/Common/TableContainer";
import { getBlockedProducts } from "../../slices/catalogs/thunk";
import BulkBlockedStatusEdit from "./components/BulkBlockedStatusEdit";
import { useReducer } from "react";
import BlockedStatusEdit from "./components/BlockedStatusEdit";
import BlockedStatusDisable from "./components/BlockedStatusDisable";
import BlockedItemDropdown from "./components/BlockedItemDropdown";
import Filter, { getFilters } from "../../Components/Common/Filter";
import { isEmpty } from "lodash";
import useQsParams from "../../Components/Hooks/QueryString";

const CatalogBlocked = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showCsvImportModal, setCsvImportModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const [statusFilter, setStatusFilter] = useState([]);
  const [createdAtFilter, setCreatedAtFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [skuFilter, setSkuFilter] = useState("");

  const { searchParams, setSearchParams } = useQsParams();

  const catalogState = createSelector(
    (state) => state.Catalog.catalog,
    (products) => products
  );

  const loadingState = createSelector(
    (state) => state.Catalog.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (state) => state.Catalog.meta,
    (meta) => meta
  );

  const meta = useSelector(metaState);
  const catalog = useSelector(catalogState);
  const isLoading = useSelector(loadingState);

  document.title = "Produtos bloqueados do catálogo | Opencashback";

  const extractSkus = (product) => {
    const skuList = product?.skus?.reduce((acc, cur) => {
      return [...acc, cur.external_id];
    }, []) || [product?.external_id];
    return skuList;
  };

  useEffect(() => {
    getData();
  }, [dispatch, page, searchParams.toString()]);

  const statuses = [
    {
      label: props.t("customer-wallet-status-blocked_redeem"),
      value: "block_redeem",
    },
    {
      label: props.t("customer-wallet-status-blocked_accumulation"),
      value: "block_accumulation",
    },
    { label: props.t("customer-wallet-status-blocked"), value: "blocked" },
  ];

  const filters = [
    {
      type: "text",
      field: "category",
      name: props.t("category"),
      values: [],
    },
    {
      type: "date",
      field: "created_at",
      name: props.t("created_at"),
      options: { maxDate: false },
      values: [],
    },
    {
      type: "checkbox",
      field: "blocked_status",
      name: props.t("status"),
      values: statuses,
    },
  ];

  const getData = () => {
    const order = "desc";
    let params = { page, order };

    const appliedFilters = getFilters(filters, [
      { field: "external_id", type: "eq" },
      { field: "blocked_status", type: "eq-as-array" },
    ]);

    const hasSearchParam = searchParams.get("name");
    if (hasSearchParam) {
      params = { ...params, name: hasSearchParam };
    }

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    dispatch(getBlockedProducts(params));
  };

  const [modals, dispatchModal] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "toggleEditStatusModal":
          return {
            ...state,
            isEditStatusModalOpened: !state?.isEditStatusModalOpened,
          };
        case "toggleUnblockStatusModal":
          return {
            ...state,
            isUnblockModalOpened: !state?.isUnblockModalOpened,
          };
        case "toggleBulkStatusesModal":
          return {
            ...state,
            isBulkStatusesModalOpened: !state?.isBulkStatusesModalOpened,
          };
        default:
          throw Error("Unknown modal action.");
      }
    },
    {
      isEditStatusModalOpened: false,
      isUnblockModalOpened: false,
      isBulkStatusesModalOpened: false,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: props.t("catalog-table-sku_id"),
        accessor: "external_id",
        filterable: true,
      },
      {
        Header: props.t("catalog-table-sku_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: props.t("catalog-table-category_name"),
        accessor: "category.name",
        filterable: false,
        reportProps: {
          accessor: "category.name",
        },
      },
      {
        Header: props.t("catalog-table-category_id"),
        accessor: "category.external_id",
        filterable: false,
        reportProps: {
          accessor: "category.external_id",
        },
      },
      {
        Header: props.t("catalog-table-blocked_status"),
        Cell: (cellProps) => {
          const raw = cellProps?.row?.original?.blocked_status;
          const value = statuses.find((s) => s.value === raw).label;

          return value ? (
            <span className='badge rounded-pill bg-danger-subtle text-danger'>
              {value}
            </span>
          ) : null;
        },
        reportProps: {
          accessor: "blocked_status",
          formatter: {
            type: "enum",
            properties: {
              options: [
                {
                  value: props.t("customer-wallet-status-blocked_redeem"),
                  key: "block_redeem",
                },
                {
                  value: props.t("customer-wallet-status-blocked_accumulation"),
                  key: "block_accumulation",
                },
                {
                  value: props.t("customer-wallet-status-blocked"),
                  key: "blocked",
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t("actions"),
        Cell: (cellProps) => (
          <Can I='manage' a='catalogs'>
            <BlockedItemDropdown
              onClickEdit={() => {
                setSelectedProduct(extractSkus(cellProps.row.original));
                dispatchModal({ type: "toggleEditStatusModal" });
              }}
              onClickDisable={() => {
                setSelectedProduct(extractSkus(cellProps.row.original));
                dispatchModal({ type: "toggleUnblockStatusModal" });
              }}
            />
          </Can>
        ),
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Produtos bloqueados do catálogo'
            pageTitle='Ferramentas'
          />
          <Row>
            <Col lg={12}>
              <Card id='leadsList'>
                <CardHeader className='border-0'>
                  <Row className='g-4 align-items-center'>
                    <Col xs={12} md={6} xl={2} lg={6}>
                      <Search
                        placeholder={props.t("code")}
                        value={searchParams?.get("external_id")}
                        onChange={(c) => {
                          if (!c?.length) setSearchParams("external_id", "");
                        }}
                        onSubmit={({ id }) => {
                          setPage(1);
                          setSearchParams("external_id", id ?? "");
                        }}
                      />
                    </Col>

                    <Col xs={12} md={6} xl={2} lg={6}>
                      <Search
                        placeholder={props.t("product")}
                        value={searchParams?.get("name")}
                        onChange={(c) => {
                          if (!c?.length) setSearchParams("name", "");
                        }}
                        onSubmit={({ id }) => {
                          setPage(1);
                          setSearchParams("name", id ?? "");
                        }}
                      />
                    </Col>

                    <Col sm={1}>
                      {isLoading && <Spinner color='secondary' />}
                    </Col>

                    <div className='col-sm-auto ms-auto'>
                      <div className='hstack gap-2 d-flex flex-column flex-sm-row'>
                        <button
                          type='button'
                          className='btn btn-primary'
                          onClick={() => setSearchParams("showFilter", true)}
                        >
                          <i className='ri-filter-3-line align-bottom me-1'></i>{" "}
                          {props.t("filters")}
                        </button>
                        <Can I='manage' a='catalogs'>
                          <button
                            type='button'
                            className='btn btn-secondary add-btn'
                            id='manage-blocked-wallets-btn'
                            onClick={() =>
                              setCsvImportModal(!showCsvImportModal)
                            }
                          >
                            <i className='ri-file-upload-line align-bottom me-1'></i>
                            {props.t("catalog-block-import-button")}
                          </button>

                          <button
                            type='button'
                            id='create-btn'
                            className='btn btn-secondary add-btn'
                            onClick={() =>
                              dispatchModal({ type: "toggleBulkStatusesModal" })
                            }
                            disabled={!selectedRows?.length}
                          >
                            <i className='ri-edit-box-line align-bottom me-1'></i>
                            {props.t("wallets-status-update")}
                          </button>

                          <ExportReport
                            pages={meta?.pages}
                            disabled={isLoading || !catalog?.blocked?.length}
                            filename='Relatório OCK: Produtos Bloqueados Do Catálogo'
                            service='strategies/products/blocked'
                            columns={columns}
                            data={{
                              ...(search ? { name: search } : {}),
                            }}
                            filters={{
                              ...(createdAtFilter?.length
                                ? {
                                    created_at: {
                                      between_date: createdAtFilter,
                                    },
                                  }
                                : {}),
                              status: {
                                in: statusFilter?.length
                                  ? statusFilter
                                  : [
                                      "blocked",
                                      "blocked_accumulation",
                                      "blocked_redeem",
                                    ],
                              },
                              ...(categoryFilter
                                ? { category: categoryFilter }
                                : {}),
                              ...(skuFilter ? { external_id: skuFilter } : {}),
                            }}
                          />
                        </Can>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className='pt-0'>
                  {!isLoading && !catalog?.blocked?.length && !search ? (
                    <EmptyList
                      body={props.t("catalog-blocked-products-empty-body")}
                      button={{
                        icon: "ri-file-upload-line",
                        label: props.t("catalog-blocked-products-import"),
                        onClick: () => setCsvImportModal(!showCsvImportModal),
                      }}
                    />
                  ) : null}

                  {!isLoading && !catalog?.blocked?.length && search ? (
                    <EmptyList
                      hideIcon
                      heading={`${props.t("search-empty-heading")} "${search}"`}
                      body={props.t("search-empty-body")}
                    />
                  ) : null}

                  <Can I='read' a='catalogs'>
                    {!isLoading && catalog?.blocked?.length ? (
                      <TableContainer
                        isLoading={isLoading}
                        columns={columns}
                        data={catalog?.blocked || []}
                        activePage={page}
                        pageSize={meta?.take || 10}
                        totalPages={meta?.pages || 0}
                        count={meta?.total || 0}
                        setPage={setPage}
                        className='custom-header-css'
                        divClass='table-responsive table-card mb-0'
                        tableClass='align-middle table-nowrap'
                        theadClass='table-light'
                        setSelectedRows={(selectedIds) => {
                          const rawIds = Object.keys(selectedIds);
                          const newFoundIIems = catalog?.blocked?.filter(
                            ({ id }) => rawIds.includes(String(id))
                          );

                          const newSelectedItems = [
                            ...selectedRows,
                            ...newFoundIIems,
                          ]
                            .filter((k) => rawIds.includes(String(k.id)))
                            .reduce((acc, row) => {
                              const skuList = row?.skus?.reduce((acc, cur) => {
                                return [...acc, cur.external_id];
                              }, []) || [row?.external_id];
                              return [...acc, ...skuList];
                            }, []);
                          setSelectedRows(newSelectedItems);
                        }}
                      />
                    ) : null}
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <CatalogBlockedImporter
        isOpen={showCsvImportModal}
        toggle={() => setCsvImportModal(!showCsvImportModal)}
        onSuccess={() => null}
      />

      <BulkBlockedStatusEdit
        isOpen={modals.isBulkStatusesModalOpened}
        toggle={() => dispatchModal({ type: "toggleBulkStatusesModal" })}
        onSuccess={() => getData()}
        skuList={selectedRows}
      />

      <BlockedStatusEdit
        isOpen={modals.isEditStatusModalOpened}
        toggle={() => dispatchModal({ type: "toggleEditStatusModal" })}
        onSuccess={() => getData()}
        skuList={selectedProduct}
      />

      <BlockedStatusDisable
        isOpen={modals.isUnblockModalOpened}
        toggle={() => dispatchModal({ type: "toggleUnblockStatusModal" })}
        onSuccess={() => getData()}
        skuList={selectedProduct}
      />

      <Filter filters={filters} />

      <ToastContainer position='top-center' closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(CatalogBlocked);
