import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  cleanDocument,
  currencyFieldToNumber,
  isInvalid,
} from '../../../helpers/functions';
import CurrencyInput from '../../../Components/Common/CurrencyInput';
import classnames from 'classnames';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import {
  createIncentive,
  getAllIncentiveGroups,
} from '../../../slices/incentives/thunk';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';
import Yup from '../../../helpers/yup_extras';

const CreateIncentive = ({ isOpen, toggle, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  const getAllGroups = async () => {
    const action = await dispatch(
      getAllIncentiveGroups({
        filters: {
          type: { in: ['user'] },
        },
      })
    );

    if (!action.payload || action.error) {
      return;
    }

    setGroups(
      action.payload.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      document: '',
      amount: '',
      expires_at: '',
      group: '',
    },
    validationSchema: Yup.object({
      document: Yup.string()
        .required(t('validation-required-document'))
        .document(t('validation-invalid-document')),
      amount: Yup.string().required(t('validation-required-amount')),
      expires_at: Yup.string().required(t('validation-required-expires-at')),
      group: Yup.object().required(t('validation-required-group')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const expires_at = moment(values.expires_at).toISOString();

      const newIncentive = {
        document: cleanDocument(values.document),
        amount: currencyFieldToNumber(values.amount),
        incentive_group_id: values.group.value,
        expires_at,
      };

      const action = await dispatch(
        createIncentive({
          incentive: newIncentive,
          successMessage: t('new-incentive-success'),
          errorMessage: t('new-incentive-error'),
          t,
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        validation.resetForm();
        onSuccess(action.payload);
        toggle();
      }
    },
  });

  useEffect(() => {
    getAllGroups();
  }, [isOpen]);

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('new-incentive')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row className="g-3">
              <Col lg={12}>
                <div>
                  <Label htmlFor="document-field" className="form-label">
                    {t('document')}
                  </Label>
                  <div className="input-group">
                    <Input
                      mask={
                        validation.values.document.length < 15
                          ? '999.999.999-999'
                          : '99.999.999/9999-99'
                      }
                      maskChar=""
                      name="document"
                      id="document-field"
                      className="form-control"
                      placeholder={t('document-placeholder')}
                      type="text"
                      validate={{ required: { value: false } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.document || ''}
                      invalid={isInvalid(validation, 'document') ? true : false}
                      tag={ReactInputMask}
                    />
                    {isInvalid(validation, 'document') && (
                      <FormFeedback type="invalid">
                        {validation.errors.document}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor="amount-field" className="form-label">
                    {t('amount')}
                  </Label>
                  <div className="input-group">
                    <span className="input-group-text">{t('currency')}</span>
                    <CurrencyInput
                      name="amount"
                      id="amount-field"
                      placeholder="0,00"
                      onChange={(e) => validation.setFieldValue('amount', e)}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount}
                      invalid={isInvalid(validation, 'amount')}
                    />
                    {isInvalid(validation, 'amount') ? (
                      <FormFeedback type="invalid">
                        {validation.errors.amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor="expires-at-field" className="form-label">
                    {t('expires-at')}
                  </Label>
                  <div className="input-group">
                    <Flatpickr
                      className="form-control"
                      id="expires-at-field"
                      placeholder={t('select-date')}
                      value={validation.values.expires_at}
                      onChange={(e) =>
                        validation.setFieldValue('expires_at', e[0])
                      }
                      options={{
                        altInput: true,
                        altFormat: 'd/m/Y',
                        dateFormat: 'd.m.y',
                      }}
                    />
                    {isInvalid(validation, 'expires_at') && (
                      <FormFeedback className="d-block" type="invalid">
                        {validation.errors.expires_at}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor="group-field" className="form-label">
                    {t('group')}
                  </Label>
                  <div className="input-group">
                    <Select
                      name="group"
                      id="group-field"
                      className="form-group w-100"
                      value={validation.values.group}
                      onChange={(e) => validation.setFieldValue('group', e)}
                      options={groups}
                    />
                    {isInvalid(validation, 'group') && (
                      <FormFeedback className="d-block" type="invalid">
                        {validation.errors.group}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={toggle}>
                {t('close')}
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                <div className="d-flex">
                  {isLoading && <Spinner size="sm" className="me-3" />}
                  {t('save')}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CreateIncentive);
