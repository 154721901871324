import { Container } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { withTranslation } from "react-i18next";

import useQsParams from "../../Components/Hooks/QueryString";
import Filter from "./components/Filter";
import Sales from "./components/Sales";
import Cashback from "./components/Cashback";

const ManagerPanel = ({ t, ...props }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();

  const loadingState = createSelector(
    (state) => state.ManagerPanel.isLoading,
    (isLoading) => isLoading
  );

  const isLoading = useSelector(loadingState);

  document.title = `${t("manager-panel.heading")} | Opencashback`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={isLoading}
            title={t("manager-panel.heading")}
            pageTitle="Gestão"
          />

          <Filter />

          <div className="d-flex flex-column gap-5">
            <Sales />
            <Cashback />
          </div>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(ManagerPanel);
