import { createSlice } from '@reduxjs/toolkit';
import {
  getBillingIndicators,
  getCheckingAccountIndicators,
  getConsumersIndicators,
} from './thunk';

export const initialState = {
  isLoading: false,
  data: {
    billing: {
      data: [],
      summary: {},
    },
    consumers: [],
    checkingAccount: [],
  },
  meta: {},
  error: {},
  isError: false,
};

const indicatorSlice = createSlice({
  name: 'Indicators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillingIndicators.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBillingIndicators.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.billing = {};
      state.data.billing.data = action.payload?.data;
      state.data.billing.summary = action.payload?.summary;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getBillingIndicators.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getConsumersIndicators.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConsumersIndicators.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.consumers = action.payload;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getConsumersIndicators.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getCheckingAccountIndicators.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCheckingAccountIndicators.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.checkingAccount = action.payload;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getCheckingAccountIndicators.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export default indicatorSlice.reducer;
