import React, { useReducer, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import Select from "react-select";
import Wizard from "../../../Components/Common/Wizard";
import CsvImporter from "../../../Components/Common/CsvImporter";
import { batchImportCatalogStrategy } from "../../../slices/strategies/thunk";
import CurrencyInput from "../../../Components/Common/CurrencyInput";

const initialValues = {
  generation_type: "",
  cashback: "",
  rows: 0,
};

const Importer = ({ isOpen, toggle, onSuccess, t, tReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  const toNumber = (value, asString = false) => {
    const raw = String(value).replaceAll(".", "").replace(",", ".");
    return asString ? raw : Number(raw);
  };

  const urlStrategy = window.location.pathname.split("/").pop();

  useEffect(() => {
    if (
      urlStrategy &&
      (urlStrategy.includes('fixed') || urlStrategy.includes('percentage'))
    ) {
      const [type, value] = urlStrategy.split('_');
      const formattedValue =
        type === 'fixed'
          ? (value / 100).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : value * 100;

      const strategy = { type, value: formattedValue };

      initialValues.generation_type = strategy.type;
      initialValues.cashback = strategy.value;

      formik.setFieldValue('generation_type', strategy.type);
      formik.setFieldValue('cashback', strategy.value);
      formik.validateForm();
    }
  }, [urlStrategy]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: initialValues?.cashback !== '',
    initialValues,

    validationSchema: Yup.object({
      generation_type: Yup.string().required(
        t("validation-required-generation-type")
      ),

      cashback: Yup.string()
        .required(t("validation-required-value"))
        .numberString(t("validation-number-value")),
    }),

    onSubmit: async (values) => {
      setIsLoading(true);

      const { columnFields, preview } = payload;

      const configurationParse = columnFields.reduce(
        (currentvalue, _, index) => {
          const column = preview.columns.find((val) => val.index === index);
          const item = { column: column?.header };
          return { ...currentvalue, item };
        },
        {}
      );

      const isFixed = values?.generation_type === "fixed";
      const rawCashback = toNumber(values.cashback, true);
      const cashback = parseFloat(rawCashback);

      const action = await dispatch(
        batchImportCatalogStrategy({
          cashback_type: values.generation_type,
          cashback_amount: isFixed ? cashback * 100 : cashback / 100,
          configuration_parse: JSON.stringify(configurationParse),
          file: payload.file,
          successMessage: t("import-success"),
          errorMessage: t("import-error"),
        })
      );

      if (!action.payload.error) {
        resetState();
        onSuccess();
      }
    },
  });

  const resetState = () => {
    setTimeout(() => {
      setActiveTab(1);
      setPayload(initialValues);
      formik.resetForm();
    }, 1000);
    toggle();
  };

  const generationOption = [
    { label: "Percentual", value: "percentage" },
    { label: "Valor em reais", value: "fixed" },
  ];

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        size={activeTab >= 2 ? "lg" : "xl"}
        style={{ transition: "all 0.2s ease" }}
        toggle={resetState}
        id="showModal"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t("strategies.catalog.import.title")}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Wizard
              showSteps
              enableContinue={false}
              activeTab={activeTab}
              tabs={[
                {
                  label: tReady && t("strategies.catalog.import.step1-heading"),
                  component: (
                    <>
                      <h6>
                        {tReady && t("incentive-import-modal-step1-heading")}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
                          t("incentive-import-modal-step1-description")}
                      </p>

                      <CsvImporter
                        onClose={() => setActiveTab(2)}
                        onComplete={(data) => setPayload({ ...data })}
                        dataHandler={(raw) => {
                          const rows = raw?.reduce(
                            (acc, i) => acc + i,
                            payload.rows
                          );
                          setPayload({ rows });
                        }}
                        fields={[
                          {
                            name: "cod_produto",
                            label: t("strategies.catalog.import.sku_id"),
                          },
                          // {
                          //   name: "sku_name",
                          //   label: t("strategies.catalog.import.sku_name"),
                          // },
                          // {
                          //   name: "category_id",
                          //   label: t("strategies.catalog.import.category_id"),
                          //   optional: true,
                          // },
                          // {
                          //   name: "category_name",
                          //   label: t("strategies.catalog.import.category_name"),
                          //   optional: true,
                          // },
                        ]}
                      />
                    </>
                  ),
                },

                {
                  label: tReady && t("strategies.catalog.import.step2-heading"),
                  component: (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h6>
                        {tReady && t("strategies.catalog.import.step2-heading")}
                      </h6>
                      <span>
                        {tReady &&
                          t("strategies.catalog.import.step2-description")}
                      </span>

                      <div className="gap-3 d-flex flex-column mt-4 mb-2 w-50 justify-content-start">
                        <div style={{ zIndex: 20 }}>
                          <Label
                            className="form-label"
                            htmlFor="generation-type"
                          >
                            {t("cashback-generation")}
                          </Label>
                          <Select
                            name="generation_type"
                            id="generation-type"
                            value={generationOption.find(
                              (item) =>
                                item.value === formik.values.generation_type
                            )}
                            onChange={(e) => {
                              formik.setFieldValue("generation_type", e.value);
                            }}
                            options={generationOption}
                            placeholder={t("select-placeholder")}
                          />
                          {isInvalid(formik, "generation_type") && (
                            <FormFeedback className="d-block" type="invalid">
                              {formik.errors.generation_type}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="input-group">
                          <Label
                            className="form-label text-muted"
                            htmlFor="cashback"
                          >
                            {t("setup-the")}{" "}
                            {formik.values.generation_type === "percentage"
                              ? t("percentage")
                              : t("fixed_amount")}
                          </Label>
                          {formik.values.generation_type === "percentage" && (
                            <div className="input-group">
                              <Input
                                name="cashback"
                                className="form-control"
                                placeholder={`${t(
                                  "setup-the"
                                )} ${t("percentage")}`}
                                type="number"
                                min="0"
                                step="1"
                                pattern="[0-9]"
                                onKeyDown={(e) => {
                                  if (e.key === ".") e.preventDefault();
                                }}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]*/g,
                                    ""
                                  ))
                                }
                                validate={{ required: { value: true } }}
                                value={formik.values.cashback}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={isInvalid(formik, "cashback")}
                              />
                              <span className="input-group-text">%</span>
                              {isInvalid(formik, "cashback") && (
                                <FormFeedback type="invalid">
                                  {formik.errors.cashback}
                                </FormFeedback>
                              )}
                            </div>
                          )}

                          {formik.values.generation_type === "fixed" && (
                            <div className="input-group">
                              <span className="input-group-text">
                                {t("currency")}
                              </span>
                              <CurrencyInput
                                name="cashback"
                                placeholder="0,00"
                                onChange={(e) =>
                                  formik.setFieldValue("cashback", e)
                                }
                                onBlur={formik.handleBlur}
                                value={formik.values.cashback}
                                invalid={isInvalid(formik, "cashback")}
                              />
                              {isInvalid(formik, "cashback") && (
                                <FormFeedback type="invalid">
                                  {formik.errors.cashback}
                                </FormFeedback>
                              )}
                            </div>
                          )}
                          {!["fixed", "percentage"].includes(
                            formik.values.generation_type
                          ) && (
                            <div className="input-group">
                              <span className="text-muted mt-2">
                                Selecione a geração de cashback
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-success align-right"
                        style={{ alignSelf: "end" }}
                        disabled={!formik.isValid}
                        onClick={() => setActiveTab(3)}
                      >
                        {t("order-step")}
                      </button>
                    </div>
                  ),
                },

                {
                  label: tReady && t("strategies.catalog.import.step3-heading"),
                  component: (
                    <>
                      <h6>
                        {tReady && t("incentive-import-modal-step3-heading")}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
                          t("incentive-import-modal-step3-description")}
                      </p>

                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <th style={{ width: "30%" }} scope="row">
                              {t(
                                "strategies.catalog.import.step3-review-amount"
                              )}
                            </th>
                            <td>
                              {payload?.rows} {t("items")}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ width: "30%" }} scope="row">
                              {t(
                                "strategies.catalog.import.step3-review-cashback-amount"
                              )}
                            </th>
                            <td>
                              {formik?.values?.generation_type === "fixed"
                                ? `R$${formik?.values?.cashback}`
                                : `${formik?.values?.cashback}%`}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-ghost"
                          onClick={resetState}
                          disabled={isLoading}
                        >
                          {t("close")}
                        </button>

                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setActiveTab(2)}
                          disabled={isLoading}
                        >
                          {t("back")}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isLoading}
                        >
                          {t("confirm")}
                        </button>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(Importer);
