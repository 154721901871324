import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Table } from 'reactstrap';
import _ from 'lodash';

import { formatCurrency } from '../../../../helpers/format';

const OrderDetailSummary = (props) => {
  const { isLoading, ...summary } = useSelector(
    createSelector(
      (store) => store.Order,
      ({ order, isLoading }) => {
        const props = order.properties;
        const discounts = _.find(props, (p) => p.key === 'discounts');
        const total = _.find(props, (p) => p.key === 'total_paid');

        return {
          subtotal: order?.total ?? 0,
          redeemed: order?.used_cashback ?? 0,
          discounts: discounts?.value ?? 0,
          total: total?.value ?? 0,
          isLoading,
        };
      }
    )
  );

  const rows = [
    {
      label: props.t('order-details.summary.subtotal'),
      value: formatCurrency(summary.subtotal),
    },
    {
      label: props.t('order-details.summary.discount'),
      value: formatCurrency(summary.discounts),
    },
    {
      label: props.t('order-details.summary.cashback-redeemed'),
      value: formatCurrency(summary.redeemed),
    },
    {
      label: props.t('order-details.summary.total'),
      value: formatCurrency(summary.total),
    },
  ];

  return (
    <div className="row justify-content-end mt-4">
      <div className="col-12 col-sm-6 col-xl-5">
        <Table className="col-6 table-nowrap m-0 text-dark">
          <tbody>
            {rows.map(({ label, value, extra }, key) => (
              <tr key={`sidebar-consumer-${key}`}>
                <td className="w-50 fw-bold">{label}</td>
                <td className="w-25 fw-semibold">
                  <span>{value}</span>
                  <span>{extra ?? null}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation()(OrderDetailSummary);
