import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Alert,
} from 'reactstrap';

import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import withRouter from '../../Components/Common/withRouter';
import logo from '../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { createNewPassword, verifyToken } from '../../slices/users/thunk';

const RecoverPasswordPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfShow, setPasswordConfShow] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const [recoveryResponse, setRecoveryResponse] = useState({
    label: '',
    type: '',
  });

  useEffect(() => {
    const redirectTo = `/login?te=${+new Date()}`;
    if (!token) navigate(redirectTo);

    async function checkTokenValidity() {
      const isValid = await dispatch(verifyToken(token));
      if (isValid?.payload?.error) navigate(redirectTo);
      return true;
    }

    checkTokenValidity();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: '',
      confirmation: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required(props.t('password-recovery.required')),
      confirmation: Yup.string()
        .required(props.t('password-recovery.required'))
        .oneOf(
          [Yup.ref('newPassword'), null],
          props.t('password-recovery.confirmation.invalid')
        ),
    }),
    onSubmit: async (values) => {
      const opts = { password: values?.newPassword, token };
      const action = await dispatch(createNewPassword(opts));

      const hasError = action?.payload?.error;
      const message = !hasError ? 'success' : 'error';

      setRecoveryResponse({
        label: props.t(`password-recovery.modal.${message}`),
        type: !hasError ? 'success' : 'danger',
      });

      setTimeout(() => { navigate('/login') }, 3000)
      formik.resetForm();
    },
  });

  document.title = 'Recuperar senha | Opencashback';

  const hasPasswordError = !!(
    formik.touched.newPassword && formik.errors.newPassword
  );
  const hasPasswordConfError = !!(
    formik.touched.confirmation && formik.errors.confirmation
  );

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logo} alt="" height="40" />
                </Link>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {props.t('password-recovery.confirmation.heading')}
                    </h5>
                    <p className="text-muted">
                      {props.t('password-recovery.confirmation.description')}
                    </p>

                    {recoveryResponse?.label ? (
                      <Alert color={recoveryResponse.type} className="mt-3 text-center">
                        {recoveryResponse.label}
                      </Alert>
                    ) : null}
                  </div>

                  <div className="p-2">
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="mb-4">
                        <Label className="form-label">
                          {props.t('password-recovery.confirmation.password')}
                        </Label>

                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="newPassword"
                            value={formik.values.newPassword || ''}
                            type={passwordShow ? 'text' : 'password'}
                            className="form-control pe-5"
                            placeholder={props.t(
                              'password-recovery.confirmation.password-placeholder'
                            )}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={hasPasswordError}
                          />

                          {hasPasswordError ? (
                            <FormFeedback type="invalid">
                              {formik.errors.newPassword}
                            </FormFeedback>
                          ) : null}

                          {formik?.values?.newPassword ? (
                            <button
                              className="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                              onClick={() => setPasswordShow(!passwordShow)}
                              style={{ right: hasPasswordError ? 20 : 0 }}
                              type="button"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          ) : null}
                        </div>
                      </div>

                      <div className="mb-4">
                        <Label className="form-label">
                          {props.t(
                            'password-recovery.confirmation.password-confirmation'
                          )}
                        </Label>

                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="confirmation"
                            value={formik.values.confirmation || ''}
                            type={passwordConfShow ? 'text' : 'password'}
                            className="form-control pe-5"
                            placeholder={props.t(
                              'password-recovery.confirmation.password-confirmation'
                            )}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={hasPasswordConfError}
                          />

                          {hasPasswordConfError ? (
                            <FormFeedback type="invalid">
                              {formik.errors.confirmation}
                            </FormFeedback>
                          ) : null}

                          {formik?.values?.confirmation ? (
                            <button
                              className="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                              onClick={() =>
                                setPasswordConfShow(!passwordConfShow)
                              }
                              style={{ right: hasPasswordConfError ? 20 : 0 }}
                              type="button"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          ) : null}
                        </div>
                      </div>

                      <div className="text-center mt-4">
                        <button
                          className="btn btn-success w-100"
                          disabled={!formik.isValid}
                          type="submit"
                        >
                          {props.t('password-recovery.confirmation.submit')}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center text-primary text-decoration-underline">
                <Link className="fw-semibold" to="/login">
                  {props.t('password-recovery.form.cancel')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

RecoverPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(RecoverPasswordPage));
