import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

import { deleteGroup } from '../../../../slices/incentives/thunk';

import { useDispatch } from 'react-redux';

const DeleteGroup = ({ isOpen, toggle, incentive, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const deleteGroupApi = async () => {
    setIsLoading(true);

    const action = await dispatch(
      deleteGroup({
        groupId: incentive.id,
        successMessage: t('incentives-management.delete-group-success-message'),
        errorMessage: t('incentives-management.delete-group-error-message'),
      })
    );

    setIsLoading(false);
    toggle();

    if (!action.payload.error) {
      onSuccess();
    }
  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('incentives-management.delete-group.delete')}
        </ModalHeader>

        <ModalBody>
          <h4 className="mb-4 text-center">
            {t('incentives-management.delete-group.modal-description')}
          </h4>
          <br></br>
          <h4 className="mb-4 text-center">
            {t('incentives-management.delete-group.question')}
          </h4>
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={toggle}>
              {t('close')}
            </button>
            <button
              onClick={deleteGroupApi}
              type="submit"
              disabled={!incentive}
              className="btn btn-success"
              id="add-btn"
            >
              <div className="d-flex">
                {isLoading && <Spinner size="sm" className="me-3" />}
                {t('confirm')}
              </div>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(DeleteGroup);
