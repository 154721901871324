import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import UpdatePassword from '../../pages/User/UpdatePassword';

const ProfileDropdown = (props) => {
  const [showPasswordUpdateModal, setPasswordUpdateModal] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'));
      setProfile({
        name: obj?.name,
        firstLetter: obj?.name && obj?.name[0],
        hasStore: obj?.stores?.length,
        account: obj?.account?.name,
        role: obj?.roles?.length ? obj?.roles[0].name : null,
      });
    }
  }, []);

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  let description = [];
  if (profile?.role) description.push(profile?.role);
  if (!profile?.hasStore) description.push(profile?.account);

  return (
    <div className="d-flex">
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="border d-flex rounded shadow-sm"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn d-flex gap-2 align-items-center py-1 px-2"
        >
          <div>
            <span
              className="bg-secondary fs-5 fw-semibold rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: 32, height: 32 }}
            >
              {profile?.firstLetter}
            </span>
          </div>

          <div className="gap-1 d-none d-md-flex align-items-center">
            <div className="d-flex flex-column text-start lh-sm">
              <span className="fw-semibold fs-5">{profile?.name}</span>
              <span className="text-muted">{description.join(' - ')}</span>
            </div>
          </div>

          <i
            className={classNames({
              'fs-3 transition bx bx-chevron-down ': true,
              rotate180deg: isProfileDropdown,
            })}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu me-1 border-top" end>
          <div className="dropdown-header d-flex mb-2 align-items-center">
            <span className="text-dark text-uppercase fs-6 fw-semibold">
              {props.t('profile')}
            </span>
          </div>

          <DropdownItem className="p-0">
            <Link
              className="dropdown-item"
              onClick={() => setPasswordUpdateModal(true)}
            >
              <i className="mdi mdi-lock-open text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle" data-key="t-update-password">
                {props.t('password-recovery.modal.heading')}
              </span>
            </Link>
          </DropdownItem>

          <DropdownItem className="p-0">
            <Link
              to={process.env.PUBLIC_URL + '/logout'}
              className="dropdown-item"
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle" data-key="t-logout">
                {props.t('logout')}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <UpdatePassword
        isOpen={showPasswordUpdateModal}
        toggle={() => setPasswordUpdateModal(false)}
      />
    </div>
  );
};

export default withTranslation()(ProfileDropdown);
