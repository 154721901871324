import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditOrderDropdown from '../../components/EditOrderDropdown';
import ApproveOrder from '../../components/ApproveOrder';
import CancelOrder from '../../components/CancelOrder';

const StatusDropdown = ({ order, icon }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();

  const initialState = {
    isApproveOrderModalOpened: false,
    isCancelOrderModalOpened: false,
  };

  const [modals, dispatchModal] = useReducer((state, action) => {
    switch (action.type) {
      case 'toggleApproveOrderModal':
        return {
          ...state,
          isApproveOrderModalOpened: !state?.isApproveOrderModalOpened,
        };

      case 'toggleCancelOrderModal':
        return {
          ...state,
          isCancelOrderModalOpened: !state?.isCancelOrderModalOpened,
        };

      default:
        throw Error('Unknown modal action.');
    }
  }, initialState);

  return (
    <>
      <EditOrderDropdown
        icon={icon}
        order={order}
        onClickApprove={() => {
          setSelectedOrder(order);
          dispatchModal({ type: 'toggleApproveOrderModal' });
        }}
        onClickCancel={() => {
          setSelectedOrder(order);
          dispatchModal({ type: 'toggleCancelOrderModal' });
        }}
      />

      <ApproveOrder
        isOpen={modals.isApproveOrderModalOpened}
        toggle={() => dispatchModal({ type: 'toggleApproveOrderModal' })}
        onSuccess={() => navigate(0)}
        order={selectedOrder}
      />

      <CancelOrder
        isOpen={modals.isCancelOrderModalOpened}
        toggle={() => dispatchModal({ type: 'toggleCancelOrderModal' })}
        onSuccess={() => navigate(0)}
        order={selectedOrder}
      />
    </>
  );
};

export default StatusDropdown;
