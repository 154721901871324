import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLeadCaptureConfiguration as getConfigurationsApi,
  setLeadCaptureConfiguration as setConfigurationsApi,
} from '../../helpers/bff_helper';
import { convertToCents } from '../../helpers/format';

export const getConfigurations = createAsyncThunk(
  'lead-capture/getConfigurations',
  () => {
    try {
      return getConfigurationsApi();
    } catch (error) {
      return error;
    }
  }
);

export const setConfigurations = createAsyncThunk(
  'lead-capture/setConfigurations',
  async (params) => {
    try {
      await setConfigurationsApi({
        logoUrl: params?.logoUrl,
        validatePhone: params?.validatePhone,
        allFieldsRequired: params?.allFieldsRequired,
        enableLeadCapture: params?.enableLeadCapture,
        enabledIncentive: params?.enabledIncentive,
        tracking: '', // not in use, yet

        labels: {
          step1Heading: params?.step1Heading,
          step2Heading: params?.step2Heading,
          step2Body: params?.step2Body,
          step3Heading: params?.step3Heading,
          step3Body: params?.step3Body,
        },

        incentive: {
          name: params?.name,
          expiresInDays: params?.expiresInDays,
          cashbackAmount: params?.cashbackAmount,
        },

        visualIdentity: {
          backgroundColor: params?.backgroundColor,
          textColor: params?.textColor,
          ctaBackgroundColor: params?.ctaBackgroundColor,
          ctaTextColor: params?.ctaTextColor,
          step1HeadingColor: params?.step1HeadingColor,
          step2HeadingColor: params?.step2HeadingColor,
          step3HeadingColor: params?.step3HeadingColor,
        },
      });
      return { status: 200, statusText: 'Success' };
    } catch (error) {
      return error;
    }
  }
);
