import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";

const BreadCrumb = ({
  title,
  pageTitle = undefined,
  description = undefined,
  onClick = () => null,
  backTo = '',
  showBackButton = false,
  isLoading = false,
  t,
}) => {
  const navigate = useNavigate();

  return (
    <Row className='justify-content-between align-items-center py-3 breadcrumb-wrapper'>
      <Col xs={12} md={8}>
        <div className='page-title-box' onClick={onClick}>
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row'>
            {showBackButton ? (
              <button
                type='button'
                className='me-3 mb-3 mb-md-0 p-0 d-flex border-0 align-items-center text-muted bg-transparent'
                onClick={() => {
                  if(backTo) return navigate(backTo);
                  const fallback = String(showBackButton);
                  if (fallback?.startsWith("/")) return navigate(fallback);
                  navigate(-1);
                }}
              >
                <i className='bx bx-arrow-back fs-3 lh-1' />
                <span className='ms-1 fs-8 d-sm-none text-uppercase'>
                  {t("back")}
                </span>
              </button>
            ) : null}

            <div>
              <div className='d-flex gap-2'>
                <h4 className='mb-1'>{t(title)}</h4>
                {isLoading ? (
                  <Spinner color='secondary' className='m-0' size='sm' />
                ) : null}
              </div>

              {description ? (
                <span className='text-muted'>{description}</span>
              ) : null}
            </div>
          </div>

          {/* {description ? (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">{description}</li>
              </ol>
            </div>
          ) : null} */}
        </div>
      </Col>

      <Col
        xs={12}
        md={4}
        className='d-flex justify-content-md-end mt-4 mt-md-0'
      >
        {pageTitle ? (
          <div className='page-title-right me-3 ms-4 ms-md-0'>
            <ol className='breadcrumb m-0'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t(pageTitle)}</Link>
              </li>
              <li className='breadcrumb-item active'>
                {t(title)?.length > 30
                  ? t(title).substr(0, 30) + "..."
                  : t(title)}
              </li>
            </ol>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default withTranslation()(BreadCrumb);
