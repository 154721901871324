import React from 'react';
import TagManager from '@sooro-io/react-gtm-module'

import './assets/scss/themes.scss';
import Route from './Routes';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
});

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
