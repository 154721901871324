import { withTranslation } from 'react-i18next';
import { Card, CardBody, Row, Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';

import {
  formatCellphone,
  formatCurrency,
  formatDate,
  formatDocument,
} from '../../../../helpers/format';
import { translations } from '../../statuses';
import StatusDropdown from './StatusDropdown';
import { Can } from '../../../../helpers/casl';

const render = (card, isLoading) => (
  <>
    <div className="d-flex align-items-center justify-content-between">
      <span className="fs-4 fw-semibold">{card.heading}</span>
      {card.description ?? null}
    </div>

    {isLoading ? (
      <div className="skeleton card" style={{ height: '100px' }} />
    ) : (
      <Card className="mt-1 p-0">
        <CardBody className="p-0">
          <Table className="table table-striped m-0 text-dark">
            <tbody>
              {card.table.map(({ label, value, extra }, key) => (
                <tr key={`sidebar-consumer-${++key}`}>
                  <td className="fw-semibold" style={{ width: '40%' }}>
                    {label}
                  </td>
                  <td className="d-flex justify-content-between">
                    <span className="text-break">{value}</span>
                    <span>{extra ?? null}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    )}
  </>
);

const GeneralInfo = (props) => {
  const { order, isLoading } = useSelector(
    createSelector(
      (store) => store.Order,
      (slice) => ({
        isLoading: slice.isLoading,
        order: slice.order,
      })
    )
  );

  const consumer = {
    heading: props.t('order-details.sidebar.consumer.heading'),
    description: (
      <Link
        to={`/carteiras/detalhes/${order?.customer_data?.document}`}
        className="d-flex align-items-center gap-2 text-muted"
      >
        {props.t('order-details.sidebar.consumer.extra')}
        <i className="bx bx-show fs-5" />
      </Link>
    ),
    table: [
      {
        label: props.t('order-details.sidebar.consumer.table.name'),
        value: order?.customer_data?.name ?? '-',
      },
      {
        label: props.t('order-details.sidebar.consumer.table.document'),
        value: order?.customer_data?.document
          ? formatDocument(order?.customer_data?.document)
          : '-',
      },
      {
        label: props.t('order-details.sidebar.consumer.table.email'),
        value: order?.customer_data?.email ?? '-',
      },
      {
        label: props.t('order-details.sidebar.consumer.table.phone'),
        value: order?.customer_data?.cellphone
          ? formatCellphone(order?.customer_data?.cellphone?.substring(2, 12))
          : '-',
      },
    ],
  };

  const cashback = {
    heading: props.t('order-details.sidebar.cashback.heading'),
    table: [
      {
        label: props.t('order-details.sidebar.cashback.table.status'),
        value: (
          <span
            className={`badge rounded-pill ${
              translations[order?.cashback_status]?.color
            }`}
          >
            {translations[order?.cashback_status]?.label}
          </span>
        ),
        extra: 
        <Can I='update' a='sales_management'>
          <StatusDropdown icon="bx-edit-alt" order={order} />
        </Can>
      },
      {
        label: props.t('order-details.sidebar.cashback.table.expiring'),
        value:
          order?.available_cashback >= 0
            ? formatCurrency(order?.available_cashback)
            : '- ',
      },
      {
        label: props.t('order-details.sidebar.cashback.table.expiring_date'),
        value: order?.cashback_expires_at
          ? formatDate(order?.cashback_expires_at, 'DD/MM/Y')
          : '-',
      },
    ],
  };

  const channel = _.find(order?.properties, (v) => v.key === 'channel')?.value;
  const hasStore = _.find(order?.properties, (v) => v.key === 'store')?.value;
  const hasOrigin = _.find(order?.properties, (v) => v.key === 'origin')?.value;

  const store = {
    heading: props.t('order-details.sidebar.store.heading'),
    table: [
      {
        label: props.t('order-details.sidebar.store.table.origin'),
        value: hasOrigin ? props.t(`order-origin-${hasOrigin}`) : '-',
      },
      {
        label: props.t('order-details.sidebar.store.table.channel'),
        value: channel ? props.t(`order-channel-${channel}`) : '-',
      },
      {
        label: props.t('order-details.sidebar.store.table.store'),
        value: !['null', null, undefined].includes(hasStore) ? hasStore : '-',
      },
      {
        label: props.t('order-details.sidebar.store.table.seller'),
        value:
          _.find(order?.properties, (v) => v.key === 'seller')?.value ?? '-',
      },
    ],
  };

  return (
    <Row className="g-3">
      {render(consumer, isLoading)}
      {render(cashback, isLoading)}
      {render(store, isLoading)}
    </Row>
  );
};

export default withTranslation()(GeneralInfo);
