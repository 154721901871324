import { createSlice } from "@reduxjs/toolkit";
import {
  createNewPassword,
  getRecoveryLink,
  getRoles,
  getRolesPaginated,
  getUsers,
  getUsersFromStore,
} from "./thunk";

export const initialState = {
  isLoading: false,
  users: [],
  meta: {},
  error: {},
  isError: false,
  roles: [],
  rolesMeta: {},
  isLoadingRoles: false,
  isErrorRoles: false,
  errorRoles: {},
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    cleanup: (state) => {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
    builder.addCase(getRolesPaginated.pending, (state, action) => {
      state.isLoadingRoles = true;
    });
    builder.addCase(getRolesPaginated.fulfilled, (state, action) => {
      state.isLoadingRoles = false;
      state.roles = action.payload?.data;
      state.rolesMeta = action.payload?.meta;
    });
    builder.addCase(getRolesPaginated.rejected, (state, action) => {
      state.isErrorRoles = true;
      state.errorRoles = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getRecoveryLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRecoveryLink.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getRecoveryLink.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.log(action);
    });

    builder.addCase(createNewPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(createNewPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.log(action);
    });

    builder.addCase(getRoles.pending, (state) => {
      state.isLoadingRoles = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.isLoadingRoles = false;
      state.isErrorRoles = false;
      state.rolesMeta = action?.meta;
      state.roles = action?.payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.isLoadingRoles = false;
      state.isErrorRoles = true;
      console.log(action);
    });

    // builder.addCase(getUsersFromStore.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getUsersFromStore.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.users = action.payload?.data;
    //   state.meta = action.payload?.meta;
    // });
    // builder.addCase(getUsersFromStore.rejected, (state, action) => {
    //   state.isError = true;
    //   state.error = action.payload?.error || null;
    //   console.log(action);
    // });
  },
});

export const { cleanup } = usersSlice.actions;
export default usersSlice.reducer;
