import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Collapse, Container, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getCheckingAccountIndicators } from '../../../../slices/indicators/thunk';

import IndicatorsProps from '../../types/IndicatorsProps';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import CheckingAccount from './CheckingAccount';
import Card from '../Card';

const CheckingAccountIndicators = ({ t }): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  document.title = `${t('reports.title')} | Opencashback`;
  const dispatch = useDispatch();

  const ReportsState = createSelector(
    (state) => state.Reports.data,
    (reports) => reports
  );

  const loadingState = createSelector(
    (state) => state.Reports.isLoading,
    (isLoading) => isLoading
  );

  useEffect(() => {
    dispatch(getCheckingAccountIndicators());
  }, []);

  const props = {
    data: useSelector(ReportsState),
    loading: useSelector(loadingState),
  } as IndicatorsProps;

  const isLoading = props?.loading;
  const hasCheckingAccountData = props?.data?.checkingAccount?.length;
  const hasData = Boolean(!isLoading && hasCheckingAccountData);

  return (
    <Container fluid>
      <div className="page-content mb-0">
        <BreadCrumb
          title={t('reports.checking-account.heading')}
          onClick={toggle}
          description={t(
            isOpen ? 'reports.click-to-hide' : 'reports.click-to-expand'
          )}
        />

        {hasData ? (
          <Collapse isOpen={isOpen}>
            <Row>
              <Col>
                <Card body={<CheckingAccount {...props} />} />
              </Col>
            </Row>
          </Collapse>
        ) : (
          <Spinner color="secondary" />
        )}
      </div>
    </Container>
  );
};

export default withTranslation()(CheckingAccountIndicators);
