import { createSlice } from '@reduxjs/toolkit';
import { getMyWalletConfiguration } from './thunk';

export const initialState = {
  isLoading: false,
  configuration: {},
  isError: false,
  error: {},
};

const myWalletSlice = createSlice({
  name: 'MyWallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyWalletConfiguration.pending, (state, action) => {
      state.isLoading = true;
      state.configuration = {};
    });
    builder.addCase(getMyWalletConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.configuration = action?.payload;
    });
    builder.addCase(getMyWalletConfiguration.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });
  },
});

export default myWalletSlice.reducer;
