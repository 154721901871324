import { createAsyncThunk } from "@reduxjs/toolkit";
import { 
  getSalesCardsData as getSalesCardsDataApi,
  getSalesChartData as getSalesChartDataApi,
  getCashbackCardsData as getCashbackCardsDataApi,
  getCashbackChartData as getCashbackChartDataApi,
} from "../../helpers/bff_helper";

const namespace = "manager-panel";

export const getSalesCardsData = createAsyncThunk(
  `${namespace}/getSalesCardsData`,
  async (params) => {
    try {
      const response = getSalesCardsDataApi(params);
  
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSalesChartData = createAsyncThunk(
  `${namespace}/getSalesChartData `,
  async (params) => {
    try {
      const response = getSalesChartDataApi(params);
  
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCashbackCardsData = createAsyncThunk(
  `${namespace}/getCashbackCardsData`,
  async (params) => {
    try {
      const response = getCashbackCardsDataApi(params);
  
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCashbackChartData = createAsyncThunk(
  `${namespace}/getCashbackChartData `,
  async (params) => {
    try {
      const response = getCashbackChartDataApi(params);
  
      return response;
    } catch (error) {
      return error;
    }
  }
);
