import { withTranslation } from "react-i18next";
import { Input, Label } from "reactstrap";

import useQsParams from "../../Hooks/QueryString";
import classNames from "classnames";
import { useState } from "react";
import InputSwitch from "../InputSwitch";

function SwitchFilter({
  item,
  key,
  t,
  value = undefined,
  onChange = undefined,
  ...rest // disabled, readOnly, showToggle, aria etc
}) {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const field = value ?? qs.get(item.field);

  const isControlledField = onChange && value !== undefined;

  return (
    <div
      className={classNames({
        "mb-4 ": true,
        "filter-item": !rest?.dry ?? false,
      })}
    >
      {item?.name ? (
        <Label
          htmlFor={`text-${key}`}
          className='form-label text-muted text-uppercase fw-semibold mb-3'
        >
          {item.name}
        </Label>
      ) : null}

      <InputSwitch
        name='validatePhone'
        id={isControlledField ? item.field : `switch-${key}`}
        // checked={formik?.values?.validatePhone}
        checked={field || false}
        // disabled={disabled}
        // onChange={(e) => formik.setFieldValue('validatePhone', e)}
        onChange={(val) => {
          const payload = { [item.field]: !val, page: 1 };
          !onChange ? setSearchParamsAsObject(payload) : onChange(!val);
        }}
        {...rest}
      />
    </div>
  );
}

export default withTranslation()(SwitchFilter);
