import React from 'react';
import { withTranslation } from 'react-i18next';

import InformativeCard from '../../../../Components/Common/InformativeCard';
import IndicatorsProps from '../../types/IndicatorsProps';

const DiscountsInfo = (props: IndicatorsProps): React.JSX.Element => {
  const indicators = props?.data?.billing;

  return (
    <InformativeCard
      label={props?.t('reports.discount.info')}
      counter={indicators?.summary?.discount * 100}
      suffix="%"
      icon={null}
      decimals={1}
      duration={1.5}
      separator="."
      decimal=","
      additionalInfo={undefined}
      badge={undefined}
      badgeClass={undefined}
      link_to={undefined}
      link_name={undefined}
      link_deactive={undefined}
      bgcolor={undefined}
    />
  );
};

export default withTranslation()(DiscountsInfo);
