import { useState } from "react";
import { withTranslation } from "react-i18next";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const EditOrderDropdown = ({
  order,
  icon = undefined,
  onClickApprove,
  onClickCancel,
  t,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const isIncentive = () => order?.type === 'incentive';

  const approveDisabled = () => {
    const statusCanApprove = ["scheduled", "created"];
    return Boolean(isIncentive() || !statusCanApprove.includes(order.status));
  };

  const cancelDisabled = () => {
    const orderStatusCanCancel = ["done", "scheduled", "created"];
    const incentiveStatusCanCancel = ["done"];

    if (isIncentive()) return Boolean(!incentiveStatusCanCancel.includes(order.status));
    return Boolean(!orderStatusCanCancel.includes(order.status));
  };

  return (
    <div className="text-left">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="start">
        <DropdownToggle className="text-muted w-auto border-0 bg-transparent p-0">
          <i
            className={`bx ${icon ? icon : 'bx-list-ul'} bx-sm align-bottom`}
          />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={onClickApprove} disabled={approveDisabled()}>
            <div className="d-flex align-top">
              <i className="bx bx-check text-muted bx-xs me-2" />
              <span>{t("orders-management.edit-order-dropdown.approve")}</span>
            </div>
          </DropdownItem>

          <DropdownItem onClick={onClickCancel} disabled={cancelDisabled()}>
            <div className="d-flex align-top">
              <i className="bx bx-x text-muted bx-xs me-2" />
              <span>{t("orders-management.edit-order-dropdown.cancel")}</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(EditOrderDropdown);
