import { withTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';

import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { formatCurrency } from '../../../../helpers/format';
import IndicatorsProps from '../../types/IndicatorsProps';

import LifetimeInfo from './LifetimeInfo';
import Lifetime from './Lifetime';
import Monthly from './Monthly';
import Daily from './Daily';
import Card from '../Card';
import { useState } from 'react';

const BillingIndicators = (props: IndicatorsProps): React.JSX.Element => {
  const indicators = props?.data?.billing;

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const sum = indicators?.data
    ?.filter((v) => {
      const currentMonth = new Date().getMonth();
      const desiredIndex = currentMonth === 0 ? 11 : currentMonth;
      return new Date(v.created_at).getMonth() === desiredIndex;
    })
    ?.reduce((acc, i) => acc + i?.billing_total, 0);

  return (
    <div style={{ marginBottom: isOpen ? 50 : 0 }}>
      <BreadCrumb
        title={props?.t('reports.billing.heading')}
        onClick={toggle}
        description={props?.t(
          isOpen ? 'reports.click-to-hide' : 'reports.click-to-expand'
        )}
      />

      <Collapse isOpen={isOpen}>
        <Card
          title={props?.t('reports.billing.lifetime.heading')}
          description={`${props?.t(
            'reports.billing.lifetime.description'
          )} ${formatCurrency(indicators?.summary.billing_total)}`}
        />

        <Row>
          <Col>
            <Card body={<Lifetime {...props} />} />
          </Col>

          <Col xxl={4} xl={12}>
            <LifetimeInfo {...props} />
          </Col>
        </Row>

        <div style={{ marginTop: -20 }}>
          <Card
            title={props?.t('reports.billing.month.heading')}
            description={`${props?.t(
              'reports.billing.month.description'
            )} ${formatCurrency(sum)}`}
          />
        </div>

        <Row>
          <Col xxl={5}>
            <Card body={<Monthly {...props} />} />
          </Col>

          <Col xxl={7}>
            <Card body={<Daily {...props} />} />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default withTranslation()(BillingIndicators);
