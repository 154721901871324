import React, { useEffect, useState, useMemo, useRef, useReducer } from 'react';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { createSelector } from 'reselect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import InputSwitch from '../../../Components/Common/InputSwitch';
import StepIndicator from '../../../Components/Common/StepIndicator';
import useQsParams from '../../../Components/Hooks/QueryString';
import {
  getConfigurations,
  setConfigurations,
} from '../../../slices/lead-capture/thunk';

import PreviewDevice from './components/Preview';
import ModalQr from './components/ModalQr';
import Step1 from './components/Steps/Step1';
import Step2 from './components/Steps/Step2';
import Step3 from './components/Steps/Step3';

const LeadCaptureSettings = (props) => {
  const { qs, setQs } = useQsParams();
  const reduxDispatch = useDispatch();

  document.title = `${props.t('lead-capture.settings.title')} | Opencashback`;

  const configuration = useSelector(
    createSelector(
      (store) => store.LeadCapture,
      (slice) => slice.configuration
    )
  );

  const isLoading = useSelector(
    createSelector(
      (store) => store.LeadCapture,
      (slice) => slice.isLoading
    )
  );

  useEffect(() => {
    reduxDispatch(getConfigurations());
  }, []);

  const updateConfiguratinos = async (values) => {
    const raw = await reduxDispatch(setConfigurations(values));

    if (raw?.payload?.status !== 200) {
      toast.error('Verifique os dados e tente novamente');
      return false;
    }

    toast.success('Configurações atualizadas!');
    return true;
  };

  const initialValues = {
    step: 'form',
    ...configuration,
  };

  const _withBoolField = {
    is: true,
    then: (f) => f.required(),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,

    initialValues: {
      ...configuration,
    },

    validationSchema: Yup.object({
      enableLeadCapture: Yup.boolean().required(),
      enabledIncentive: Yup.boolean().required(),
      validatePhone: Yup.boolean().required(),
      allFieldsRequired: Yup.boolean().required(),

      logoUrl: Yup.string().when('enableLeadCapture', _withBoolField),
      cashbackAmount: Yup.string().when('enabledIncentive', _withBoolField),
      expiresInDays: Yup.string().when('enabledIncentive', _withBoolField),

      step1Heading: Yup.string().when('enableLeadCapture', _withBoolField),
      step2Heading: Yup.string().when('enableLeadCapture', _withBoolField),
      step2Body: Yup.string().when('enableLeadCapture', _withBoolField),
      step3Heading: Yup.string().when('enableLeadCapture', _withBoolField),
      step3Body: Yup.string().when('enableLeadCapture', _withBoolField),

      step1HeadingColor: Yup.string().when('enableLeadCapture', _withBoolField),
      step2HeadingColor: Yup.string().when('enableLeadCapture', _withBoolField),
      step3HeadingColor: Yup.string().when('enableLeadCapture', _withBoolField),
      textColor: Yup.string().when('enableLeadCapture', _withBoolField),
      backgroundColor: Yup.string().when('enableLeadCapture', _withBoolField),
      ctaTextColor: Yup.string().when('enableLeadCapture', _withBoolField),
      ctaBackgroundColor: Yup.string().when(
        'enableLeadCapture',
        _withBoolField
      ),
    }),

    onSubmit: updateConfiguratinos,
  });

  const [state, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialValues
  );

  const disabled = !formik?.values?.enableLeadCapture;

  const STEPS = [
    {
      number: 1,
      step: 'form',
      label: 'Identidade Visual',
      description: 'Defina o logotipo e cores do formulário',
      component: (props) => <Step1 {...props} onClick />,
      active: true,
    },

    {
      number: 2,
      step: 'success',
      label: 'Conteúdos',
      description: 'Defina os textos que aparecerão nas etapas do formulário',
      component: (props) => <Step2 {...props} />,
    },

    {
      number: 3,
      step: 'error',
      label: 'Configurações',
      description: 'Habilite funcionalidades extras',
      component: (props) => <Step3 {...props} />,
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t('lead-capture.settings.title')}
          pageTitle="Gestão"
        />

        {isLoading && !formik?.values ? (
          <Spinner color="secondary" />
        ) : (
          <>
            <Row className="mb-4">
              <Col xl={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <h4>Ativar Captura de Leads</h4>
                        <span>Habilita o formulário de captura de leads</span>
                      </Col>

                      <Col className="d-flex justify-content-end">
                        <InputSwitch
                          name="enableLeadCapture"
                          checked={formik?.values?.enableLeadCapture}
                          onChange={(v) => {
                            dispatch({ enableLeadCapture: v });
                            formik.setFieldValue('enableLeadCapture', v);
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <Card style={{ paddingTop: 7, paddingBottom: 7 }}>
                  <CardBody className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary btn-label right ms-auto"
                      onClick={() => setQs('modal', true)}
                      disabled={disabled}
                    >
                      Visualizar QR Code
                      <i className="bx bx-qr label-icon align-middle fs-16 ms-2" />
                    </button>

                    <button
                      type="button"
                      className="btn btn-success btn-label right ms-4"
                      onClick={formik.submitForm}
                    >
                      Salvar tudo
                      <i className="bx bx-save label-icon align-middle fs-16 ms-2" />
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={2}>
                <Row className="vstack g-4">
                  {STEPS.map((step, index) => (
                    <StepIndicator key={`step-cta-${index}`} {...step} />
                  ))}
                </Row>
              </Col>

              <Col>
                {isLoading ? (
                  <Spinner color="secondary" size="xl" />
                ) : (
                  STEPS.map(
                    ({ component, ...step }, index) =>
                      Number(qs.get('active') ?? 1) === ++index && (
                        <div key={`step-${index}`}>
                          <StepIndicator heading {...step} />
                          {component({
                            step,
                            state,
                            dispatch,
                            formik,
                            disabled,
                            initialValues: formik.values,
                          })}
                        </div>
                      )
                  )
                )}
              </Col>

              <Col xl={4}>
                {!isLoading && formik?.values && (
                  <PreviewDevice
                    params={{ ...state, ...formik?.values }}
                    onEnable={() => {
                      dispatch({ enableLeadCapture: true });
                      formik.setFieldValue('enableLeadCapture', true);
                    }}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalQr
        value={`${process.env.REACT_APP_API_MY_WALLET_URL}/${configuration?.path}/cadastro`}
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </div>
  );
};

export default withTranslation()(LeadCaptureSettings);
