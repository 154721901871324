import { useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  getBillingIndicators,
  getConsumersIndicators,
  getCheckingAccountIndicators,
} from '../../slices/indicators/thunk';

import IndicatorsProps from './types/IndicatorsProps';
import Billing from './components/Billing';
import SalesVolume from './components/SalesVolume';
import EarningRedeem from './components/EarningRedeem';
import AverageTicket from './components/AverageTicket';
import Discount from './components/Discount';
import ReturningTax from './components/ReturningTax';

const Reports = ({ t }): React.JSX.Element => {
  document.title = `${t('reports.title')} | Opencashback`;
  const dispatch = useDispatch();

  const ReportsState = createSelector(
    (state) => state.Indicators.data,
    (reports) => reports
  );

  const loadingState = createSelector(
    (state) => state.Indicators.isLoading,
    (isLoading) => isLoading
  );

  useEffect(() => {
    dispatch(getBillingIndicators());
    dispatch(getConsumersIndicators());
    dispatch(getCheckingAccountIndicators());
  }, []);

  const props = {
    data: useSelector(ReportsState),
    loading: useSelector(loadingState),
  } as IndicatorsProps;

  const isLoading = props?.loading;
  const hasBillingData = props?.data?.billing?.data?.length;
  const hasConsumersData = props?.data?.consumers?.length;
  const hasData = Boolean(!isLoading && hasBillingData && hasConsumersData);

  return (
    <Container fluid>
      <div className="page-content mb-0">
        {hasData ? (
          <>
            <Billing {...props} />
            <SalesVolume {...props} />
            <EarningRedeem {...props} />
            <AverageTicket {...props} />
            <Discount {...props} />
            <ReturningTax {...props} />
          </>
        ) : (
          <Spinner color="secondary" />
        )}
      </div>
    </Container>
  );
};

export default withTranslation()(Reports);
