import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const ReturningTaxChart = ({ data, t }): React.JSX.Element => {
  const indicators = data?.consumers;

  return (
    <ReactApexChart
      type="line"
      height={550}
      options={{
        chart: {
          toolbar: { show: false },
          ...chartLocales,
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 2, 2],
        },
        colors: ['#aaff03', '#1b73e8', '#E8901C', '#ec616c'],
        labels: indicators?.map((i) => i?.day),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: {
            formatter: (v) => formatCurrency(v, { style: 'percent' }),
          },
        },
      }}
      series={[
        {
          name: t('reports.returning-tax.7d'),
          data: indicators?.map((i) => i?.percent_rate_return_7_days),
        },

        {
          name: t('reports.returning-tax.15d'),
          data: indicators?.map((i) => i?.percent_rate_return_15_days),
        },

        {
          name: t('reports.returning-tax.30d'),
          data: indicators?.map((i) => i?.percent_rate_return_30_days),
        },

        {
          name: t('reports.returning-tax.historical'),
          data: indicators?.map((i) => i?.percent_rate_return_historical),
        },
      ]}
    />
  );
};

export default withTranslation()(ReturningTaxChart);
