import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { convertCents, formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const BillingMonthTotalAccumulatedChart = ({ data, t }) => {
  const indicators = data?.billing?.data;

  const lastMonthSeries = indicators.filter((v) => {
    const currentMonth = new Date().getMonth();
    const desiredIndex = currentMonth - 1 <= 0 ? 11 : currentMonth - 1;
    return new Date(v.created_at).getMonth() === desiredIndex;
  });

  const currentMonthSeries = indicators.filter((v) => {
    const currentMonth = new Date().getMonth();
    const desiredIndex = currentMonth === 0 ? 11 : currentMonth;
    return new Date(v.created_at).getMonth() === desiredIndex;
  });

  return (
    <ReactApexChart
      type="line"
      height={350}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        title: {
          align: 'center',
          text: t('reports.billing.month.monthly.title'),
        },

        subtitle: {
          align: 'center',
          text: t('reports.billing.month.monthly.subtitle'),
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [2, 2, 2, 4] },

        labels: [...lastMonthSeries, ...currentMonthSeries]?.map(
          (i) => i?.created_at
        ),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: { formatter: (v) => formatCurrency(v, { convert: true }) },
        },
      }}
      series={[
        {
          name: t('reports.billing.month.monthly.chart.last-month'),
          data: [
            ...lastMonthSeries?.map((i) => convertCents(i?.billing_total)),
            ...currentMonthSeries?.map(() => null),
          ],
        },

        {
          name: t('reports.billing.month.monthly.chart.current-month'),
          data: [
            ...lastMonthSeries?.map(() => null),
            ...currentMonthSeries?.map((i) => convertCents(i?.billing_total)),
          ],
        },
      ]}
    />
  );
};

export default withTranslation()(BillingMonthTotalAccumulatedChart);
