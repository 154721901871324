import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOrders as getOrdersApi,
  getOrder as getOrderApi,
  getOrdersFilters as getOrdersFiltersApi,
  simulateOrder as simulateOrderApi,
  createOrder as createOrderApi,
  updateOrder as updateOrderApi,
  approveOrder as approveOrderApi,
  cancelOrder as cancelOrderApi
} from "../../helpers/bff_helper";
import { toast } from "react-toastify";

export const getOrders = createAsyncThunk("orders/getOrders", async (params) => {
  params.order = 'desc'
  params.page = params.page || 1
  try {
    const response = getOrdersApi(params);

    return response;
  } catch (error) {
    return error;
  }
});

export const getOrder = createAsyncThunk("orders/getOrder", async (id) => {
  try {
    return getOrderApi(id);
  } catch (error) {
    return error;
  }
});

export const getOrdersFilters = createAsyncThunk("orders/getFilters", async () => {
  try {
    const response = getOrdersFiltersApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const simulateOrder = createAsyncThunk('orders/simulate', async (params) => {
  try {
    const response = simulateOrderApi(params);

    return response;
  } catch (error) {
    return {
      error: true,
      message: error
    }
  }
})

export const createOrder = createAsyncThunk('orders/createOrder', async ({
  order,
  errorMessage,
}) => {
  try {
    const response = await createOrderApi(order);

    return response;
  } catch (error) {
    toast.error(errorMessage, {autoClose: 5000});
    return {
      error: true,
      message: error
    }
  }
})

export const updateOrder = createAsyncThunk('orders/updateOrder', async ({
  external_id,
  order,
  errorMessage,
  conflictMessage,
}) => {
  try {
    const response = await updateOrderApi(external_id, order);
    return response;
  } catch (error) {
    if (error === 'conflict') {
      toast.error(conflictMessage, {autoClose: 5000});
      return {
        error: true,
        message: error
      }
    }

    toast.error(errorMessage, {autoClose: 5000});
    return {
      error: true,
      message: error
    }
  }
})

export const approveOrder = createAsyncThunk(
  "orders/approveOrder",
  async ({ external_id, successMessage, errorMessage }) => {
    try {
      const response = await approveOrderApi(external_id);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "orders/cancelOrder",
  async ({ external_id, successMessage, errorMessage }) => {
    try {
      const response = await cancelOrderApi(external_id);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

