import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import { convertCents, formatCurrency } from '../../../../helpers/format';
import chartLocales from '../../utils/locales';

const BillingLifetimeChart = ({ data, t }) => {
  const indicators = data?.billing?.data;

  return (
    <ReactApexChart
      type="line"
      height={530}
      options={{
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },

        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },

        markers: { size: 5 },
        stroke: { width: [1, 1, 1, 2] },
        colors: ['#1b73e8', '#aaff03', '#ff6400', '#ec616c'],
        labels: indicators?.map((i) => i?.created_at),

        xaxis: {
          labels: {
            formatter: (v) => new Date(v).toLocaleDateString('pt-BR'),
          },
        },

        yaxis: {
          labels: {
            formatter: (v, ...rest: any) => {
              const serieIndex = rest[0]?.seriesIndex;
              const serie = rest[0]?.w?.globals?.initialSeries[serieIndex];
              const isPercent = !!serie?.name?.startsWith('%');

              return !serie || !isPercent
                ? formatCurrency(v, { convert: true })
                : formatCurrency(v, { convert: false, style: 'percent' });
            },
          },
        },
      }}
      series={[
        {
          type: 'column',
          name: t('reports.billing.lifetime.chart.no-cashback'),
          data: indicators?.map((i) =>
            convertCents(i?.billing_total_without_cashback)
          ),
        },

        {
          type: 'column',
          name: t('reports.billing.lifetime.chart.total-impacted'),
          data: indicators?.map((i) => convertCents(i?.billing_total_impacted)),
        },

        {
          type: 'column',
          name: t('reports.billing.lifetime.chart.total-incremental'),
          data: indicators?.map((i) =>
            convertCents(i?.billing_total_incremental)
          ),
        },

        {
          type: 'line',
          name: t(
            'reports.billing.lifetime.chart.total-incremental-percentage'
          ),
          data: indicators?.map((i) => i?.billing_total_incremental_percentage),
        },
      ]}
    />
  );
};

export default withTranslation()(BillingLifetimeChart);
