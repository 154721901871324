import { withTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';

import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import IndicatorsProps from '../../types/IndicatorsProps';

import LifetimeInfo from './LifetimeInfo';
import Lifetime from './Lifetime';
import Monthly from './Monthly';
import Card from '../Card';
import Daily from './Daily';
import { useState } from 'react';

const SalesVolumeIndicators = (props: IndicatorsProps): React.JSX.Element => {
  const indicators = props?.data?.billing;

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ marginBottom: isOpen ? 50 : 0 }}>
      <BreadCrumb
        title={props?.t('reports.sales-volume.heading')}
        onClick={toggle}
        description={props?.t(
          isOpen ? 'reports.click-to-hide' : 'reports.click-to-expand'
        )}
      />
      <Collapse isOpen={isOpen}>
        <Card
          title={props?.t('reports.sales-volume.lifetime.heading')}
          description={`${props?.t(
            'reports.sales-volume.lifetime.description'
          )} ${indicators.summary.sales_quantity_total}`}
        />

        <Row>
          <Col>
            <Card body={<Lifetime {...props} />} />
          </Col>

          <Col xxl={4} xl={12}>
            <LifetimeInfo {...props} />
          </Col>
        </Row>

        <Card title={props?.t('reports.sales-volume.month.heading')} />

        <Row>
          <Col xxl={5}>
            <Card body={<Monthly {...props} />} />
          </Col>

          <Col xxl={7}>
            <Card body={<Daily {...props} />} />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default withTranslation()(SalesVolumeIndicators);
