import { CardBody, CardHeader, Card as Wrapper } from 'reactstrap';

interface CardProps {
  title?: string;
  description?: any;
  body?: any;
  minimal?: boolean;
}

const Card = (props: CardProps): React.JSX.Element => {
  const { title, description, body, minimal = false } = props;

  const styles = {
    wrapper: minimal ? { boxShadow: 'unset' } : {},
    body: minimal ? { padding: 0, background: '#f4f6f9', border: 0 } : {},
  };

  return (
    <Wrapper style={styles.wrapper}>
      {title || description ? (
        <CardHeader className="d-flex gap-5">
          {title ? (
            <h4 className="flex-grow-1 card-title mb-0 text-center">{title}</h4>
          ) : null}

          {description ? (
            <h6 className="card-title mb-0 text-center">{description}</h6>
          ) : null}
        </CardHeader>
      ) : null}

      {body ? <CardBody style={styles.body}>{body}</CardBody> : null}
    </Wrapper>
  );
};

export default Card;
