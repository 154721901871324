import { createSlice } from "@reduxjs/toolkit";
import {
  getCashbackCardsData,
  getCashbackChartData,
  getSalesCardsData,
  getSalesChartData,
} from "./thunk";
import { keys } from "lodash";

const shared = {
  isLoading: false,
  isError: false,
  error: {},
};

export const initialState = {
  sales: {
    cards: {
      ...shared,
      total: [],
      impacted: [],
      incremental: [],
      cumulative: [],
    },

    chart: {
      ...shared,
      data: [],
    },
  },

  cashback: {
    cards: {
      ...shared,
      generated: [],
      redeemed: [],
      expired: [],
      available: [],
    },

    chart: {
      ...shared,
      data: [],
    },
  },
};

const managerPanelSlice = createSlice({
  name: "ManagerPanel",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSalesCardsData.pending, (state, action) => {
      state.sales.cards.isLoading = true;
    });
    builder.addCase(getSalesCardsData.fulfilled, (state, action) => {
      state.sales.cards.isLoading = false;
      state.sales.cards.total = [
        { label: "Valor total", value1: action.payload.total_billing },
        { label: "Número total", value1: action.payload.total_orders },
        { label: "Ticket médio", value1: action.payload.average_ticket },
      ];
      state.sales.cards.impacted = [
        { label: "Valor total", value1: action.payload.total_billing_impacted, value2: action.payload.total_billing_impacted_percentage },
        { label: "Número total", value1: action.payload.total_orders_impacted, value2: action.payload.total_orders_impacted_percentage },
        { label: "Ticket médio", value1: action.payload.average_ticket_impacted, value2: action.payload.average_ticket_impacted_percentage },
      ];
      state.sales.cards.incremental = [
        { label: "Valor total", value1: action.payload.total_billing_incremental, value2: action.payload.total_billing_incremental_percentage },
        { label: "Número total", value1: action.payload.total_orders_incremental, value2: action.payload.total_orders_incremental_percentage },
        { label: "Ticket médio", value1: action.payload.average_ticket_incremental, value2: action.payload.average_ticket_incremental_percentage },
      ];
      state.sales.cards.without_cashback = [
        { label: "Valor total", value1: action.payload.total_billing_without_cashback, value2: action.payload.total_billing_without_cashback_percentage },
        { label: "Número total", value1: action.payload.total_orders_without_cashback, value2: action.payload.total_orders_without_cashback_percentage },
        { label: "Ticket médio", value1: action.payload.average_ticket_without_cashback, value2: action.payload.average_ticket_without_cashback_percentage },
      ];
    });
    builder.addCase(getSalesCardsData.rejected, (state, action) => {
      state.sales.cards.isError = true;
      state.sales.cards.error = action.payload || null;
    });

    builder.addCase(getSalesChartData.pending, (state, action) => {
      state.sales.chart.isLoading = true;
    });
    builder.addCase(getSalesChartData.fulfilled, (state, action) => {
      state.sales.chart.isLoading = false;
      state.sales.chart.data = action.payload.map((i) => {
        const props = keys(i);
        return {
          date: i.date,
          value1: i[props[1]],
          value2: i[props[2]],
          value3: i[props[3]],
          value4: i[props[4]]
        }
      });
    });
    builder.addCase(getSalesChartData.rejected, (state, action) => {
      state.sales.chart.isError = true;
      state.sales.chart.error = action.payload || null;
    });

    builder.addCase(getCashbackCardsData.pending, (state, action) => {
      state.cashback.cards.isLoading = true;
    });
    builder.addCase(getCashbackCardsData.fulfilled, (state, action) => {
      state.cashback.cards.isLoading = false;
      state.cashback.cards.generated = [
        { label: "Valor total", value1: action.payload.total_generated_cashback },
      ];
      state.cashback.cards.redeemed = [
        { label: "Valor total", value1: action.payload.total_redeemed_cashback, value2: action.payload.redeemed_cashback_percentage }
      ];
      state.cashback.cards.available  = [
        { label: "Valor total", value1: action.payload.total_available_cashback, value2: action.payload.available_cashback_percentage }
      ];
      state.cashback.cards.expired = [
        { label: "Valor total", value1: action.payload.total_expired_cashback, value2: action.payload.expired_cashback_percentage }
      ];
    });
    builder.addCase(getCashbackCardsData.rejected, (state, action) => {
      state.cashback.cards.isError = true;
      state.cashback.cards.error = action.payload || null;
    });

    builder.addCase(getCashbackChartData.pending, (state, action) => {
      state.cashback.chart.isLoading = true;
    });
    builder.addCase(getCashbackChartData.fulfilled, (state, action) => {
      state.cashback.chart.isLoading = false;
      state.cashback.chart.data = action.payload;
    });
    builder.addCase(getCashbackChartData.rejected, (state, action) => {
      state.cashback.chart.isError = true;
      state.cashback.chart.error = action.payload || null;
    });
  },
});

export default managerPanelSlice.reducer;
