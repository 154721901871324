import { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { getLoggedinUser } from '../../helpers/api_helper';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUpload = ({
  values,
  invalid = false,
  onFinish,
  onRemoveFile,
  ...rest
}) => {
  const [files, setFiles] = useState(values);
  const { access_token } = getLoggedinUser();

  return (
    <div
      key="fileUpload"
      style={{ border: invalid ? '1px solid red' : 0, borderRadius: '0.5em' }}
    >
      <FilePond
        files={files?.length ? files : values}
        onupdatefiles={setFiles}
        // allowMultiple={true}
        maxFiles={1}
        server={{
          url: process.env.REACT_APP_API_URL,
          process: {
            url: `/import`,
            method: 'POST',
            headers: { Authorization: `Bearer ${access_token}` },
            onload: (stream) => {
              if (!stream) return null;
              return onFinish(JSON.parse(stream));
            },
          },

          remove: async (_, remove) => {
            remove();
            setFiles([]);
            onRemoveFile();
          },

          load: async (source, load) => {
            try {
              if (!source) load();
              const raw = await fetch(source);
              const blob = await raw.blob();
              load(blob);
            } catch (e) {
              load();
            }
          },
        }}
        name="file"
        labelIdle={
          invalid ? 'Arquivo obrigatório' : 'Clique para enviar o arquivo'
        }
        {...rest}
      />
    </div>
  );
};

export default FileUpload;
