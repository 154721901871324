import { withTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import classNames from "classnames";
import moment from "moment";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Spinner,
  UncontrolledCollapse,
} from "reactstrap";

import useQsParams from "../../Hooks/QueryString";

import FilterByUserStores from "../FilterByUserStores";
import CheckboxFilter from "./Checkbox";
import SelectFilter from "./Select";
import RadioFilter from "./Radio";
import RangeFilter from "./Range";
import TextFilter from "./Text";
import DateFilter from "./Date";

export const getFilters = (filters = [], fixedFilters = []) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  return [...filters, ...fixedFilters]?.reduce((carry, item) => {
    const urlParameter = searchParams.get(item?.field);
    const { field, type } = item;

    if (urlParameter?.length) {
      if (!carry[field]) carry[field] = {};

      if (["text", "eq"].includes(type)) {
        carry[field] = urlParameter;
      }

      // used to query an array without operator
      if (["eq-as-array"].includes(type)) {
        carry[field] = urlParameter?.split(",");
      }

      if (["gte"].includes(type)) carry[field]["gte"] = urlParameter;
      if (["gt"].includes(type)) carry[field]["gt"] = urlParameter;

      if (["lte"].includes(type)) carry[field]["lte"] = urlParameter;
      if (["lt"].includes(type)) carry[field]["lt"] = urlParameter;

      if (["eq-as-raw"].includes(type)) {
        carry[field]["eq"] = urlParameter;
      }

      if (["like"].includes(type)) {
        carry[field]["like"] = urlParameter;
      }

      if (["checkbox", "select", "in"].includes(type)) {
        carry[field]["in"] = urlParameter?.split(",");
      }

      if (["isnull", "radio"].includes(type)) {
        carry[field]["isnull"] = urlParameter;
      }

      if (["date", "date-range", "between_date"].includes(type)) {
        const param = urlParameter?.split(",");
        const d1 = param?.[0];
        const d2 = param?.[1] || d1;

        carry[field]["between_date"] = [
          moment.unix(d1).startOf("day").format(),
          moment.unix(d2).endOf("day").format(),
        ];
      }

      if (["value-range", "between", "range"].includes(type)) {
        const param = urlParameter?.split(",");
        const d1 = param?.[0];
        const d2 = param?.[1] || d1;

        carry[field]["between"] = [d1, d2];
      }
    }

    return carry;
  }, {});
};

const _renderFiltersAsArray = (filters = [], values) => {
  return filters.map((item, key) => {
    const item_values = values.find((field) => field.field === item.field);

    const filter_values = (item_values?.values || []).map((value) => ({
      label: value,
      value,
    }));

    const emptyValues = isEmpty(filter_values) && isEmpty(item_values);
    // if (!item?.values && emptyValues) return null;
    if (emptyValues) return null;

    const defaultOpts = { key, item, values: filter_values };

    const FILTERS = {
      date: <DateFilter {...defaultOpts} />,
      text: <TextFilter {...defaultOpts} />,
      select: <SelectFilter {...defaultOpts} />,
      checkbox: <CheckboxFilter {...defaultOpts} />,
      radio: <RadioFilter {...defaultOpts} />,
      range: <RangeFilter {...defaultOpts} orderFilters={values} />,
    };

    return FILTERS[item.type] ?? null;
  });
};

const _manageArrayParamAsString = (_key, value, qs) => {
  const raw = qs.qs.get(_key);
  const ARRAY = raw ? raw.split(",") : [];
  const existing = _.uniq([...ARRAY].map(Number));

  if (existing.indexOf(value) != -1) {
    existing.splice(existing.indexOf(value), 1);
  } else {
    existing.push(value);
  }

  qs.setSearchParams(_key, existing.join(","));
};

const _renderFiltersAsGroups = ({ filters, values, qs, t, localePrefix }) => {
  const available_fields = [..._.intersectionBy(values, filters, "field")];
  const grouped = Object.groupBy(available_fields, ({ group }) => group);
  if (!grouped) return null;

  return Object.keys(grouped).map((group, key) => {
    const isOpened = !!qs.qs.get("opened")?.split(",")?.includes(String(key));
    const TRIGGER = `${group}-${key}-trigger`;

    return (
      <div
        key={`group-${group}-${key}`}
        className='filter-drawer-group-wrapper'
      >
        <span className="px-3">
          <span
            style={{ cursor: "pointer" }}
            className='d-flex justify-content-between align-items-center px-3'
            onTouchStart={() => _manageArrayParamAsString("opened", key, qs)}
            onClick={() => _manageArrayParamAsString("opened", key, qs)}
            id={TRIGGER}
          >
            <span className='fw-semibold fs-5'>
              {t(`${localePrefix}.filters.${group}`)}
            </span>

            <i
              className={classNames({
                "transition bx bxs-down-arrow": true,
                rotate180deg: isOpened,
              })}
            />
          </span>
        </span>

        <UncontrolledCollapse
          className='px-3'
          toggler={`${TRIGGER.toString()}`}
          defaultOpen={isOpened}
        >
          <div className='mt-2 group-collapsible'>
            {_renderFiltersAsArray(filters, grouped[group])}
          </div>
        </UncontrolledCollapse>
      </div>
    );
  });
};

const Filter = ({
  show,
  onCloseClick = () => {},
  onClearFilter,
  onSubmitFilter,
  filters,
  values = [],
  isLoading,

  showUserStoresFilter = false,
  useGroups = false,
  localePrefix,
  t,
}) => {
  const qs = useQsParams();
  const { searchParams, setSearchParams } = useQsParams();

  const showFilter = searchParams.get("showFilter") === "true" ? true : false;
  const toggleDrawer = () => setSearchParams("showFilter", "");

  return (
    <Offcanvas
      direction='end'
      isOpen={show || showFilter}
      id='filter'
      toggle={() => {
        toggleDrawer();
        onCloseClick();
      }}
    >
      <OffcanvasHeader
        className='bg-light'
        toggle={() => {
          toggleDrawer();
          onCloseClick();
        }}
      >
        {t(useGroups ? "advanced-filters" : "filters")}
      </OffcanvasHeader>

      <div className='d-flex flex-column justify-content-end h-100 overflow-auto'>
        <div className='offcanvas-body p-0 filter-drawer-wrapper'>
          {useGroups ? (
            <>
              {showUserStoresFilter ? <FilterByUserStores /> : null}
              {_renderFiltersAsGroups({ filters, values, localePrefix, qs, t })}
            </>
          ) : (
            <OffcanvasBody>
              {showUserStoresFilter ? <FilterByUserStores /> : null}
              {_renderFiltersAsArray(filters, values, qs)}
            </OffcanvasBody>
          )}
        </div>

        <div className='offcanvas-footer border-top p-3 text-center hstack gap-2'>
          <button
            className='btn btn-light w-100'
            onClick={(e) => {
              qs.resetQs(e);
              onClearFilter && onClearFilter();
            }}
          >
            {t("clear-filter")}
          </button>

          <button
            type='button'
            className='btn btn-success w-100'
            onClick={(e) => {
              e.preventDefault();
              qs.setSearchParams("showFilter", "");
              onSubmitFilter && onSubmitFilter();
            }}
          >
            {t("to-filter")}
            {isLoading && <Spinner className='ms-2' size='sm' />}
          </button>
        </div>
      </div>
    </Offcanvas>
  );
};

export default withTranslation()(Filter);
