import { createSlice } from '@reduxjs/toolkit';
import { getOrder, getOrders, getOrdersFilters } from './thunk';

export const initialState = {
  isLoading: false,
  orders: [],
  order: {},
  meta: {},
  error: {},
  filters: [],
  isError: false,
};

const orderSlice = createSlice({
  name: 'Orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getOrdersFilters.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getOrdersFilters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filters = action.payload?.filters;
    });
    builder.addCase(getOrdersFilters.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getOrder.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.order = action.payload;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export default orderSlice.reducer;
