import { useDebounce } from '@react-hooks-hub/use-debounce';
import { Card, CardBody, CardFooter, Input, Label } from 'reactstrap';
import ActionButton from '../../../../../../Components/Common/ActionButton';
import Editor from '../../../../../../Components/Common/Editor';

const Tab2 = ({ dispatch, formik, disabled, setActiveTab }) => {
  const debounceDispatch = useDebounce(dispatch, 500);
  const debounceField = useDebounce(formik.setFieldValue, 500);

  return (
    <Card>
      <CardBody className="d-flex flex-column gap-4 pb-4">
        <h3 className="mb-2">Sucesso ao criar carteira</h3>

        <div>
          <Label
            htmlFor="step2Heading"
            className="form-label fs-5 fw-semibold text-muted"
          >
            Título
          </Label>

          <Input
            type="text"
            id="step2Heading"
            name="step2Heading"
            className="form-control"
            placeholder="Título da sessão"
            value={formik?.values?.step2Heading || ''}
            invalid={formik?.errors?.step2Heading}
            disabled={disabled}
            onBlur={() => formik.validateField('step2Heading')}
            onChange={(e) => {
              formik.handleChange(e);
              debounceDispatch({ step2Heading: e.target.value });
            }}
          />
        </div>

        <Editor
          id="step2Body"
          label="Mensagem de sucesso"
          value={formik?.values?.step2Body || ''}
          disabled={disabled}
          invalid={formik?.errors?.step2Body}
          onChange={(html) => {
            const isEmpty = html === '<p><br></p>';
            const value = isEmpty ? '' : html;
            debounceField('step2Body', value);
            debounceDispatch({ step2Body: value });
          }}
        />
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          label="Salvar e continuar"
          icon="bx-right-arrow-alt"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setActiveTab(3);
          }}
          disabled={disabled}
        />
      </CardFooter>
    </Card>
  );
};

export default Tab2;
