import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { createSelector } from "reselect";

import Search from "../../Components/Common/Search";
import useQsParams from "../../Components/Hooks/QueryString";
import TableContainer from "../../Components/Common/TableContainer";
import ActionButton from "../../Components/Common/ActionButton";

const UsersDataTable = (props) => {
  const { searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const storeId = searchParams?.get("edit") || searchParams?.get("view");
  const isEdit = !!searchParams.get("edit");
  const isCreate = !!searchParams.get("create");
  const isView = !!searchParams.get("view");

  const data = useSelector(
    createSelector(
      (store) => (isCreate ? store?.User : store?.Stores),
      (slice) => ({
        store: slice?.store,
        isLoading: isCreate ? slice?.isLoading : slice?.storeIsLoading,
        meta: isCreate ? slice?.meta : slice?.storeUsersMeta,
        users: isCreate ? slice?.users : slice?.storeUsers,
      })
    )
  );

  const columns = [
    {
      accessor: "name",
      Header: "Nome do funcionário",
      // Header: props.t("stores.table.store_name"),
      Cell: ({ value }) => value ?? "-",
    },

    {
      // Header: props.t("stores.table.store_name"),
      Header: "Função",
      Cell: ({ row }) => {
        const role = row?.original?.role || row?.original?.roles?.[0]?.name;
        return role || "-";
      },
    },
  ];

  if (isEdit || isCreate) {
    columns.push({
      accessor: "id",
      // Header: props.t("actions"),
      Header: "Atribuir à loja",
      Cell: ({ value, row }) => {
        const storeUsers = props?.form?.values?.store_users || [];
        const isSelected = storeUsers?.find((u) => u.id === value) ?? false;

        const isAdmin =
          row?.original?.isAdmin ||
          row?.original?.roles?.[0]?.name
            ?.toLowerCase()
            .includes("administrador");

        const cta = {
          add: { label: "Adicionar", style: "success", icon: "bx-plus" },
          remove: { label: "Remover", style: "danger", icon: "bx-x" },
        };

        const current = isSelected || isAdmin;
        const { style, label, icon } = cta[current ? "remove" : "add"];

        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item d-flex align-items-center">
              <ActionButton
                disabled={isAdmin}
                small
                {...{ style, label, icon }}
                {...(!isAdmin && {
                  onClick: () => {
                    props.form.setFieldValue(
                      "store_users",
                      isSelected
                        ? storeUsers.filter((u) => u.id !== value)
                        : [...storeUsers, row?.original]
                    );
                  },
                })}
              />

              {isAdmin && (
                <>
                  <div id="admin-tooltip">
                    <i
                      class="bx bx-info-circle ms-3 pt-1 fs-5 text-muted"
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <UncontrolledTooltip target="admin-tooltip">
                    Não é possível remover um administrador.
                  </UncontrolledTooltip>
                </>
              )}
            </li>
          </ul>
        );
      },
    });
  }

  return (
    <Card>
      <CardHeader>
        <Row className="g-4">
          <Col md={6}>
            <Search
              placeholder={props.t("search-by-name")}
              value={searchParams?.get("nameu")}
              onSubmit={({ id }) => {
                setSearchParamsAsObject({ nameu: id ?? "", pageu: 1 });
              }}
            />
          </Col>

          {data?.isLoading && (
            <Col>
              <Spinner color="secondary" />
            </Col>
          )}

          {searchParams.get("view") && (
            <div className="col-sm-auto ms-auto">
              <div className="hstack gap-2">
                <button
                  type="button"
                  className="btn btn-secondary add-btn"
                  onClick={() =>
                    setSearchParamsAsObject({
                      edit: storeId,
                      view: "",
                      pageu: "",
                    })
                  }
                >
                  <i className="ri-pencil-fill align-bottom me-1" />
                  Editar lista de usuários
                  {/* {props.t("stores.new-store")} */}
                </button>
              </div>
            </div>
          )}
        </Row>
      </CardHeader>

      <CardBody className="pt-0">
        <TableContainer
          isLoading={data?.isLoading}
          columns={columns}
          data={data?.users || []}
          activePage={searchParams.get("pageu")}
          setPage={(p) => setSearchParams("pageu", p)}
          pageSize={data?.meta?.take || 10}
          totalPages={data?.meta?.pages || 0}
          count={data?.meta?.total || 0}
          className="custom-header-css"
          divClass="table-responsive table-card mb-0"
          tableClass="align-middle table-nowrap"
          theadClass="table-light"
        />
      </CardBody>
    </Card>
  );
};

export default withTranslation()(UsersDataTable);
