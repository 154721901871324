import { useState } from "react";
import { withTranslation } from "react-i18next";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const DetailIncentiveDropdown = ({ incentive, onClickCancel, t }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const cancelDisabled = () => {
    const statusCanCancel = ["done"];

    return Boolean(!statusCanCancel.includes(incentive.status));
  };

  return (
    <div className="text-left">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="start">
        <DropdownToggle className="text-muted w-auto border-0 bg-transparent p-0">
          <i className="bx bx-list-ul bx-sm align-bottom" />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={onClickCancel} disabled={cancelDisabled()}>
              <div className="d-flex align-top">
                <i className="bx bx-x text-muted bx-xs me-2" />
                <span>{t("incentives-management.edit-incentive-dropdown.cancel")}</span>
              </div>
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(DetailIncentiveDropdown);
