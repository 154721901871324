import React, { useState, useEffect } from "react";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

const MyStoresDropdown = (props) => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setProfile({
        account: obj?.account?.name,
        storeCount: obj?.stores?.length,
        hasOneStore: obj?.stores?.length === 1,
        stores: [...obj?.stores],
      });
    }
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const renderBadge = (
    <Badge color='primary' className='ms-2 fs-6'>
      {profile.storeCount}
    </Badge>
  );

  if (!profile.stores?.length) return null;

  return (
    <div className='d-flex'>
      <Dropdown
        isOpen={showDropdown}
        toggle={() => (profile?.hasOneStore ? null : toggleProfileDropdown())}
        className='border d-flex rounded shadow-sm'
      >
        <DropdownToggle
          tag='button'
          type='button'
          className='btn d-flex gap-2 flex-row align-items-center py-1 px-2'
        >
          <div className='d-flex gap-1 align-items-center'>
            <div className='d-flex flex-column text-start lh-sm'>
              <div className='d-grid' style={{ maxWidth: 300 }}>
                <span className='fw-semibold fs-5 d-inline text-truncate'>
                  {profile.hasOneStore
                    ? profile?.stores[0]?.name
                    : props.t("my-stores")}
                </span>
              </div>
              <span className='text-muted'>{profile?.account}</span>
            </div>

            {!profile.hasOneStore && profile?.storeCount > 1 && (
              <div>{renderBadge}</div>
            )}
          </div>

          {!profile.hasOneStore && profile?.storeCount > 1 && (
            <i
              className={classNames({
                "fs-3 transition bx bx-chevron-down": true,
                rotate180deg: showDropdown,
              })}
            />
          )}
        </DropdownToggle>

        <DropdownMenu className='dropdown-menu me-1 border-top' end>
          <div className='dropdown-header d-flex mb-2 align-items-center'>
            <span className='text-dark text-uppercase fs-6 fw-semibold'>
              {props.t("my-stores")}
            </span>
          </div>

          <div style={{ maxHeight: 250, minWidth: 200, overflow: "auto" }}>
            {profile?.stores?.length &&
              profile?.stores?.map((s, i) => (
                <span
                  key={`${s.slug}-${i}`}
                  className='d-block d-block py-1 px-4 text-muted'
                  style={{ whiteSpace: "nowrap" }}
                >
                  &bull;&nbsp;&nbsp; {s.name}
                </span>
              ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(MyStoresDropdown);
