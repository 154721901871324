import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBillingIndicators as getBillingIndicatorsApi,
  getConsumersIndicators as getConsumersIndicatorsApi,
  getCheckingAccountIndicators as getCheckingAccountIndicatorsApi,
} from '../../helpers/bff_helper';

export const getBillingIndicators = createAsyncThunk(
  'indicators/getBilling',
  async (params) => {
    try {
      return getBillingIndicatorsApi(params);
    } catch (error) {
      return error;
    }
  }
);

export const getConsumersIndicators = createAsyncThunk(
  'indicators/getConsumers',
  async (params) => {
    try {
      return getConsumersIndicatorsApi(params);
    } catch (error) {
      return error;
    }
  }
);

export const getCheckingAccountIndicators = createAsyncThunk(
  'indicators/getCheckingAccount',
  async (params) => {
    try {
      return getCheckingAccountIndicatorsApi(params);
    } catch (error) {
      return error;
    }
  }
);
