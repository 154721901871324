import { createSlice } from "@reduxjs/toolkit";
import { getIntegrations, getIntegrationStatus } from "./thunk";

export const initialState = {
  isLoading: false,
  isError: false,
  data: {},
  status: {},
  error: {},
};

const integrationSlice = createSlice({
  name: "Integrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIntegrations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIntegrations.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getIntegrations.rejected, (state, action) => {
      state.error = action.payload || null;
      state.isError = true;
      console.log(action);
    });

    builder.addCase(getIntegrationStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIntegrationStatus.fulfilled, (state, action) => {
      state.status = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getIntegrationStatus.rejected, (state, action) => {
      state.error = action.payload || null;
      state.isError = true;
      console.log(action);
    });
  },
});

export default integrationSlice.reducer;
