import { createContext, useEffect, useState } from "react";
import { getLoggedinUser } from "../../helpers/api_helper";
import { Ability, AbilityBuilder, createMongoAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { isEmpty } from "lodash";

const useProfile = () => {
  const user = getLoggedinUser();
  const token = user && user?.access_token;
  const [loading, setLoading] = useState(user ? false : true);
  const [userProfile, setUserProfile] = useState(
    user ? user : null
  );

  useEffect(() => {
    const user = getLoggedinUser();
    const token = user && user?.access_token;
    setUserProfile(user ? user : null);
    setLoading(token ? false : true);
  }, []);


  return { userProfile, loading, token };
};

const useAbilities = () => {
  const user = getLoggedinUser();
  const { can, build } = new AbilityBuilder(createMongoAbility)

  if (!user) {
    return build();
  }

  for (const permission of user.permissions) {
    can(permission.action, permission.subject)
  }

  return build();
}

export { useProfile, useAbilities };