import qs from 'qs';
import classNames from 'classnames';
import off from '../../../../assets/images/static.gif';

const PreviewDevice = ({ params, onEnable }) => {
  const PARAMS = { mode: 'preview', ...params };
  const DOMAIN = `${process.env.REACT_APP_API_MY_WALLET_URL}/${params?.path}/cadastro`;
  const URI = `${DOMAIN}${qs.stringify(PARAMS, { addQueryPrefix: true })}`;

  const FRAME = 'w-100 h-100 rounded-4 border border-3 border-primary';
  const ALIGN = 'd-flex flex-column align-items-center justify-content-center ';

  const renderFrame = () => <iframe className={FRAME} src={URI} />;
  const renderOff = () => (
    <div
      className={classNames(FRAME, ALIGN, ' text-center overflow-hidden')}
      style={{ cursor: 'pointer' }}
      onClick={onEnable}
    >
      <div className={classNames(ALIGN, 'rounded-3 w-100 h-100 bg-light')}>
        <div className="px-3">
          <i className="bx bx-power-off text-danger" style={{ fontSize: 80 }} />
          <h5 className="fs-4">A Captura de leads está desativada</h5>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="w-100 bg-white rounded-5 p-4 border border-3 border-primary-subtle"
      style={{ height: 710 }}
    >
      <div
        className="rounded-4 h-100 w-100 overflow-hidden"
        style={{ backgroundImage: `url(${off})` }}
      >
        {params?.enableLeadCapture ? renderFrame() : renderOff()}
      </div>
    </div>
  );
};

export default PreviewDevice;
