import { createSlice } from "@reduxjs/toolkit";
import { getHome } from './thunk';

export const initialState = {
  isLoading: false,
  billing: {},
  cashback: {},
  customers: {},
  error: {},
  isError: false,
};

const incetiveSlice = createSlice({
  name: 'Results',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHome.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getHome.fulfilled, (state, action) => {
      state.isLoading = false;
      state.billing = action.payload?.orders;
      state.cashback = action.payload?.cashback;
      state.customers = action.payload?.customers;
    });
    builder.addCase(getHome.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action)
    });
  }
});

export default incetiveSlice.reducer;